export const themeBorderColor = 'GREYSCALE.SATELLITE';
export const createTextFieldBorderColors = (color = themeBorderColor) => ({
  borderStyle: 'solid',
  borderWidth: '1px',
  borderTopColor: `${color} !important`,
  borderRightColor: `${color} !important`,
  borderBottomColor: `${color} !important`,
  borderLeftColor: `${color} !important`
});
export const baseFontSize = 14;
export const baseSpacingPixelsSize = 4;
export const baseSpacingRemValue = baseSpacingPixelsSize / baseFontSize;