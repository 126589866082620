import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { createTextFieldBorderColors } from '../../constants';
import { variantOutlined } from '../../focusShim';
const outline = defineStyle(() => {
  return Object.assign({
    backgroundColor: 'GREYSCALE.SUPERNOVA'
  }, createTextFieldBorderColors(), variantOutlined(), {
    fontSize: 'sm'
  });
});
export const textareaTheme = defineStyleConfig({
  variants: {
    outline
  }
});