'use es6';

import { useRef, useEffect } from 'react';

// src/index.ts
function useUpdateEffect(callback, deps) {
  const renderCycleRef = useRef(false);
  const effectCycleRef = useRef(false);
  useEffect(() => {
    const mounted = renderCycleRef.current;
    const run = mounted && effectCycleRef.current;
    if (run) {
      return callback();
    }
    effectCycleRef.current = true;
  }, deps);
  useEffect(() => {
    renderCycleRef.current = true;
    return () => {
      renderCycleRef.current = false;
    };
  }, []);
}
export { useUpdateEffect };