'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["children", "isDisabled", "__css"];
import { Icon } from "../icon";
import { forwardRef, useStyleConfig, omitThemingProps, chakra } from "../system";
import { jsx } from 'react/jsx-runtime';

// src/close-button.tsx
function CloseIcon(props) {
  return /* @__PURE__ */jsx(Icon, Object.assign({
    focusable: "false",
    "aria-hidden": true
  }, props, {
    children: /* @__PURE__ */jsx("path", {
      fill: "currentColor",
      d: "M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z"
    })
  }));
}
var CloseButton = forwardRef(function CloseButton2(props, ref) {
  const styles = useStyleConfig("CloseButton", props);
  const _omitThemingProps = omitThemingProps(props),
    {
      children,
      isDisabled,
      __css
    } = _omitThemingProps,
    rest = _objectWithoutPropertiesLoose(_omitThemingProps, _excluded);
  const baseStyle = {
    outline: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0
  };
  return /* @__PURE__ */jsx(chakra.button, Object.assign({
    type: "button",
    "aria-label": "Close",
    ref,
    disabled: isDisabled,
    __css: Object.assign({}, baseStyle, styles, __css)
  }, rest, {
    children: children || /* @__PURE__ */jsx(CloseIcon, {
      width: "1em",
      height: "1em"
    })
  }));
});
CloseButton.displayName = "CloseButton";
export { CloseButton };