import { defineStyle } from '@chakra-ui/react';
export const variantsSizes = {
  taglineHeading: defineStyle({
    fontSize: '4xl',
    fontWeight: 'bold',
    lineHeight: 'normal'
  }),
  taglineTwo: defineStyle({
    fontSize: '3xl',
    fontWeight: 'bold',
    lineHeight: 'normal'
  })
};
const color = 'GREYSCALE.ECLIPSE';

// Note: Took inspiration from here: https://stackoverflow.com/a/77739726
export const headingSizes = {
  h1: defineStyle({
    fontSize: '2xl',
    fontWeight: 'bold',
    lineHeight: 'normal',
    color
  }),
  h2: defineStyle({
    fontSize: 'xl',
    fontWeight: 'semibold',
    lineHeight: 'normal',
    color
  }),
  h3: defineStyle({
    fontSize: 'lg',
    fontWeight: 'medium',
    lineHeight: 'normal',
    color
  }),
  h4: defineStyle({
    fontSize: 'md',
    fontWeight: 'medium',
    lineHeight: 'normal',
    color
  }),
  h5: defineStyle({
    fontSize: 'sm',
    fontWeight: 'medium',
    lineHeight: 'normal',
    color
  }),
  h6: defineStyle({
    fontSize: 'xs',
    color,
    fontWeight: 'medium',
    lineHeight: 'normal',
    textTransform: 'uppercase'
  })
};

// Variants allow us to use semantic markup but stylise differently
const variants = {
  taglineHeading: variantsSizes.taglineHeading,
  taglineTwo: variantsSizes.taglineTwo
};
export default {
  baseStyle: ({
    as
  }) => {
    switch (as) {
      case 'h6':
        return headingSizes['h6'];
      case 'h5':
        return headingSizes['h5'];
      case 'h4':
        return headingSizes['h4'];
      case 'h3':
        return headingSizes['h3'];
      case 'h2':
        return headingSizes['h2'];
      default:
        return headingSizes['h1'];
    }
  },
  variants,
  sizes: {
    dynamic: {},
    md: {
      fontWeight: 'medium'
    }
  },
  defaultProps: {
    size: 'dynamic'
  }
};