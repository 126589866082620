// FIXME: Change when https://github.com/chakra-ui/chakra-ui/issues/7648 is picked up

const baseStyle = {
  // select the indicator part
  indicator: {
    '&[data-status=active]': {
      borderColor: 'CARNABY.regular !important'
    },
    '&[data-status=complete]': {
      backgroundImage: `var(--chakra-gradients-magenta-orange)`
    }
  },
  separator: {
    '&[data-status=complete]': {
      backgroundImage: `var(--chakra-gradients-magenta-orange)`
    }
  }
};
export const stepperTheme = {
  baseStyle
};