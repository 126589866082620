import { useEffect, useState } from 'react';
import Raven from 'raven-js';
import { Storage } from 'connect-dot-com-lib/storage/superstore';

/**
 * Allows persistence of user app settings
 *
 * Currently uses superstore (IndexedDb) but could easily be swapped out for a
 * BE service in the future
 */
export const useGetStorageValue = name => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(undefined);
  const removeValue = async () => {
    setValue(undefined);
    await new Storage().remove(name);
  };
  const storeValue = async valueToStore => {
    setValue(valueToStore);
    await new Storage().set(name, valueToStore);
  };
  useEffect(() => {
    setLoading(true);
    new Storage().get(name).then(res => setValue(res)).catch(err => Raven.captureException(err)).finally(() => setLoading(false));
  }, [name]);
  return {
    loading,
    value,
    removeValue,
    storeValue
  };
};