import enviro from 'enviro';
export let PortalConfig;
(function (PortalConfig) {
  PortalConfig[PortalConfig["qa"] = 886867204] = "qa";
  PortalConfig[PortalConfig["prod"] = 39650646] = "prod";
})(PortalConfig || (PortalConfig = {}));
export const getEnvironment = () => {
  return enviro.getShort('app');
};
export const getPortalId = () => {
  return PortalConfig[getEnvironment()];
};