import { createTracker } from 'usage-tracker-anonymous';
import { makeUuid } from 'usage-tracker-core/common/helpers';
import events from 'connect-dot-com-public-ui/events.yaml';
const noauthTracker = createTracker({
  events,
  properties: {
    email: null,
    hubId: null,
    lang: null,
    deviceId: makeUuid()
  }
});
export function trackPageview(screen, additionalProperties) {
  noauthTracker.track('pageView', Object.assign({
    screen
  }, additionalProperties));
}
export function trackInteraction(interaction, additionalProperties) {
  noauthTracker.track('interaction', Object.assign({
    action: interaction
  }, additionalProperties));
}