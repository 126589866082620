import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers(['container', 'content']);
const CUINavItemTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {},
    content: {
      color: 'GREYSCALE.ECLIPSE',
      py: 2,
      px: 2,
      fontWeight: 'medium',
      borderRadius: '3px',
      _selected: {
        backgroundColor: 'GREYSCALE.CONSTELLATION'
      },
      _hover: {
        backgroundColor: 'GREYSCALE.SUPERNOVA',
        _selected: {
          backgroundColor: 'GREYSCALE.CONSTELLATION'
        }
      }
    }
  },
  variants: {
    primary: {},
    secondary: {
      content: {
        _selected: {
          backgroundColor: 'GREYSCALE.SUPERNOVA'
        },
        _hover: {
          backgroundColor: 'GREYSCALE.SUPERNOVA',
          _selected: {
            backgroundColor: 'GREYSCALE.SUPERNOVA'
          }
        }
      }
    }
  },
  defaultProps: {
    variant: 'primary'
  }
});
export default CUINavItemTheme;