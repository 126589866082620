import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(checkboxAnatomy.keys);
const sizes = {
  xl: definePartsStyle({
    control: defineStyle({
      boxSize: 6
    }),
    label: defineStyle({
      fontSize: '2xl',
      marginLeft: 6
    })
  })
};
const baseStyle = definePartsStyle({
  control: defineStyle({
    _checked: {
      bg: 'CARNABY.regular',
      borderColor: 'CARNABY.regular',
      _hover: {
        bg: 'CARNABY.dark',
        borderColor: 'CARNABY.dark'
      }
    }
  }),
  icon: {},
  container: {},
  label: {}
});
export default defineMultiStyleConfig({
  baseStyle,
  sizes
});