import { useToast } from '@chakra-ui/react';
export default function useNotifications() {
  const toast = useToast();
  const toastOpts = {
    position: 'top',
    variant: 'subtle',
    duration: 5000,
    isClosable: true
  };
  const invokeToast = ({
    title,
    description,
    extraOpts,
    status
  }) => toast(Object.assign({}, toastOpts, {
    title,
    description,
    status
  }, extraOpts));
  const notifyError = (title, description, extraOpts) => invokeToast({
    title,
    description,
    extraOpts: Object.assign({
      variant: 'solid'
    }, extraOpts),
    status: 'error'
  });
  const notifySuccess = (title, description, extraOpts) => invokeToast({
    title,
    description,
    extraOpts,
    status: 'success'
  });
  const notifyWarning = (title, description, extraOpts) => invokeToast({
    title,
    description,
    extraOpts,
    status: 'warning'
  });
  return {
    notifyError,
    notifySuccess,
    notifyWarning
  };
}