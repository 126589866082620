import buttonTheme from './button/buttonTheme';
import closeButtonTheme from './closeButton/closeButtonTheme';
import tagTheme from './tag/tagTheme';
import linkTheme from './link/linkTheme';
import inputTheme from './input/inputTheme';
import headingTheme from './heading/headingTheme';
import textTheme from './text/textTheme';
import cardTheme from './card/cardTheme.ts/cardTheme';
import { textareaTheme } from './textarea/textareaTheme';
import { modalTheme } from './modal/modalTheme';
import { selectTheme } from './select/selectTheme';
import tooltipTheme from './tooltip/tooltipTheme';
import { tabsTheme } from './tab/tabTheme';
import { stepperTheme } from './stepper/stepperTheme';
import { switchTheme } from './switch/switchTheme';
import checkboxTheme from './checkbox/checkboxTheme';
import { tableTheme } from './table/tableTheme';
import { drawerTheme } from './drawer/drawerTheme';
import { avatarTheme } from './avatar/avatarTheme';
import { accordionTheme } from './accordion/accordionTheme';
import { menuTheme } from './menu/menuTheme';
import { badgeTheme } from './badge/badgeTheme';
import { popoverTheme } from './popover/popoverTheme';
import CUINavItemTheme from './CUINavItem/CUINavITemTheme';
import { dividerTheme } from './divider/dividerTheme';
import { formLabelTheme } from './forms/formTheme';
export default {
  Accordion: accordionTheme,
  Avatar: avatarTheme,
  Badge: badgeTheme,
  Button: buttonTheme,
  CUINavItem: CUINavItemTheme,
  Card: cardTheme,
  CloseButton: closeButtonTheme,
  Checkbox: checkboxTheme,
  Drawer: drawerTheme,
  Form: formLabelTheme,
  Heading: headingTheme,
  Input: inputTheme,
  Link: linkTheme,
  Menu: menuTheme,
  Modal: modalTheme,
  Popover: popoverTheme,
  Select: selectTheme,
  Stepper: stepperTheme,
  Switch: switchTheme,
  Tag: tagTheme,
  Table: tableTheme,
  Tabs: tabsTheme,
  Text: textTheme,
  Textarea: textareaTheme,
  Tooltip: tooltipTheme,
  Divider: dividerTheme
};