import http from 'hub-http/clients/noAuthApiClient';
import Raven from 'raven-js';
export function getIsUsernameAvailable(username) {
  return http.get(`connect-community/v1/user-profiles/username/${username}`).then(() => false).catch(error => {
    if (error.status === 404) {
      return true;
    }
    Raven.captureException(error, {
      extra: {
        username,
        error
      }
    });
    return false;
  });
}