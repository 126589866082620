'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["label", "thickness", "speed", "emptyColor", "className"];
import { keyframes, forwardRef, useStyleConfig, omitThemingProps, chakra } from "../system";
import { cx } from "../shared-utils";
import { jsx } from 'react/jsx-runtime';

// src/spinner.tsx
var spin = keyframes({
  "0%": {
    transform: "rotate(0deg)"
  },
  "100%": {
    transform: "rotate(360deg)"
  }
});
var Spinner = forwardRef((props, ref) => {
  const styles = useStyleConfig("Spinner", props);
  const _omitThemingProps = omitThemingProps(props),
    {
      label = "Loading...",
      thickness = "2px",
      speed = "0.45s",
      emptyColor = "transparent",
      className
    } = _omitThemingProps,
    rest = _objectWithoutPropertiesLoose(_omitThemingProps, _excluded);
  const _className = cx("chakra-spinner", className);
  const spinnerStyles = Object.assign({
    display: "inline-block",
    borderColor: "currentColor",
    borderStyle: "solid",
    borderRadius: "99999px",
    borderWidth: thickness,
    borderBottomColor: emptyColor,
    borderLeftColor: emptyColor,
    animation: `${spin} ${speed} linear infinite`
  }, styles);
  return /* @__PURE__ */jsx(chakra.div, Object.assign({
    ref,
    __css: spinnerStyles,
    className: _className
  }, rest, {
    children: label && /* @__PURE__ */jsx(chakra.span, {
      srOnly: true,
      children: label
    })
  }));
});
Spinner.displayName = "Spinner";
export { Spinner };