import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
export default defineStyleConfig({
  variants: {
    mobile: defineStyle({
      color: 'CARNABY.regular',
      height: 5,
      width: 5,
      svg: {
        height: 'full',
        width: 'full'
      }
    })
  }
});