'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["children", "theme", "toastOptions"];
import { ChakraProvider as ChakraProvider$1 } from "../provider";
import { theme as theme$1, baseTheme } from "../theme";
export * from "../theme";
import { ToastOptionProvider, ToastProvider } from "../toast";
export * from "../toast";
import { jsxs, jsx } from 'react/jsx-runtime';
export * from "../accordion";
export * from "../alert";
export * from "../avatar";
export * from "../breadcrumb";
export * from "../button";
export * from "../card";
export * from "../checkbox";
export * from "../close-button";
export * from "../control-box";
export * from "../counter";
export * from "../css-reset";
export * from "../editable";
export * from "../focus-lock";
export * from "../form-control";
export * from "../hooks";
export * from "../icon";
export * from "../image";
export * from "../input";
export * from "../layout";
export * from "../media-query";
export * from "../menu";
export * from "../modal";
export * from "../number-input";
export * from "../pin-input";
export * from "../popover";
export * from "../popper";
export * from "../portal";
export * from "../progress";
export * from "../radio";
export * from "../react-env";
export * from "../select";
export * from "../skeleton";
export * from "../skip-nav";
export * from "../slider";
export * from "../spinner";
export * from "../stat";
export * from "../stepper";
export * from "../switch";
export * from "../system";
export * from "../table";
export * from "../tabs";
export * from "../tag";
export * from "../textarea";
export * from "../theme-utils";
export * from "../tooltip";
export * from "../transition";
export * from "../visually-hidden";

// src/chakra-provider.tsx
var createChakraProvider = providerTheme => {
  return function ChakraProvider2(_ref) {
    let {
        children,
        theme = providerTheme,
        toastOptions
      } = _ref,
      restProps = _objectWithoutPropertiesLoose(_ref, _excluded);
    return /* @__PURE__ */jsxs(ChakraProvider$1, Object.assign({
      theme
    }, restProps, {
      children: [/* @__PURE__ */jsx(ToastOptionProvider, {
        value: toastOptions == null ? void 0 : toastOptions.defaultOptions,
        children
      }), /* @__PURE__ */jsx(ToastProvider, Object.assign({}, toastOptions))]
    }));
  };
};
var ChakraProvider = createChakraProvider(theme$1);
var ChakraBaseProvider = createChakraProvider(baseTheme);
export { ChakraBaseProvider, ChakraProvider };