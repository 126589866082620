// https://github.com/ricokahler/color2k/blob/main/src/guard.ts

/**
 * A simple guard function:
 *
 * ```js
 * Math.min(Math.max(low, value), high)
 * ```
 */
function guard(low, high, value) {
  return Math.min(Math.max(low, value), high);
}
export default guard;