'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["as", "viewBox", "color", "focusable", "children", "className", "__css"];
import { forwardRef, useStyleConfig, chakra } from "../system";
import { cx } from "../shared-utils";
import { jsx, jsxs } from 'react/jsx-runtime';
import { Children } from 'react';

// src/icon.tsx
var fallbackIcon = {
  path: /* @__PURE__ */jsxs("g", {
    stroke: "currentColor",
    strokeWidth: "1.5",
    children: [/* @__PURE__ */jsx("path", {
      strokeLinecap: "round",
      fill: "none",
      d: "M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25"
    }), /* @__PURE__ */jsx("path", {
      fill: "currentColor",
      strokeLinecap: "round",
      d: "M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
    }), /* @__PURE__ */jsx("circle", {
      fill: "none",
      strokeMiterlimit: "10",
      cx: "12",
      cy: "12",
      r: "11.25"
    })]
  }),
  viewBox: "0 0 24 24"
};
var Icon = forwardRef((props, ref) => {
  const {
      as: element,
      viewBox,
      color = "currentColor",
      focusable = false,
      children,
      className,
      __css
    } = props,
    rest = _objectWithoutPropertiesLoose(props, _excluded);
  const _className = cx("chakra-icon", className);
  const customStyles = useStyleConfig("Icon", props);
  const styles = Object.assign({
    w: "1em",
    h: "1em",
    display: "inline-block",
    lineHeight: "1em",
    flexShrink: 0,
    color
  }, __css, customStyles);
  const shared = {
    ref,
    focusable,
    className: _className,
    __css: styles
  };
  const _viewBox = viewBox != null ? viewBox : fallbackIcon.viewBox;
  if (element && typeof element !== "string") {
    return /* @__PURE__ */jsx(chakra.svg, Object.assign({
      as: element
    }, shared, rest));
  }
  const _path = children != null ? children : fallbackIcon.path;
  return /* @__PURE__ */jsx(chakra.svg, Object.assign({
    verticalAlign: "middle",
    viewBox: _viewBox
  }, shared, rest, {
    children: _path
  }));
});
Icon.displayName = "Icon";
var icon_default = Icon;
function createIcon(options) {
  const {
    viewBox = "0 0 24 24",
    d: pathDefinition,
    displayName,
    defaultProps = {}
  } = options;
  const path = Children.toArray(options.path);
  const Comp = forwardRef((props, ref) => /* @__PURE__ */jsx(Icon, Object.assign({
    ref,
    viewBox
  }, defaultProps, props, {
    children: path.length ? path : /* @__PURE__ */jsx("path", {
      fill: "currentColor",
      d: pathDefinition
    })
  })));
  Comp.displayName = displayName;
  return Comp;
}
export { Icon, createIcon, icon_default as default };