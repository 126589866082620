import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { _greyscaleColors } from '../../colors';

// Workaround from https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
const $arrowBg = cssVar('popper-arrow-bg');
const baseStyle = {
  borderRadius: 'md',
  opacity: '0.2',
  backgroundColor: 'GREYSCALE.GALAXY',
  [$arrowBg.variable]: _greyscaleColors.GALAXY
};
const sizes = {
  lg: defineStyle({
    borderRadius: 'lg',
    fontSize: 'md',
    padding: '2'
  })
};

// export the component theme
const tooltipTheme = defineStyleConfig({
  baseStyle,
  sizes
});
export default tooltipTheme;