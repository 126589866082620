import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const helpers = createMultiStyleConfigHelpers(tagAnatomy.keys);
export default helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: 'sm'
    },
    label: {
      color: 'text.body',
      fontSize: 'xs'
    }
  },
  sizes: {
    sm: {
      container: {
        height: '21px',
        fontSize: '11px'
      }
    }
  },
  variants: {
    outline: {
      container: {
        backgroundColor: 'CARNABY.extraLight',
        boxShadow: 'inset 0 0 0px 1px var(--chakra-colors-CARNABY-medium)',
        color: 'ECLIPSE'
      }
    },
    goal: {
      label: {
        textTransform: 'uppercase',
        fontWeight: 600
      }
    },
    informational: {
      container: {
        bg: 'GREYSCALE.CONSTELLATION',
        fontWeight: 'medium',
        borderRadius: '3px'
      }
    },
    selectable: {
      container: {
        cursor: 'pointer',
        borderColor: 'GREYSCALE.SATELLITE',
        backgroundColor: 'GREYSCALE.MOON',
        borderWidth: '1px',
        borderRadius: '3px',
        _checked: {
          borderColor: 'CARNABY.medium',
          backgroundColor: 'CARNABY.extraLight',
          color: 'CARNABY.regular'
        },
        px: 3,
        py: 1.5
      }
    },
    selected: {
      container: {
        backgroundColor: 'CARNABY.extraLight',
        borderColor: 'CARNABY.medium',
        borderRadius: '3px',
        borderWidth: '1px',
        color: 'CARNABY.regular',
        cursor: 'pointer',
        px: 3,
        py: 1.5
      }
    }
  }
});