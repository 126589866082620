import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const {
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(menuAnatomy.keys);
const button = {
  fontSize: 'md',
  color: 'text.body',
  px: 3,
  py: 2,
  backgroundColor: 'GREYSCALE.CONSTELLATION',
  borderRadius: '2px',
  _hover: {
    bg: 'GREYSCALE.SATELLITE'
  }
};
const item = {
  paddingX: 0.5,
  paddingLeft: '1rem',
  paddingRight: '1rem',
  fontSize: 'sm',
  lineHeight: '36px',
  fontWeight: 'light',
  fontStyle: 'normal',
  _hover: {
    bg: 'GREYSCALE.SATELLITE'
  },
  _focus: {
    bg: 'inherit'
  },
  _selected: {
    bg: 'GREYSCALE.SATELLITE'
  },
  marginBottom: '2px'
};
const variants = {
  dropdownUnstyled: {
    button: {
      color: 'text.body',
      fontSize: 'xs',
      fontWeight: 'medium'
    },
    item
  },
  dropdown: {
    button,
    item
  }
};
export const menuTheme = defineMultiStyleConfig({
  variants
});