import { extendTheme } from '@chakra-ui/react';
import colors, { CARNABY } from './colors';
import components from './components';
import { fontSizes, fontWeights, fonts } from './typography';
import space from './space';
import { baseFontSize } from './constants';
import sizes from './components/sizes';
import gradients from './gradients';
const theme = extendTheme({
  styles: {
    global: {
      '#root, html, body': {
        height: '100%',
        color: 'text.body',
        fontSize: `${baseFontSize}px`
      },
      body: {
        color: 'text.body'
      },
      ':host,:root': {
        '--chakra-ui-focus-ring-color': CARNABY.light
      }
    }
  },
  shadows: {
    'focus-ring-color': CARNABY.light,
    outline: '0 0 0 3px var(--chakra-ui-focus-ring-color)'
  },
  colors,
  fontSizes,
  fontWeights,
  fonts,
  components,
  space,
  sizes,
  gradients
});
export default theme;