import { avatarAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { isDark, randomColorFromList } from '../../utils/colors/colorUtils';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(parts.keys);
const baseStyleContainer = defineStyle(props => {
  const {
    name,
    theme
  } = props;
  let {
    bg
  } = props;
  if (!bg) {
    bg = name ? randomColorFromList(name, ['CHERRY_BLOSSOM.medium', 'KYOTO.medium', 'PATAGONIA.medium', 'CARNABY.medium', 'SOLARIS.medium', 'MACARON.medium', 'ATLANTIC.medium', 'ZAMBA.medium', 'EMERALD.medium', 'GREYSCALE.CELESTIAL']) : 'colors.gray.400';
  }
  const isBgDark = isDark(bg)(theme);
  return {
    bg,
    color: isBgDark ? 'white' : 'gray.800'
  };
});
const xxl = defineStyle({
  width: 100,
  height: 100
});
const profile = defineStyle({
  width: 72,
  height: 72
});
const sizes = {
  xxl: definePartsStyle({
    container: xxl
  }),
  profile: definePartsStyle({
    container: profile
  })
};
const baseStyle = definePartsStyle(props => ({
  container: baseStyleContainer(props)
}));
export const avatarTheme = defineMultiStyleConfig({
  baseStyle,
  sizes
});