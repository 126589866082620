export const checkUsernameAvailabilityReducerInitialState = {
  isFetching: false,
  hasFetched: false,
  isAvailable: false,
  validatedUsername: undefined
};
export function checkUsernameAvailabilityReducer(state, action) {
  if (action.type === 'fetch') {
    return {
      isFetching: true,
      hasFetched: false,
      isAvailable: false,
      validatedUsername: undefined
    };
  } else if (action.type === 'receive') {
    return {
      isFetching: false,
      hasFetched: true,
      isAvailable: action.isAvailable,
      validatedUsername: action.validatedUsername
    };
  } else if (action.type === 'reset') {
    return checkUsernameAvailabilityReducerInitialState;
  }
  throw Error('Unknown action in checkUsernameAvailabilityReducer');
}