export function generateBasicBreakpointObject(baseValue, mdValue) {
  return {
    base: baseValue,
    md: mdValue
  };
}
export function generateBasicStackDirectionObject(baseValue, mdValue) {
  return [baseValue, mdValue];
}
export function generateBasicDisplayBreakpointObject(smallValue, mdValue) {
  return {
    base: smallValue,
    md: mdValue
  };
}
export function generateBasicSpacingBreakpointObject(smallValue, mdValue) {
  return {
    base: smallValue,
    md: mdValue
  };
}