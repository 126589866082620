'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["timeout"];
import { useRef, useEffect, useState, useLayoutEffect, useCallback, useMemo, useId as useId$1 } from 'react';
import { isBrowser, runIfFn, getOwnerDocument, wrapPointerEventHandler, getPointerEventName, noop, PanSession, callAllHandlers, hasFocusWithin, focus, getActiveElement, contains, isTabbable, detectBrowser, isRefObject, isActiveElement, getAllFocusable, getOwnerWindow, getBox } from "../utils";

// src/use-previous.ts
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

// src/use-shortcut.ts
function isPrintableCharacter(event) {
  const {
    key
  } = event;
  return key.length === 1 || key.length > 1 && /[^a-zA-Z0-9]/.test(key);
}
function useShortcut(props = {}) {
  const {
    timeout = 300,
    preventDefault = () => true
  } = props;
  const [keys, setKeys] = useState([]);
  const timeoutRef = useRef();
  const flush = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };
  const clearKeysAfterDelay = () => {
    flush();
    timeoutRef.current = setTimeout(() => {
      setKeys([]);
      timeoutRef.current = null;
    }, timeout);
  };
  useEffect(() => flush, []);
  function onKeyDown(fn) {
    return event => {
      if (event.key === "Backspace") {
        const keysCopy = [...keys];
        keysCopy.pop();
        setKeys(keysCopy);
        return;
      }
      if (isPrintableCharacter(event)) {
        const keysCopy = keys.concat(event.key);
        if (preventDefault(event)) {
          event.preventDefault();
          event.stopPropagation();
        }
        setKeys(keysCopy);
        fn(keysCopy.join(""));
        clearKeysAfterDelay();
      }
    };
  }
  return onKeyDown;
}

// src/use-safe-layout-effect.ts
var useSafeLayoutEffect = isBrowser ? useLayoutEffect : useEffect;
function useCallbackRef(fn, deps = []) {
  const ref = useRef(fn);
  useSafeLayoutEffect(() => {
    ref.current = fn;
  });
  return useCallback((...args) => {
    var _a;
    return (_a = ref.current) == null ? void 0 : _a.call(ref, ...args);
  }, deps);
}
function useTimeout(callback, delay) {
  const fn = useCallbackRef(callback);
  useEffect(() => {
    if (delay == null) return void 0;
    let timeoutId = null;
    timeoutId = window.setTimeout(() => {
      fn();
    }, delay);
    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [delay, fn]);
}

// src/use-why-update.ts
function useWhyDidYouUpdate(name, props) {
  const previousProps = useRef();
  useEffect(() => {
    if (previousProps.current) {
      const allKeys = Object.keys(Object.assign({}, previousProps.current, props));
      const changesObj = {};
      allKeys.forEach(key => {
        if (previousProps.current[key] !== props[key]) {
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key]
          };
        }
      });
      if (Object.keys(changesObj).length) {
        console.log("[why-did-you-update]", name, changesObj);
      }
    }
    previousProps.current = props;
  });
}
function useInterval(callback, delay) {
  const fn = useCallbackRef(callback);
  useEffect(() => {
    let intervalId = null;
    const tick = () => fn();
    if (delay !== null) {
      intervalId = window.setInterval(tick, delay);
    }
    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [delay, fn]);
}

// src/use-latest-ref.ts
function useLatestRef(value) {
  const ref = useRef(null);
  ref.current = value;
  return ref;
}

// src/use-merge-refs.ts
function assignRef(ref, value) {
  if (ref == null) return;
  if (typeof ref === "function") {
    ref(value);
    return;
  }
  try {
    ref.current = value;
  } catch (error) {
    throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
  }
}
function useMergeRefs(...refs) {
  return useMemo(() => {
    if (refs.every(ref => ref == null)) {
      return null;
    }
    return node => {
      refs.forEach(ref => {
        if (ref) assignRef(ref, node);
      });
    };
  }, refs);
}
function useEventListener(event, handler, env, options) {
  const listener = useCallbackRef(handler);
  useEffect(() => {
    var _a;
    const node = (_a = runIfFn(env)) != null ? _a : document;
    if (!handler) {
      return;
    }
    node.addEventListener(event, listener, options);
    return () => {
      node.removeEventListener(event, listener, options);
    };
  }, [event, env, options, listener, handler]);
  return () => {
    var _a;
    const node = (_a = runIfFn(env)) != null ? _a : document;
    node.removeEventListener(event, listener, options);
  };
}
function useMouseDownRef(shouldListen = true) {
  const mouseDownRef = useRef();
  useEventListener("mousedown", event => {
    if (shouldListen) {
      mouseDownRef.current = event.target;
    }
  });
  return mouseDownRef;
}
function useOutsideClick(props) {
  const {
    ref,
    handler,
    enabled = true
  } = props;
  const savedHandler = useCallbackRef(handler);
  const stateRef = useRef({
    isPointerDown: false,
    ignoreEmulatedMouseEvents: false
  });
  const state = stateRef.current;
  useEffect(() => {
    if (!enabled) return;
    const onPointerDown = e => {
      if (isValidEvent(e, ref)) {
        state.isPointerDown = true;
      }
    };
    const onMouseUp = event => {
      if (state.ignoreEmulatedMouseEvents) {
        state.ignoreEmulatedMouseEvents = false;
        return;
      }
      if (state.isPointerDown && handler && isValidEvent(event, ref)) {
        state.isPointerDown = false;
        savedHandler(event);
      }
    };
    const onTouchEnd = event => {
      state.ignoreEmulatedMouseEvents = true;
      if (handler && state.isPointerDown && isValidEvent(event, ref)) {
        state.isPointerDown = false;
        savedHandler(event);
      }
    };
    const doc = getOwnerDocument(ref.current);
    doc.addEventListener("mousedown", onPointerDown, true);
    doc.addEventListener("mouseup", onMouseUp, true);
    doc.addEventListener("touchstart", onPointerDown, true);
    doc.addEventListener("touchend", onTouchEnd, true);
    return () => {
      doc.removeEventListener("mousedown", onPointerDown, true);
      doc.removeEventListener("mouseup", onMouseUp, true);
      doc.removeEventListener("touchstart", onPointerDown, true);
      doc.removeEventListener("touchend", onTouchEnd, true);
    };
  }, [handler, ref, savedHandler, state, enabled]);
}
function isValidEvent(event, ref) {
  var _a;
  const target = event.target;
  if (target) {
    const doc = getOwnerDocument(target);
    if (!doc.contains(target)) return false;
  }
  return !((_a = ref.current) == null ? void 0 : _a.contains(target));
}
function usePointerEvent(env, eventName, handler, options) {
  return useEventListener(getPointerEventName(eventName), wrapPointerEventHandler(handler, eventName === "pointerdown"), env, options);
}

// src/use-unmount-effect.ts
function useUnmountEffect(fn, deps = []) {
  return useEffect(() => () => fn(), deps);
}
function usePanGesture(ref, props) {
  const {
    onPan,
    onPanStart,
    onPanEnd,
    onPanSessionStart,
    onPanSessionEnd,
    threshold
  } = props;
  const hasPanEvents = Boolean(onPan || onPanStart || onPanEnd || onPanSessionStart || onPanSessionEnd);
  const panSession = useRef(null);
  const handlers = {
    onSessionStart: onPanSessionStart,
    onSessionEnd: onPanSessionEnd,
    onStart: onPanStart,
    onMove: onPan,
    onEnd(event, info) {
      panSession.current = null;
      onPanEnd == null ? void 0 : onPanEnd(event, info);
    }
  };
  useEffect(() => {
    var _a;
    (_a = panSession.current) == null ? void 0 : _a.updateHandlers(handlers);
  });
  function onPointerDown(event) {
    panSession.current = new PanSession(event, handlers, threshold);
  }
  usePointerEvent(() => ref.current, "pointerdown", hasPanEvents ? onPointerDown : noop);
  useUnmountEffect(() => {
    var _a;
    (_a = panSession.current) == null ? void 0 : _a.end();
    panSession.current = null;
  });
}

// src/use-id.ts
function useId(idProp, prefix) {
  const id = useId$1();
  return useMemo(() => idProp || [prefix, id].filter(Boolean).join("-"), [idProp, prefix, id]);
}
function useIds(idProp, ...prefixes) {
  const id = useId(idProp);
  return useMemo(() => {
    return prefixes.map(prefix => `${prefix}-${id}`);
  }, [id, prefixes]);
}
function useOptionalPart(partId) {
  const [id, setId] = useState(null);
  const ref = useCallback(node => {
    setId(node ? partId : null);
  }, [partId]);
  return {
    ref,
    id,
    isRendered: Boolean(id)
  };
}
function useControllableProp(prop, state) {
  const isControlled = prop !== void 0;
  const value = isControlled && typeof prop !== "undefined" ? prop : state;
  return [isControlled, value];
}
function useControllableState(props) {
  const {
    value: valueProp,
    defaultValue,
    onChange,
    shouldUpdate = (prev, next) => prev !== next
  } = props;
  const onChangeProp = useCallbackRef(onChange);
  const shouldUpdateProp = useCallbackRef(shouldUpdate);
  const [valueState, setValue] = useState(defaultValue);
  const isControlled = valueProp !== void 0;
  const value = isControlled ? valueProp : valueState;
  const updateValue = useCallback(next => {
    const nextValue = runIfFn(next, value);
    if (!shouldUpdateProp(value, nextValue)) {
      return;
    }
    if (!isControlled) {
      setValue(nextValue);
    }
    onChangeProp(nextValue);
  }, [isControlled, onChangeProp, value, shouldUpdateProp]);
  return [value, updateValue];
}
function useDisclosure(props = {}) {
  const {
    onClose: onCloseProp,
    onOpen: onOpenProp,
    isOpen: isOpenProp,
    id: idProp
  } = props;
  const onOpenPropCallbackRef = useCallbackRef(onOpenProp);
  const onClosePropCallbackRef = useCallbackRef(onCloseProp);
  const [isOpenState, setIsOpen] = useState(props.defaultIsOpen || false);
  const [isControlled, isOpen] = useControllableProp(isOpenProp, isOpenState);
  const id = useId(idProp, "disclosure");
  const onClose = useCallback(() => {
    if (!isControlled) {
      setIsOpen(false);
    }
    onClosePropCallbackRef == null ? void 0 : onClosePropCallbackRef();
  }, [isControlled, onClosePropCallbackRef]);
  const onOpen = useCallback(() => {
    if (!isControlled) {
      setIsOpen(true);
    }
    onOpenPropCallbackRef == null ? void 0 : onOpenPropCallbackRef();
  }, [isControlled, onOpenPropCallbackRef]);
  const onToggle = useCallback(() => {
    const action = isOpen ? onClose : onOpen;
    action();
  }, [isOpen, onOpen, onClose]);
  return {
    isOpen: !!isOpen,
    onOpen,
    onClose,
    onToggle,
    isControlled,
    getButtonProps: (props2 = {}) => Object.assign({}, props2, {
      "aria-expanded": isOpen,
      "aria-controls": id,
      onClick: callAllHandlers(props2.onClick, onToggle)
    }),
    getDisclosureProps: (props2 = {}) => Object.assign({}, props2, {
      hidden: !isOpen,
      id
    })
  };
}

// src/use-event-listener-map.ts
function useEventListenerMap() {
  const listeners = useRef( /* @__PURE__ */new Map());
  const currentListeners = listeners.current;
  const add = useCallback((el, type, listener, options) => {
    const pointerEventListener = wrapPointerEventHandler(listener, type === "pointerdown");
    listeners.current.set(listener, {
      __listener: pointerEventListener,
      type: getPointerEventName(type),
      el,
      options
    });
    el.addEventListener(type, pointerEventListener, options);
  }, []);
  const remove = useCallback((el, type, listener, options) => {
    const {
      __listener: pointerEventListener
    } = listeners.current.get(listener);
    el.removeEventListener(type, pointerEventListener, options);
    listeners.current.delete(pointerEventListener);
  }, []);
  useEffect(() => () => {
    currentListeners.forEach((value, key) => {
      remove(value.el, value.type, key, value.options);
    });
  }, [remove, currentListeners]);
  return {
    add,
    remove
  };
}

// src/use-update-effect.ts
var useUpdateEffect = (effect, deps) => {
  const renderCycleRef = useRef(false);
  const effectCycleRef = useRef(false);
  useEffect(() => {
    const isMounted = renderCycleRef.current;
    const shouldRun = isMounted && effectCycleRef.current;
    if (shouldRun) {
      return effect();
    }
    effectCycleRef.current = true;
  }, deps);
  useEffect(() => {
    renderCycleRef.current = true;
    return () => {
      renderCycleRef.current = false;
    };
  }, []);
};
function useFocusEffect(ref, options) {
  const {
    shouldFocus,
    preventScroll
  } = options;
  useUpdateEffect(() => {
    const node = ref.current;
    if (!node || !shouldFocus) return;
    if (!hasFocusWithin(node)) {
      focus(node, {
        preventScroll,
        nextTick: true
      });
    }
  }, [shouldFocus, ref, preventScroll]);
}
function preventReturnFocus(containerRef) {
  const el = containerRef.current;
  if (!el) return false;
  const activeElement = getActiveElement(el);
  if (!activeElement) return false;
  if (contains(el, activeElement)) return false;
  if (isTabbable(activeElement)) return true;
  return false;
}
function useFocusOnHide(containerRef, options) {
  const {
    shouldFocus: shouldFocusProp,
    visible,
    focusRef
  } = options;
  const shouldFocus = shouldFocusProp && !visible;
  useUpdateEffect(() => {
    if (!shouldFocus) return;
    if (preventReturnFocus(containerRef)) {
      return;
    }
    const el = (focusRef == null ? void 0 : focusRef.current) || containerRef.current;
    if (el) {
      focus(el, {
        nextTick: true
      });
    }
  }, [shouldFocus, containerRef, focusRef]);
}
function useFocusOnPointerDown(props) {
  const {
    ref,
    elements,
    enabled
  } = props;
  const isSafari = detectBrowser("Safari");
  const doc = () => getOwnerDocument(ref.current);
  usePointerEvent(doc, "pointerdown", event => {
    if (!isSafari || !enabled) return;
    const target = event.target;
    const els = elements != null ? elements : [ref];
    const isValidTarget = els.some(elementOrRef => {
      const el = isRefObject(elementOrRef) ? elementOrRef.current : elementOrRef;
      return contains(el, target);
    });
    if (!isActiveElement(target) && isValidTarget) {
      event.preventDefault();
      focus(target);
    }
  });
}
var defaultOptions = {
  preventScroll: true,
  shouldFocus: false
};
function useFocusOnShow(target, options = defaultOptions) {
  const {
    focusRef,
    preventScroll,
    shouldFocus,
    visible
  } = options;
  const element = isRefObject(target) ? target.current : target;
  const autoFocusValue = shouldFocus && visible;
  const autoFocusRef = useRef(autoFocusValue);
  const lastVisibleRef = useRef(visible);
  useSafeLayoutEffect(() => {
    if (!lastVisibleRef.current && visible) {
      autoFocusRef.current = autoFocusValue;
    }
    lastVisibleRef.current = visible;
  }, [visible, autoFocusValue]);
  const onFocus = useCallback(() => {
    if (!visible || !element || !autoFocusRef.current) return;
    autoFocusRef.current = false;
    if (contains(element, document.activeElement)) return;
    if (focusRef == null ? void 0 : focusRef.current) {
      focus(focusRef.current, {
        preventScroll,
        nextTick: true
      });
    } else {
      const tabbableEls = getAllFocusable(element);
      if (tabbableEls.length > 0) {
        focus(tabbableEls[0], {
          preventScroll,
          nextTick: true
        });
      }
    }
  }, [visible, preventScroll, element, focusRef]);
  useUpdateEffect(() => {
    onFocus();
  }, [onFocus]);
  useEventListener("transitionend", onFocus, element);
}
function useForceUpdate() {
  const unloadingRef = useRef(false);
  const [count, setCount] = useState(0);
  useUnmountEffect(() => {
    unloadingRef.current = true;
  });
  return useCallback(() => {
    if (!unloadingRef.current) {
      setCount(count + 1);
    }
  }, [count]);
}
function useAnimationState(props) {
  const {
    isOpen,
    ref
  } = props;
  const [mounted, setMounted] = useState(isOpen);
  const [once, setOnce] = useState(false);
  useEffect(() => {
    if (!once) {
      setMounted(isOpen);
      setOnce(true);
    }
  }, [isOpen, once, mounted]);
  useEventListener("animationend", () => {
    setMounted(isOpen);
  }, () => ref.current);
  const hidden = isOpen ? false : !mounted;
  return {
    present: !hidden,
    onComplete() {
      var _a;
      const win = getOwnerWindow(ref.current);
      const evt = new win.CustomEvent("animationend", {
        bubbles: true
      });
      (_a = ref.current) == null ? void 0 : _a.dispatchEvent(evt);
    }
  };
}

// src/use-boolean.ts
function useBoolean(initialState = false) {
  const [value, setValue] = useState(initialState);
  const callbacks = useMemo(() => ({
    on: () => setValue(true),
    off: () => setValue(false),
    toggle: () => setValue(prev => !prev)
  }), []);
  return [value, callbacks];
}
var toggleSelection = function toggleSelection() {
  var selection = document.getSelection();
  if (!selection.rangeCount) {
    return function () {};
  }
  var active = document.activeElement;
  var ranges = [];
  for (var i = 0; i < selection.rangeCount; i++) {
    ranges.push(selection.getRangeAt(i));
  }
  switch (active.tagName.toUpperCase()) {
    // .toUpperCase handles XHTML
    case 'INPUT':
    case 'TEXTAREA':
      active.blur();
      break;
    default:
      active = null;
      break;
  }
  selection.removeAllRanges();
  return function () {
    selection.type === 'Caret' && selection.removeAllRanges();
    if (!selection.rangeCount) {
      ranges.forEach(function (range) {
        selection.addRange(range);
      });
    }
    active && active.focus();
  };
};
var deselectCurrent = toggleSelection;
var clipboardToIE11Formatting = {
  "text/plain": "Text",
  "text/html": "Url",
  "default": "Text"
};
var defaultMessage = "Copy to clipboard: #{key}, Enter";
function format(message) {
  var copyKey = (/mac os x/i.test(navigator.userAgent) ? "⌘" : "Ctrl") + "+C";
  return message.replace(/#{\s*key\s*}/g, copyKey);
}
function copy(text, options) {
  var debug,
    message,
    reselectPrevious,
    range,
    selection,
    mark,
    success = false;
  if (!options) {
    options = {};
  }
  debug = options.debug || false;
  try {
    reselectPrevious = deselectCurrent();
    range = document.createRange();
    selection = document.getSelection();
    mark = document.createElement("span");
    mark.textContent = text;
    // avoid screen readers from reading out loud the text
    mark.ariaHidden = "true";
    // reset user styles for span element
    mark.style.all = "unset";
    // prevents scrolling to the end of the page
    mark.style.position = "fixed";
    mark.style.top = 0;
    mark.style.clip = "rect(0, 0, 0, 0)";
    // used to preserve spaces and line breaks
    mark.style.whiteSpace = "pre";
    // do not inherit user-select (it may be `none`)
    mark.style.webkitUserSelect = "text";
    mark.style.MozUserSelect = "text";
    mark.style.msUserSelect = "text";
    mark.style.userSelect = "text";
    mark.addEventListener("copy", function (e) {
      e.stopPropagation();
      if (options.format) {
        e.preventDefault();
        if (typeof e.clipboardData === "undefined") {
          // IE 11
          debug && console.warn("unable to use e.clipboardData");
          debug && console.warn("trying IE specific stuff");
          window.clipboardData.clearData();
          var format = clipboardToIE11Formatting[options.format] || clipboardToIE11Formatting["default"];
          window.clipboardData.setData(format, text);
        } else {
          // all other browsers
          e.clipboardData.clearData();
          e.clipboardData.setData(options.format, text);
        }
      }
      if (options.onCopy) {
        e.preventDefault();
        options.onCopy(e.clipboardData);
      }
    });
    document.body.appendChild(mark);
    range.selectNodeContents(mark);
    selection.addRange(range);
    var successful = document.execCommand("copy");
    if (!successful) {
      throw new Error("copy command was unsuccessful");
    }
    success = true;
  } catch (err) {
    debug && console.error("unable to copy using execCommand: ", err);
    debug && console.warn("trying IE specific stuff");
    try {
      window.clipboardData.setData(options.format || "text", text);
      options.onCopy && options.onCopy(window.clipboardData);
      success = true;
    } catch (err) {
      debug && console.error("unable to copy using clipboardData: ", err);
      debug && console.error("falling back to prompt");
      message = format("message" in options ? options.message : defaultMessage);
      window.prompt(message, text);
    }
  } finally {
    if (selection) {
      if (typeof selection.removeRange == "function") {
        selection.removeRange(range);
      } else {
        selection.removeAllRanges();
      }
    }
    if (mark) {
      document.body.removeChild(mark);
    }
    reselectPrevious();
  }
  return success;
}
var copyToClipboard = copy;

// src/use-clipboard.ts
function useClipboard(value, optionsOrTimeout = {}) {
  const [hasCopied, setHasCopied] = useState(false);
  const [valueState, setValueState] = useState(value);
  useEffect(() => setValueState(value), [value]);
  const _ref = typeof optionsOrTimeout === "number" ? {
      timeout: optionsOrTimeout
    } : optionsOrTimeout,
    {
      timeout = 1500
    } = _ref,
    copyOptions = _objectWithoutPropertiesLoose(_ref, _excluded);
  const onCopy = useCallback(() => {
    const didCopy = copyToClipboard(valueState, copyOptions);
    setHasCopied(didCopy);
  }, [valueState, copyOptions]);
  useEffect(() => {
    let timeoutId = null;
    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false);
      }, timeout);
    }
    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [timeout, hasCopied]);
  return {
    value: valueState,
    setValue: setValueState,
    onCopy,
    hasCopied
  };
}

// src/use-const.ts
function useConst(init) {
  const ref = useRef(null);
  if (ref.current === null) {
    ref.current = typeof init === "function" ? init() : init;
  }
  return ref.current;
}
function useDimensions(ref, observe) {
  const [dimensions, setDimensions] = useState(null);
  const rafId = useRef();
  useSafeLayoutEffect(() => {
    function measure() {
      const node = ref.current;
      if (!node) return;
      rafId.current = requestAnimationFrame(() => {
        const boxModel = getBox(node);
        setDimensions(boxModel);
      });
    }
    measure();
    if (observe) {
      window.addEventListener("resize", measure);
      window.addEventListener("scroll", measure);
    }
    return () => {
      if (observe) {
        window.removeEventListener("resize", measure);
        window.removeEventListener("scroll", measure);
      }
      if (rafId.current) {
        cancelAnimationFrame(rafId.current);
      }
    };
  }, [observe]);
  return dimensions;
}
export { assignRef, useAnimationState, useBoolean, useCallbackRef, useClipboard, useConst, useControllableProp, useControllableState, useDimensions, useDisclosure, useEventListener, useEventListenerMap, useFocusEffect, useFocusOnHide, useFocusOnPointerDown, useFocusOnShow, useForceUpdate, useId, useIds, useInterval, useLatestRef, useMergeRefs, useMouseDownRef, useOptionalPart, useOutsideClick, usePanGesture, usePointerEvent, usePrevious, useSafeLayoutEffect, useShortcut, useTimeout, useUnmountEffect, useUpdateEffect, useWhyDidYouUpdate };