'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["status", "addRole"];
import { Icon } from "../icon";
import { jsx } from 'react/jsx-runtime';
import { createContext } from "../react-context";
import { Spinner } from "../spinner";
import { cx } from "../shared-utils";
import { forwardRef, chakra, omitThemingProps, useMultiStyleConfig } from "../system";

// src/icons.tsx
function CheckIcon(props) {
  return /* @__PURE__ */jsx(Icon, Object.assign({
    viewBox: "0 0 24 24"
  }, props, {
    children: /* @__PURE__ */jsx("path", {
      fill: "currentColor",
      d: "M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
    })
  }));
}
function InfoIcon(props) {
  return /* @__PURE__ */jsx(Icon, Object.assign({
    viewBox: "0 0 24 24"
  }, props, {
    children: /* @__PURE__ */jsx("path", {
      fill: "currentColor",
      d: "M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm.25,5a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,12.25,5ZM14.5,18.5h-4a1,1,0,0,1,0-2h.75a.25.25,0,0,0,.25-.25v-4.5a.25.25,0,0,0-.25-.25H10.5a1,1,0,0,1,0-2h1a2,2,0,0,1,2,2v4.75a.25.25,0,0,0,.25.25h.75a1,1,0,1,1,0,2Z"
    })
  }));
}
function WarningIcon(props) {
  return /* @__PURE__ */jsx(Icon, Object.assign({
    viewBox: "0 0 24 24"
  }, props, {
    children: /* @__PURE__ */jsx("path", {
      fill: "currentColor",
      d: "M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
    })
  }));
}
var [AlertProvider, useAlertContext] = createContext({
  name: "AlertContext",
  hookName: "useAlertContext",
  providerName: "<Alert />"
});
var [AlertStylesProvider, useAlertStyles] = createContext({
  name: `AlertStylesContext`,
  hookName: `useAlertStyles`,
  providerName: "<Alert />"
});
var STATUSES = {
  info: {
    icon: InfoIcon,
    colorScheme: "blue"
  },
  warning: {
    icon: WarningIcon,
    colorScheme: "orange"
  },
  success: {
    icon: CheckIcon,
    colorScheme: "green"
  },
  error: {
    icon: WarningIcon,
    colorScheme: "red"
  },
  loading: {
    icon: Spinner,
    colorScheme: "blue"
  }
};
function getStatusColorScheme(status) {
  return STATUSES[status].colorScheme;
}
function getStatusIcon(status) {
  return STATUSES[status].icon;
}
var AlertDescription = forwardRef(function AlertDescription2(props, ref) {
  const styles = useAlertStyles();
  const {
    status
  } = useAlertContext();
  const descriptionStyles = Object.assign({
    display: "inline"
  }, styles.description);
  return /* @__PURE__ */jsx(chakra.div, Object.assign({
    ref,
    "data-status": status
  }, props, {
    className: cx("chakra-alert__desc", props.className),
    __css: descriptionStyles
  }));
});
AlertDescription.displayName = "AlertDescription";
function AlertIcon(props) {
  const {
    status
  } = useAlertContext();
  const BaseIcon = getStatusIcon(status);
  const styles = useAlertStyles();
  const css = status === "loading" ? styles.spinner : styles.icon;
  return /* @__PURE__ */jsx(chakra.span, Object.assign({
    display: "inherit",
    "data-status": status
  }, props, {
    className: cx("chakra-alert__icon", props.className),
    __css: css,
    children: props.children || /* @__PURE__ */jsx(BaseIcon, {
      h: "100%",
      w: "100%"
    })
  }));
}
AlertIcon.displayName = "AlertIcon";
var AlertTitle = forwardRef(function AlertTitle2(props, ref) {
  const styles = useAlertStyles();
  const {
    status
  } = useAlertContext();
  return /* @__PURE__ */jsx(chakra.div, Object.assign({
    ref,
    "data-status": status
  }, props, {
    className: cx("chakra-alert__title", props.className),
    __css: styles.title
  }));
});
AlertTitle.displayName = "AlertTitle";
var Alert = forwardRef(function Alert2(props, ref) {
  var _a;
  const _omitThemingProps = omitThemingProps(props),
    {
      status = "info",
      addRole = true
    } = _omitThemingProps,
    rest = _objectWithoutPropertiesLoose(_omitThemingProps, _excluded);
  const colorScheme = (_a = props.colorScheme) != null ? _a : getStatusColorScheme(status);
  const styles = useMultiStyleConfig("Alert", Object.assign({}, props, {
    colorScheme
  }));
  const alertStyles = Object.assign({
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative",
    overflow: "hidden"
  }, styles.container);
  return /* @__PURE__ */jsx(AlertProvider, {
    value: {
      status
    },
    children: /* @__PURE__ */jsx(AlertStylesProvider, {
      value: styles,
      children: /* @__PURE__ */jsx(chakra.div, Object.assign({
        "data-status": status,
        role: addRole ? "alert" : void 0,
        ref
      }, rest, {
        className: cx("chakra-alert", props.className),
        __css: alertStyles
      }))
    })
  });
});
Alert.displayName = "Alert";
export { Alert, AlertDescription, AlertIcon, AlertTitle, useAlertStyles };