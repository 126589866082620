import http from 'hub-http/clients/noAuthApiClient';
import { hubletApi } from 'hub-http/middlewares/core';
import { getPortalId } from '../utils/portal-config';
import { getConfigForForm, Forms, getFormData } from '../utils/forms';
export function addUserToAndroidWaitlist(email) {
  const api = hubletApi('api', 'hsforms');
  const {
    formGuid
  } = getConfigForForm(Forms.ANDROID_WAITLIST);
  const url = `/submissions/v3/integration/submit/${getPortalId()}/${formGuid}`;
  return http.post(url, {
    data: getFormData(email, Forms.ANDROID_WAITLIST),
    api
  });
  return http.post('connect-community/v1/user-profiles/android-waitlist', {
    email
  });
}