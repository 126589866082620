import { defineStyle } from '@chakra-ui/react';
export default {
  variants: {
    micro: defineStyle({
      color: 'GREYSCALE.CELESTIAL',
      fontSize: 'xs',
      fontWeight: 'normal',
      textDecorationColor: 'GREYSCALE.CELESTIAL'
    })
  }
};