'use es6';

import { isObject } from "../shared-utils";
import { createMultiStyleConfigHelpers, cssVar, defineStyle, defineCssVars, defineStyleConfig } from "../styled-system";
import { switchAnatomy, tableAnatomy, tabsAnatomy, tagAnatomy, inputAnatomy, progressAnatomy, checkboxAnatomy, radioAnatomy, selectAnatomy, sliderAnatomy, statAnatomy, listAnatomy, menuAnatomy, modalAnatomy, numberInputAnatomy, popoverAnatomy, drawerAnatomy, editableAnatomy, formAnatomy, formErrorAnatomy, breadcrumbAnatomy, cardAnatomy, accordionAnatomy, alertAnatomy, avatarAnatomy } from "../anatomy";
import { cssVar as cssVar$1, calc, mode, getColor, transparentize, getColorVar, generateStripe, orient, randomColor, isDark } from "../theme-tools";

// src/utils/is-chakra-theme.ts
var requiredChakraThemeKeys = ["borders", "breakpoints", "colors", "components", "config", "direction", "fonts", "fontSizes", "fontWeights", "letterSpacings", "lineHeights", "radii", "shadows", "sizes", "space", "styles", "transition", "zIndices"];
function isChakraTheme(unit) {
  if (!isObject(unit)) {
    return false;
  }
  return requiredChakraThemeKeys.every(propertyName => Object.prototype.hasOwnProperty.call(unit, propertyName));
}

// src/foundations/transition.ts
var transitionProperty = {
  common: "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform",
  colors: "background-color, border-color, color, fill, stroke",
  dimensions: "width, height",
  position: "left, right, top, bottom",
  background: "background-color, background-image, background-position"
};
var transitionTimingFunction = {
  "ease-in": "cubic-bezier(0.4, 0, 1, 1)",
  "ease-out": "cubic-bezier(0, 0, 0.2, 1)",
  "ease-in-out": "cubic-bezier(0.4, 0, 0.2, 1)"
};
var transitionDuration = {
  "ultra-fast": "50ms",
  faster: "100ms",
  fast: "150ms",
  normal: "200ms",
  slow: "300ms",
  slower: "400ms",
  "ultra-slow": "500ms"
};
var transition = {
  property: transitionProperty,
  easing: transitionTimingFunction,
  duration: transitionDuration
};
var transition_default = transition;

// src/foundations/z-index.ts
var zIndices = {
  hide: -1,
  auto: "auto",
  base: 0,
  docked: 10,
  dropdown: 1e3,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800
};
var z_index_default = zIndices;

// src/foundations/borders.ts
var borders = {
  none: 0,
  "1px": "1px solid",
  "2px": "2px solid",
  "4px": "4px solid",
  "8px": "8px solid"
};
var borders_default = borders;

// src/foundations/breakpoints.ts
var breakpoints = {
  base: "0em",
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em"
};
var breakpoints_default = breakpoints;

// src/foundations/colors.ts
var colors = {
  transparent: "transparent",
  current: "currentColor",
  black: "#000000",
  white: "#FFFFFF",
  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.04)",
    100: "rgba(255, 255, 255, 0.06)",
    200: "rgba(255, 255, 255, 0.08)",
    300: "rgba(255, 255, 255, 0.16)",
    400: "rgba(255, 255, 255, 0.24)",
    500: "rgba(255, 255, 255, 0.36)",
    600: "rgba(255, 255, 255, 0.48)",
    700: "rgba(255, 255, 255, 0.64)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.92)"
  },
  blackAlpha: {
    50: "rgba(0, 0, 0, 0.04)",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.08)",
    300: "rgba(0, 0, 0, 0.16)",
    400: "rgba(0, 0, 0, 0.24)",
    500: "rgba(0, 0, 0, 0.36)",
    600: "rgba(0, 0, 0, 0.48)",
    700: "rgba(0, 0, 0, 0.64)",
    800: "rgba(0, 0, 0, 0.80)",
    900: "rgba(0, 0, 0, 0.92)"
  },
  gray: {
    50: "#F7FAFC",
    100: "#EDF2F7",
    200: "#E2E8F0",
    300: "#CBD5E0",
    400: "#A0AEC0",
    500: "#718096",
    600: "#4A5568",
    700: "#2D3748",
    800: "#1A202C",
    900: "#171923"
  },
  red: {
    50: "#FFF5F5",
    100: "#FED7D7",
    200: "#FEB2B2",
    300: "#FC8181",
    400: "#F56565",
    500: "#E53E3E",
    600: "#C53030",
    700: "#9B2C2C",
    800: "#822727",
    900: "#63171B"
  },
  orange: {
    50: "#FFFAF0",
    100: "#FEEBC8",
    200: "#FBD38D",
    300: "#F6AD55",
    400: "#ED8936",
    500: "#DD6B20",
    600: "#C05621",
    700: "#9C4221",
    800: "#7B341E",
    900: "#652B19"
  },
  yellow: {
    50: "#FFFFF0",
    100: "#FEFCBF",
    200: "#FAF089",
    300: "#F6E05E",
    400: "#ECC94B",
    500: "#D69E2E",
    600: "#B7791F",
    700: "#975A16",
    800: "#744210",
    900: "#5F370E"
  },
  green: {
    50: "#F0FFF4",
    100: "#C6F6D5",
    200: "#9AE6B4",
    300: "#68D391",
    400: "#48BB78",
    500: "#38A169",
    600: "#2F855A",
    700: "#276749",
    800: "#22543D",
    900: "#1C4532"
  },
  teal: {
    50: "#E6FFFA",
    100: "#B2F5EA",
    200: "#81E6D9",
    300: "#4FD1C5",
    400: "#38B2AC",
    500: "#319795",
    600: "#2C7A7B",
    700: "#285E61",
    800: "#234E52",
    900: "#1D4044"
  },
  blue: {
    50: "#ebf8ff",
    100: "#bee3f8",
    200: "#90cdf4",
    300: "#63b3ed",
    400: "#4299e1",
    500: "#3182ce",
    600: "#2b6cb0",
    700: "#2c5282",
    800: "#2a4365",
    900: "#1A365D"
  },
  cyan: {
    50: "#EDFDFD",
    100: "#C4F1F9",
    200: "#9DECF9",
    300: "#76E4F7",
    400: "#0BC5EA",
    500: "#00B5D8",
    600: "#00A3C4",
    700: "#0987A0",
    800: "#086F83",
    900: "#065666"
  },
  purple: {
    50: "#FAF5FF",
    100: "#E9D8FD",
    200: "#D6BCFA",
    300: "#B794F4",
    400: "#9F7AEA",
    500: "#805AD5",
    600: "#6B46C1",
    700: "#553C9A",
    800: "#44337A",
    900: "#322659"
  },
  pink: {
    50: "#FFF5F7",
    100: "#FED7E2",
    200: "#FBB6CE",
    300: "#F687B3",
    400: "#ED64A6",
    500: "#D53F8C",
    600: "#B83280",
    700: "#97266D",
    800: "#702459",
    900: "#521B41"
  },
  linkedin: {
    50: "#E8F4F9",
    100: "#CFEDFB",
    200: "#9BDAF3",
    300: "#68C7EC",
    400: "#34B3E4",
    500: "#00A0DC",
    600: "#008CC9",
    700: "#0077B5",
    800: "#005E93",
    900: "#004471"
  },
  facebook: {
    50: "#E8F4F9",
    100: "#D9DEE9",
    200: "#B7C2DA",
    300: "#6482C0",
    400: "#4267B2",
    500: "#385898",
    600: "#314E89",
    700: "#29487D",
    800: "#223B67",
    900: "#1E355B"
  },
  messenger: {
    50: "#D0E6FF",
    100: "#B9DAFF",
    200: "#A2CDFF",
    300: "#7AB8FF",
    400: "#2E90FF",
    500: "#0078FF",
    600: "#0063D1",
    700: "#0052AC",
    800: "#003C7E",
    900: "#002C5C"
  },
  whatsapp: {
    50: "#dffeec",
    100: "#b9f5d0",
    200: "#90edb3",
    300: "#65e495",
    400: "#3cdd78",
    500: "#22c35e",
    600: "#179848",
    700: "#0c6c33",
    800: "#01421c",
    900: "#001803"
  },
  twitter: {
    50: "#E5F4FD",
    100: "#C8E9FB",
    200: "#A8DCFA",
    300: "#83CDF7",
    400: "#57BBF5",
    500: "#1DA1F2",
    600: "#1A94DA",
    700: "#1681BF",
    800: "#136B9E",
    900: "#0D4D71"
  },
  telegram: {
    50: "#E3F2F9",
    100: "#C5E4F3",
    200: "#A2D4EC",
    300: "#7AC1E4",
    400: "#47A9DA",
    500: "#0088CC",
    600: "#007AB8",
    700: "#006BA1",
    800: "#005885",
    900: "#003F5E"
  }
};
var colors_default = colors;

// src/foundations/radius.ts
var radii = {
  none: "0",
  sm: "0.125rem",
  base: "0.25rem",
  md: "0.375rem",
  lg: "0.5rem",
  xl: "0.75rem",
  "2xl": "1rem",
  "3xl": "1.5rem",
  full: "9999px"
};
var radius_default = radii;

// src/foundations/shadows.ts
var shadows = {
  xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
  sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  base: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
  outline: "0 0 0 3px rgba(66, 153, 225, 0.6)",
  inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
  none: "none",
  "dark-lg": "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px"
};
var shadows_default = shadows;

// src/foundations/blur.ts
var blur = {
  none: 0,
  sm: "4px",
  base: "8px",
  md: "12px",
  lg: "16px",
  xl: "24px",
  "2xl": "40px",
  "3xl": "64px"
};
var blur_default = blur;

// src/foundations/typography.ts
var typography = {
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em"
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem"
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  fonts: {
    heading: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`
  },
  fontSizes: {
    "3xs": "0.45rem",
    "2xs": "0.625rem",
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem"
  }
};
var typography_default = typography;

// src/foundations/spacing.ts
var spacing = {
  px: "1px",
  0.5: "0.125rem",
  1: "0.25rem",
  1.5: "0.375rem",
  2: "0.5rem",
  2.5: "0.625rem",
  3: "0.75rem",
  3.5: "0.875rem",
  4: "1rem",
  5: "1.25rem",
  6: "1.5rem",
  7: "1.75rem",
  8: "2rem",
  9: "2.25rem",
  10: "2.5rem",
  12: "3rem",
  14: "3.5rem",
  16: "4rem",
  20: "5rem",
  24: "6rem",
  28: "7rem",
  32: "8rem",
  36: "9rem",
  40: "10rem",
  44: "11rem",
  48: "12rem",
  52: "13rem",
  56: "14rem",
  60: "15rem",
  64: "16rem",
  72: "18rem",
  80: "20rem",
  96: "24rem"
};

// src/foundations/sizes.ts
var largeSizes = {
  max: "max-content",
  min: "min-content",
  full: "100%",
  "3xs": "14rem",
  "2xs": "16rem",
  xs: "20rem",
  sm: "24rem",
  md: "28rem",
  lg: "32rem",
  xl: "36rem",
  "2xl": "42rem",
  "3xl": "48rem",
  "4xl": "56rem",
  "5xl": "64rem",
  "6xl": "72rem",
  "7xl": "80rem",
  "8xl": "90rem",
  prose: "60ch"
};
var container = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px"
};
var sizes$m = Object.assign({}, spacing, largeSizes, {
  container
});
var sizes_default = sizes$m;

// src/foundations/index.ts
var foundations = Object.assign({
  breakpoints: breakpoints_default,
  zIndices: z_index_default,
  radii: radius_default,
  blur: blur_default,
  colors: colors_default
}, typography_default, {
  sizes: sizes_default,
  shadows: shadows_default,
  space: spacing,
  borders: borders_default,
  transition: transition_default
});

// src/components/stepper.ts
var {
  defineMultiStyleConfig: defineMultiStyleConfig$p,
  definePartsStyle: definePartsStyle$p
} = createMultiStyleConfigHelpers(["stepper", "step", "title", "description", "indicator", "separator", "icon", "number"]);
var $size$4 = cssVar("stepper-indicator-size");
var $iconSize = cssVar("stepper-icon-size");
var $titleFontSize = cssVar("stepper-title-font-size");
var $descFontSize = cssVar("stepper-description-font-size");
var $accentColor = cssVar("stepper-accent-color");
var baseStyle$F = definePartsStyle$p(({
  colorScheme: c
}) => ({
  stepper: {
    display: "flex",
    justifyContent: "space-between",
    gap: "4",
    "&[data-orientation=vertical]": {
      flexDirection: "column",
      alignItems: "flex-start"
    },
    "&[data-orientation=horizontal]": {
      flexDirection: "row",
      alignItems: "center"
    },
    [$accentColor.variable]: `colors.${c}.500`,
    _dark: {
      [$accentColor.variable]: `colors.${c}.200`
    }
  },
  title: {
    fontSize: $titleFontSize.reference,
    fontWeight: "medium"
  },
  description: {
    fontSize: $descFontSize.reference,
    color: "chakra-subtle-text"
  },
  number: {
    fontSize: $titleFontSize.reference
  },
  step: {
    flexShrink: 0,
    position: "relative",
    display: "flex",
    gap: "2",
    "&[data-orientation=horizontal]": {
      alignItems: "center"
    },
    flex: "1",
    "&:last-of-type:not([data-stretch])": {
      flex: "initial"
    }
  },
  icon: {
    flexShrink: 0,
    width: $iconSize.reference,
    height: $iconSize.reference
  },
  indicator: {
    flexShrink: 0,
    borderRadius: "full",
    width: $size$4.reference,
    height: $size$4.reference,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&[data-status=active]": {
      borderWidth: "2px",
      borderColor: $accentColor.reference
    },
    "&[data-status=complete]": {
      bg: $accentColor.reference,
      color: "chakra-inverse-text"
    },
    "&[data-status=incomplete]": {
      borderWidth: "2px"
    }
  },
  separator: {
    bg: "chakra-border-color",
    flex: "1",
    "&[data-status=complete]": {
      bg: $accentColor.reference
    },
    "&[data-orientation=horizontal]": {
      width: "100%",
      height: "2px",
      marginStart: "2"
    },
    "&[data-orientation=vertical]": {
      width: "2px",
      position: "absolute",
      height: "100%",
      maxHeight: `calc(100% - ${$size$4.reference} - 8px)`,
      top: `calc(${$size$4.reference} + 4px)`,
      insetStart: `calc(${$size$4.reference} / 2 - 1px)`
    }
  }
}));
var stepperTheme = defineMultiStyleConfig$p({
  baseStyle: baseStyle$F,
  sizes: {
    xs: definePartsStyle$p({
      stepper: {
        [$size$4.variable]: "sizes.4",
        [$iconSize.variable]: "sizes.3",
        [$titleFontSize.variable]: "fontSizes.xs",
        [$descFontSize.variable]: "fontSizes.xs"
      }
    }),
    sm: definePartsStyle$p({
      stepper: {
        [$size$4.variable]: "sizes.6",
        [$iconSize.variable]: "sizes.4",
        [$titleFontSize.variable]: "fontSizes.sm",
        [$descFontSize.variable]: "fontSizes.xs"
      }
    }),
    md: definePartsStyle$p({
      stepper: {
        [$size$4.variable]: "sizes.8",
        [$iconSize.variable]: "sizes.5",
        [$titleFontSize.variable]: "fontSizes.md",
        [$descFontSize.variable]: "fontSizes.sm"
      }
    }),
    lg: definePartsStyle$p({
      stepper: {
        [$size$4.variable]: "sizes.10",
        [$iconSize.variable]: "sizes.6",
        [$titleFontSize.variable]: "fontSizes.lg",
        [$descFontSize.variable]: "fontSizes.md"
      }
    })
  },
  defaultProps: {
    size: "md",
    colorScheme: "blue"
  }
});

// src/components/switch.ts
var {
  defineMultiStyleConfig: defineMultiStyleConfig$o,
  definePartsStyle: definePartsStyle$o
} = createMultiStyleConfigHelpers(switchAnatomy.keys);
var $width = cssVar$1("switch-track-width");
var $height = cssVar$1("switch-track-height");
var $diff = cssVar$1("switch-track-diff");
var diffValue = calc.subtract($width, $height);
var $translateX = cssVar$1("switch-thumb-x");
var $bg$f = cssVar$1("switch-bg");
var baseStyleTrack$2 = defineStyle(props => {
  const {
    colorScheme: c
  } = props;
  return {
    borderRadius: "full",
    p: "0.5",
    width: [$width.reference],
    height: [$height.reference],
    transitionProperty: "common",
    transitionDuration: "fast",
    [$bg$f.variable]: "colors.gray.300",
    _dark: {
      [$bg$f.variable]: "colors.whiteAlpha.400"
    },
    _focusVisible: {
      boxShadow: "outline"
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    },
    _checked: {
      [$bg$f.variable]: `colors.${c}.500`,
      _dark: {
        [$bg$f.variable]: `colors.${c}.200`
      }
    },
    bg: $bg$f.reference
  };
});
var baseStyleThumb$1 = defineStyle({
  bg: "white",
  transitionProperty: "transform",
  transitionDuration: "normal",
  borderRadius: "inherit",
  width: [$height.reference],
  height: [$height.reference],
  _checked: {
    transform: `translateX(${$translateX.reference})`
  }
});
var baseStyle$E = definePartsStyle$o(props => ({
  container: {
    [$diff.variable]: diffValue,
    [$translateX.variable]: $diff.reference,
    _rtl: {
      [$translateX.variable]: calc($diff).negate().toString()
    }
  },
  track: baseStyleTrack$2(props),
  thumb: baseStyleThumb$1
}));
var sizes$l = {
  sm: definePartsStyle$o({
    container: {
      [$width.variable]: "1.375rem",
      [$height.variable]: "sizes.3"
    }
  }),
  md: definePartsStyle$o({
    container: {
      [$width.variable]: "1.875rem",
      [$height.variable]: "sizes.4"
    }
  }),
  lg: definePartsStyle$o({
    container: {
      [$width.variable]: "2.875rem",
      [$height.variable]: "sizes.6"
    }
  })
};
var switchTheme = defineMultiStyleConfig$o({
  baseStyle: baseStyle$E,
  sizes: sizes$l,
  defaultProps: {
    size: "md",
    colorScheme: "blue"
  }
});

// src/components/table.ts
var {
  defineMultiStyleConfig: defineMultiStyleConfig$n,
  definePartsStyle: definePartsStyle$n
} = createMultiStyleConfigHelpers(tableAnatomy.keys);
var baseStyle$D = definePartsStyle$n({
  table: {
    fontVariantNumeric: "lining-nums tabular-nums",
    borderCollapse: "collapse",
    width: "full"
  },
  th: {
    fontFamily: "heading",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "wider",
    textAlign: "start"
  },
  td: {
    textAlign: "start"
  },
  caption: {
    mt: 4,
    fontFamily: "heading",
    textAlign: "center",
    fontWeight: "medium"
  }
});
var numericStyles = defineStyle({
  "&[data-is-numeric=true]": {
    textAlign: "end"
  }
});
var variantSimple = definePartsStyle$n(props => {
  const {
    colorScheme: c
  } = props;
  return {
    th: Object.assign({
      color: mode("gray.600", "gray.400")(props),
      borderBottom: "1px",
      borderColor: mode(`${c}.100`, `${c}.700`)(props)
    }, numericStyles),
    td: Object.assign({
      borderBottom: "1px",
      borderColor: mode(`${c}.100`, `${c}.700`)(props)
    }, numericStyles),
    caption: {
      color: mode("gray.600", "gray.100")(props)
    },
    tfoot: {
      tr: {
        "&:last-of-type": {
          th: {
            borderBottomWidth: 0
          }
        }
      }
    }
  };
});
var variantStripe = definePartsStyle$n(props => {
  const {
    colorScheme: c
  } = props;
  return {
    th: Object.assign({
      color: mode("gray.600", "gray.400")(props),
      borderBottom: "1px",
      borderColor: mode(`${c}.100`, `${c}.700`)(props)
    }, numericStyles),
    td: Object.assign({
      borderBottom: "1px",
      borderColor: mode(`${c}.100`, `${c}.700`)(props)
    }, numericStyles),
    caption: {
      color: mode("gray.600", "gray.100")(props)
    },
    tbody: {
      tr: {
        "&:nth-of-type(odd)": {
          "th, td": {
            borderBottomWidth: "1px",
            borderColor: mode(`${c}.100`, `${c}.700`)(props)
          },
          td: {
            background: mode(`${c}.100`, `${c}.700`)(props)
          }
        }
      }
    },
    tfoot: {
      tr: {
        "&:last-of-type": {
          th: {
            borderBottomWidth: 0
          }
        }
      }
    }
  };
});
var variants$b = {
  simple: variantSimple,
  striped: variantStripe,
  unstyled: defineStyle({})
};
var sizes$k = {
  sm: definePartsStyle$n({
    th: {
      px: "4",
      py: "1",
      lineHeight: "4",
      fontSize: "xs"
    },
    td: {
      px: "4",
      py: "2",
      fontSize: "sm",
      lineHeight: "4"
    },
    caption: {
      px: "4",
      py: "2",
      fontSize: "xs"
    }
  }),
  md: definePartsStyle$n({
    th: {
      px: "6",
      py: "3",
      lineHeight: "4",
      fontSize: "xs"
    },
    td: {
      px: "6",
      py: "4",
      lineHeight: "5"
    },
    caption: {
      px: "6",
      py: "2",
      fontSize: "sm"
    }
  }),
  lg: definePartsStyle$n({
    th: {
      px: "8",
      py: "4",
      lineHeight: "5",
      fontSize: "sm"
    },
    td: {
      px: "8",
      py: "5",
      lineHeight: "6"
    },
    caption: {
      px: "6",
      py: "2",
      fontSize: "md"
    }
  })
};
var tableTheme = defineMultiStyleConfig$n({
  baseStyle: baseStyle$D,
  variants: variants$b,
  sizes: sizes$k,
  defaultProps: {
    variant: "simple",
    size: "md",
    colorScheme: "gray"
  }
});

// src/components/tabs.ts
var $fg$5 = cssVar("tabs-color");
var $bg$e = cssVar("tabs-bg");
var $border$3 = cssVar("tabs-border-color");
var {
  defineMultiStyleConfig: defineMultiStyleConfig$m,
  definePartsStyle: definePartsStyle$m
} = createMultiStyleConfigHelpers(tabsAnatomy.keys);
var baseStyleRoot$1 = defineStyle(props => {
  const {
    orientation
  } = props;
  return {
    display: orientation === "vertical" ? "flex" : "block"
  };
});
var baseStyleTab = defineStyle(props => {
  const {
    isFitted
  } = props;
  return {
    flex: isFitted ? 1 : void 0,
    transitionProperty: "common",
    transitionDuration: "normal",
    _focusVisible: {
      zIndex: 1,
      boxShadow: "outline"
    },
    _disabled: {
      cursor: "not-allowed",
      opacity: 0.4
    }
  };
});
var baseStyleTablist = defineStyle(props => {
  const {
    align = "start",
    orientation
  } = props;
  const alignments = {
    end: "flex-end",
    center: "center",
    start: "flex-start"
  };
  return {
    justifyContent: alignments[align],
    flexDirection: orientation === "vertical" ? "column" : "row"
  };
});
var baseStyleTabpanel = defineStyle({
  p: 4
});
var baseStyle$C = definePartsStyle$m(props => ({
  root: baseStyleRoot$1(props),
  tab: baseStyleTab(props),
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel
}));
var sizes$j = {
  sm: definePartsStyle$m({
    tab: {
      py: 1,
      px: 4,
      fontSize: "sm"
    }
  }),
  md: definePartsStyle$m({
    tab: {
      fontSize: "md",
      py: 2,
      px: 4
    }
  }),
  lg: definePartsStyle$m({
    tab: {
      fontSize: "lg",
      py: 3,
      px: 4
    }
  })
};
var variantLine = definePartsStyle$m(props => {
  const {
    colorScheme: c,
    orientation
  } = props;
  const isVertical = orientation === "vertical";
  const borderProp = isVertical ? "borderStart" : "borderBottom";
  const marginProp = isVertical ? "marginStart" : "marginBottom";
  return {
    tablist: {
      [borderProp]: "2px solid",
      borderColor: "inherit"
    },
    tab: {
      [borderProp]: "2px solid",
      borderColor: "transparent",
      [marginProp]: "-2px",
      _selected: {
        [$fg$5.variable]: `colors.${c}.600`,
        _dark: {
          [$fg$5.variable]: `colors.${c}.300`
        },
        borderColor: "currentColor"
      },
      _active: {
        [$bg$e.variable]: "colors.gray.200",
        _dark: {
          [$bg$e.variable]: "colors.whiteAlpha.300"
        }
      },
      _disabled: {
        _active: {
          bg: "none"
        }
      },
      color: $fg$5.reference,
      bg: $bg$e.reference
    }
  };
});
var variantEnclosed = definePartsStyle$m(props => {
  const {
    colorScheme: c
  } = props;
  return {
    tab: {
      borderTopRadius: "md",
      border: "1px solid",
      borderColor: "transparent",
      mb: "-1px",
      [$border$3.variable]: "transparent",
      _selected: {
        [$fg$5.variable]: `colors.${c}.600`,
        [$border$3.variable]: `colors.white`,
        _dark: {
          [$fg$5.variable]: `colors.${c}.300`,
          [$border$3.variable]: `colors.gray.800`
        },
        borderColor: "inherit",
        borderBottomColor: $border$3.reference
      },
      color: $fg$5.reference
    },
    tablist: {
      mb: "-1px",
      borderBottom: "1px solid",
      borderColor: "inherit"
    }
  };
});
var variantEnclosedColored = definePartsStyle$m(props => {
  const {
    colorScheme: c
  } = props;
  return {
    tab: {
      border: "1px solid",
      borderColor: "inherit",
      [$bg$e.variable]: "colors.gray.50",
      _dark: {
        [$bg$e.variable]: "colors.whiteAlpha.50"
      },
      mb: "-1px",
      _notLast: {
        marginEnd: "-1px"
      },
      _selected: {
        [$bg$e.variable]: "colors.white",
        [$fg$5.variable]: `colors.${c}.600`,
        _dark: {
          [$bg$e.variable]: "colors.gray.800",
          [$fg$5.variable]: `colors.${c}.300`
        },
        borderColor: "inherit",
        borderTopColor: "currentColor",
        borderBottomColor: "transparent"
      },
      color: $fg$5.reference,
      bg: $bg$e.reference
    },
    tablist: {
      mb: "-1px",
      borderBottom: "1px solid",
      borderColor: "inherit"
    }
  };
});
var variantSoftRounded = definePartsStyle$m(props => {
  const {
    colorScheme: c,
    theme
  } = props;
  return {
    tab: {
      borderRadius: "full",
      fontWeight: "semibold",
      color: "gray.600",
      _selected: {
        color: getColor(theme, `${c}.700`),
        bg: getColor(theme, `${c}.100`)
      }
    }
  };
});
var variantSolidRounded = definePartsStyle$m(props => {
  const {
    colorScheme: c
  } = props;
  return {
    tab: {
      borderRadius: "full",
      fontWeight: "semibold",
      [$fg$5.variable]: "colors.gray.600",
      _dark: {
        [$fg$5.variable]: "inherit"
      },
      _selected: {
        [$fg$5.variable]: "colors.white",
        [$bg$e.variable]: `colors.${c}.600`,
        _dark: {
          [$fg$5.variable]: "colors.gray.800",
          [$bg$e.variable]: `colors.${c}.300`
        }
      },
      color: $fg$5.reference,
      bg: $bg$e.reference
    }
  };
});
var variantUnstyled$2 = definePartsStyle$m({});
var variants$a = {
  line: variantLine,
  enclosed: variantEnclosed,
  "enclosed-colored": variantEnclosedColored,
  "soft-rounded": variantSoftRounded,
  "solid-rounded": variantSolidRounded,
  unstyled: variantUnstyled$2
};
var tabsTheme = defineMultiStyleConfig$m({
  baseStyle: baseStyle$C,
  sizes: sizes$j,
  variants: variants$a,
  defaultProps: {
    size: "md",
    variant: "line",
    colorScheme: "blue"
  }
});

// src/components/badge.ts
var vars = defineCssVars("badge", ["bg", "color", "shadow"]);
var baseStyle$B = defineStyle({
  px: 1,
  textTransform: "uppercase",
  fontSize: "xs",
  borderRadius: "sm",
  fontWeight: "bold",
  bg: vars.bg.reference,
  color: vars.color.reference,
  boxShadow: vars.shadow.reference
});
var variantSolid$3 = defineStyle(props => {
  const {
    colorScheme: c,
    theme
  } = props;
  const dark = transparentize(`${c}.500`, 0.6)(theme);
  return {
    [vars.bg.variable]: `colors.${c}.500`,
    [vars.color.variable]: `colors.white`,
    _dark: {
      [vars.bg.variable]: dark,
      [vars.color.variable]: `colors.whiteAlpha.800`
    }
  };
});
var variantSubtle$1 = defineStyle(props => {
  const {
    colorScheme: c,
    theme
  } = props;
  const darkBg = transparentize(`${c}.200`, 0.16)(theme);
  return {
    [vars.bg.variable]: `colors.${c}.100`,
    [vars.color.variable]: `colors.${c}.800`,
    _dark: {
      [vars.bg.variable]: darkBg,
      [vars.color.variable]: `colors.${c}.200`
    }
  };
});
var variantOutline$2 = defineStyle(props => {
  const {
    colorScheme: c,
    theme
  } = props;
  const darkColor = transparentize(`${c}.200`, 0.8)(theme);
  return {
    [vars.color.variable]: `colors.${c}.500`,
    _dark: {
      [vars.color.variable]: darkColor
    },
    [vars.shadow.variable]: `inset 0 0 0px 1px ${vars.color.reference}`
  };
});
var variants$9 = {
  solid: variantSolid$3,
  subtle: variantSubtle$1,
  outline: variantOutline$2
};
var badgeTheme = defineStyleConfig({
  baseStyle: baseStyle$B,
  variants: variants$9,
  defaultProps: {
    variant: "subtle",
    colorScheme: "gray"
  }
});
var {
  defineMultiStyleConfig: defineMultiStyleConfig$l,
  definePartsStyle: definePartsStyle$l
} = createMultiStyleConfigHelpers(tagAnatomy.keys);
var $bg$d = cssVar("tag-bg");
var $color = cssVar("tag-color");
var $shadow$3 = cssVar("tag-shadow");
var $minH = cssVar("tag-min-height");
var $minW = cssVar("tag-min-width");
var $fontSize = cssVar("tag-font-size");
var $paddingX = cssVar("tag-padding-inline");
var baseStyleContainer$4 = defineStyle({
  fontWeight: "medium",
  lineHeight: 1.2,
  outline: 0,
  [$color.variable]: vars.color.reference,
  [$bg$d.variable]: vars.bg.reference,
  [$shadow$3.variable]: vars.shadow.reference,
  color: $color.reference,
  bg: $bg$d.reference,
  boxShadow: $shadow$3.reference,
  borderRadius: "md",
  minH: $minH.reference,
  minW: $minW.reference,
  fontSize: $fontSize.reference,
  px: $paddingX.reference,
  _focusVisible: {
    [$shadow$3.variable]: "shadows.outline"
  }
});
var baseStyleLabel$4 = defineStyle({
  lineHeight: 1.2,
  overflow: "visible"
});
var baseStyleCloseButton$3 = defineStyle({
  fontSize: "lg",
  w: "5",
  h: "5",
  transitionProperty: "common",
  transitionDuration: "normal",
  borderRadius: "full",
  marginStart: "1.5",
  marginEnd: "-1",
  opacity: 0.5,
  _disabled: {
    opacity: 0.4
  },
  _focusVisible: {
    boxShadow: "outline",
    bg: "rgba(0, 0, 0, 0.14)"
  },
  _hover: {
    opacity: 0.8
  },
  _active: {
    opacity: 1
  }
});
var baseStyle$A = definePartsStyle$l({
  container: baseStyleContainer$4,
  label: baseStyleLabel$4,
  closeButton: baseStyleCloseButton$3
});
var sizes$i = {
  sm: definePartsStyle$l({
    container: {
      [$minH.variable]: "sizes.5",
      [$minW.variable]: "sizes.5",
      [$fontSize.variable]: "fontSizes.xs",
      [$paddingX.variable]: "space.2"
    },
    closeButton: {
      marginEnd: "-2px",
      marginStart: "0.35rem"
    }
  }),
  md: definePartsStyle$l({
    container: {
      [$minH.variable]: "sizes.6",
      [$minW.variable]: "sizes.6",
      [$fontSize.variable]: "fontSizes.sm",
      [$paddingX.variable]: "space.2"
    }
  }),
  lg: definePartsStyle$l({
    container: {
      [$minH.variable]: "sizes.8",
      [$minW.variable]: "sizes.8",
      [$fontSize.variable]: "fontSizes.md",
      [$paddingX.variable]: "space.3"
    }
  })
};
var variants$8 = {
  subtle: definePartsStyle$l(props => {
    var _a;
    return {
      container: (_a = badgeTheme.variants) == null ? void 0 : _a.subtle(props)
    };
  }),
  solid: definePartsStyle$l(props => {
    var _a;
    return {
      container: (_a = badgeTheme.variants) == null ? void 0 : _a.solid(props)
    };
  }),
  outline: definePartsStyle$l(props => {
    var _a;
    return {
      container: (_a = badgeTheme.variants) == null ? void 0 : _a.outline(props)
    };
  })
};
var tagTheme = defineMultiStyleConfig$l({
  variants: variants$8,
  baseStyle: baseStyle$A,
  sizes: sizes$i,
  defaultProps: {
    size: "md",
    variant: "subtle",
    colorScheme: "gray"
  }
});

// src/components/input.ts
var {
  definePartsStyle: definePartsStyle$k,
  defineMultiStyleConfig: defineMultiStyleConfig$k
} = createMultiStyleConfigHelpers(inputAnatomy.keys);
var baseStyle$z = definePartsStyle$k({
  field: {
    width: "100%",
    minWidth: 0,
    outline: 0,
    position: "relative",
    appearance: "none",
    transitionProperty: "common",
    transitionDuration: "normal",
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    }
  }
});
var size = {
  lg: defineStyle({
    fontSize: "lg",
    px: "4",
    h: "12",
    borderRadius: "md"
  }),
  md: defineStyle({
    fontSize: "md",
    px: "4",
    h: "10",
    borderRadius: "md"
  }),
  sm: defineStyle({
    fontSize: "sm",
    px: "3",
    h: "8",
    borderRadius: "sm"
  }),
  xs: defineStyle({
    fontSize: "xs",
    px: "2",
    h: "6",
    borderRadius: "sm"
  })
};
var sizes$h = {
  lg: definePartsStyle$k({
    field: size.lg,
    addon: size.lg
  }),
  md: definePartsStyle$k({
    field: size.md,
    addon: size.md
  }),
  sm: definePartsStyle$k({
    field: size.sm,
    addon: size.sm
  }),
  xs: definePartsStyle$k({
    field: size.xs,
    addon: size.xs
  })
};
function getDefaults(props) {
  const {
    focusBorderColor: fc,
    errorBorderColor: ec
  } = props;
  return {
    focusBorderColor: fc || mode("blue.500", "blue.300")(props),
    errorBorderColor: ec || mode("red.500", "red.300")(props)
  };
}
var variantOutline$1 = definePartsStyle$k(props => {
  const {
    theme
  } = props;
  const {
    focusBorderColor: fc,
    errorBorderColor: ec
  } = getDefaults(props);
  return {
    field: {
      border: "1px solid",
      borderColor: "inherit",
      bg: "inherit",
      _hover: {
        borderColor: mode("gray.300", "whiteAlpha.400")(props)
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all"
      },
      _invalid: {
        borderColor: getColorVar(theme, ec),
        boxShadow: `0 0 0 1px ${getColorVar(theme, ec)}`
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: getColorVar(theme, fc),
        boxShadow: `0 0 0 1px ${getColorVar(theme, fc)}`
      }
    },
    addon: {
      border: "1px solid",
      borderColor: mode("inherit", "whiteAlpha.50")(props),
      bg: mode("gray.100", "whiteAlpha.300")(props)
    }
  };
});
var variantFilled = definePartsStyle$k(props => {
  const {
    theme
  } = props;
  const {
    focusBorderColor: fc,
    errorBorderColor: ec
  } = getDefaults(props);
  return {
    field: {
      border: "2px solid",
      borderColor: "transparent",
      bg: mode("gray.100", "whiteAlpha.50")(props),
      _hover: {
        bg: mode("gray.200", "whiteAlpha.100")(props)
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all"
      },
      _invalid: {
        borderColor: getColorVar(theme, ec)
      },
      _focusVisible: {
        bg: "transparent",
        borderColor: getColorVar(theme, fc)
      }
    },
    addon: {
      border: "2px solid",
      borderColor: "transparent",
      bg: mode("gray.100", "whiteAlpha.50")(props)
    }
  };
});
var variantFlushed = definePartsStyle$k(props => {
  const {
    theme
  } = props;
  const {
    focusBorderColor: fc,
    errorBorderColor: ec
  } = getDefaults(props);
  return {
    field: {
      borderBottom: "1px solid",
      borderColor: "inherit",
      borderRadius: "0",
      px: "0",
      bg: "transparent",
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all"
      },
      _invalid: {
        borderColor: getColorVar(theme, ec),
        boxShadow: `0px 1px 0px 0px ${getColorVar(theme, ec)}`
      },
      _focusVisible: {
        borderColor: getColorVar(theme, fc),
        boxShadow: `0px 1px 0px 0px ${getColorVar(theme, fc)}`
      }
    },
    addon: {
      borderBottom: "2px solid",
      borderColor: "inherit",
      borderRadius: "0",
      px: "0",
      bg: "transparent"
    }
  };
});
var variantUnstyled$1 = definePartsStyle$k({
  field: {
    bg: "transparent",
    px: "0",
    height: "auto"
  },
  addon: {
    bg: "transparent",
    px: "0",
    height: "auto"
  }
});
var variants$7 = {
  outline: variantOutline$1,
  filled: variantFilled,
  flushed: variantFlushed,
  unstyled: variantUnstyled$1
};
var inputTheme = defineMultiStyleConfig$k({
  baseStyle: baseStyle$z,
  sizes: sizes$h,
  variants: variants$7,
  defaultProps: {
    size: "md",
    variant: "outline"
  }
});
var _a$2;
var baseStyle$y = defineStyle(Object.assign({}, (_a$2 = inputTheme.baseStyle) == null ? void 0 : _a$2.field, {
  paddingY: "2",
  minHeight: "20",
  lineHeight: "short",
  verticalAlign: "top"
}));
var _a2$2, _b$2;
var variants$6 = {
  outline: defineStyle(props => {
    var _a4, _b3;
    return (_b3 = (_a4 = inputTheme.variants) == null ? void 0 : _a4.outline(props).field) != null ? _b3 : {};
  }),
  flushed: defineStyle(props => {
    var _a4, _b3;
    return (_b3 = (_a4 = inputTheme.variants) == null ? void 0 : _a4.flushed(props).field) != null ? _b3 : {};
  }),
  filled: defineStyle(props => {
    var _a4, _b3;
    return (_b3 = (_a4 = inputTheme.variants) == null ? void 0 : _a4.filled(props).field) != null ? _b3 : {};
  }),
  unstyled: (_b$2 = (_a2$2 = inputTheme.variants) == null ? void 0 : _a2$2.unstyled.field) != null ? _b$2 : {}
};
var _a3, _b2, _c$1, _d$1, _e$1, _f$1, _g$1, _h$1;
var sizes$g = {
  xs: (_b2 = (_a3 = inputTheme.sizes) == null ? void 0 : _a3.xs.field) != null ? _b2 : {},
  sm: (_d$1 = (_c$1 = inputTheme.sizes) == null ? void 0 : _c$1.sm.field) != null ? _d$1 : {},
  md: (_f$1 = (_e$1 = inputTheme.sizes) == null ? void 0 : _e$1.md.field) != null ? _f$1 : {},
  lg: (_h$1 = (_g$1 = inputTheme.sizes) == null ? void 0 : _g$1.lg.field) != null ? _h$1 : {}
};
var textareaTheme = defineStyleConfig({
  baseStyle: baseStyle$y,
  sizes: sizes$g,
  variants: variants$6,
  defaultProps: {
    size: "md",
    variant: "outline"
  }
});

// src/components/tooltip.ts
var $bg$c = cssVar$1("tooltip-bg");
var $fg$4 = cssVar$1("tooltip-fg");
var $arrowBg$1 = cssVar$1("popper-arrow-bg");
var baseStyle$x = defineStyle({
  bg: $bg$c.reference,
  color: $fg$4.reference,
  [$bg$c.variable]: "colors.gray.700",
  [$fg$4.variable]: "colors.whiteAlpha.900",
  _dark: {
    [$bg$c.variable]: "colors.gray.300",
    [$fg$4.variable]: "colors.gray.900"
  },
  [$arrowBg$1.variable]: $bg$c.reference,
  px: "2",
  py: "0.5",
  borderRadius: "sm",
  fontWeight: "medium",
  fontSize: "sm",
  boxShadow: "md",
  maxW: "xs",
  zIndex: "tooltip"
});
var tooltipTheme = defineStyleConfig({
  baseStyle: baseStyle$x
});

// src/components/progress.ts
var {
  defineMultiStyleConfig: defineMultiStyleConfig$j,
  definePartsStyle: definePartsStyle$j
} = createMultiStyleConfigHelpers(progressAnatomy.keys);
var filledStyle = defineStyle(props => {
  const {
    colorScheme: c,
    theme: t,
    isIndeterminate,
    hasStripe
  } = props;
  const stripeStyle = mode(generateStripe(), generateStripe("1rem", "rgba(0,0,0,0.1)"))(props);
  const bgColor = mode(`${c}.500`, `${c}.200`)(props);
  const gradient = `linear-gradient(
    to right,
    transparent 0%,
    ${getColorVar(t, bgColor)} 50%,
    transparent 100%
  )`;
  const addStripe = !isIndeterminate && hasStripe;
  return Object.assign({}, addStripe && stripeStyle, isIndeterminate ? {
    bgImage: gradient
  } : {
    bgColor
  });
});
var baseStyleLabel$3 = defineStyle({
  lineHeight: "1",
  fontSize: "0.25em",
  fontWeight: "bold",
  color: "white"
});
var baseStyleTrack$1 = defineStyle(props => {
  return {
    bg: mode("gray.100", "whiteAlpha.300")(props)
  };
});
var baseStyleFilledTrack$1 = defineStyle(props => {
  return Object.assign({
    transitionProperty: "common",
    transitionDuration: "slow"
  }, filledStyle(props));
});
var baseStyle$w = definePartsStyle$j(props => ({
  label: baseStyleLabel$3,
  filledTrack: baseStyleFilledTrack$1(props),
  track: baseStyleTrack$1(props)
}));
var sizes$f = {
  xs: definePartsStyle$j({
    track: {
      h: "1"
    }
  }),
  sm: definePartsStyle$j({
    track: {
      h: "2"
    }
  }),
  md: definePartsStyle$j({
    track: {
      h: "3"
    }
  }),
  lg: definePartsStyle$j({
    track: {
      h: "4"
    }
  })
};
var progressTheme = defineMultiStyleConfig$j({
  sizes: sizes$f,
  baseStyle: baseStyle$w,
  defaultProps: {
    size: "md",
    colorScheme: "blue"
  }
});

// src/utils/run-if-fn.ts
var isFunction = value => typeof value === "function";
function runIfFn(valueOrFn, ...args) {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}
var {
  definePartsStyle: definePartsStyle$i,
  defineMultiStyleConfig: defineMultiStyleConfig$i
} = createMultiStyleConfigHelpers(checkboxAnatomy.keys);
var $size$3 = cssVar("checkbox-size");
var baseStyleControl$1 = defineStyle(props => {
  const {
    colorScheme: c
  } = props;
  return {
    w: $size$3.reference,
    h: $size$3.reference,
    transitionProperty: "box-shadow",
    transitionDuration: "normal",
    border: "2px solid",
    borderRadius: "sm",
    borderColor: "inherit",
    color: "white",
    _checked: {
      bg: mode(`${c}.500`, `${c}.200`)(props),
      borderColor: mode(`${c}.500`, `${c}.200`)(props),
      color: mode("white", "gray.900")(props),
      _hover: {
        bg: mode(`${c}.600`, `${c}.300`)(props),
        borderColor: mode(`${c}.600`, `${c}.300`)(props)
      },
      _disabled: {
        borderColor: mode("gray.200", "transparent")(props),
        bg: mode("gray.200", "whiteAlpha.300")(props),
        color: mode("gray.500", "whiteAlpha.500")(props)
      }
    },
    _indeterminate: {
      bg: mode(`${c}.500`, `${c}.200`)(props),
      borderColor: mode(`${c}.500`, `${c}.200`)(props),
      color: mode("white", "gray.900")(props)
    },
    _disabled: {
      bg: mode("gray.100", "whiteAlpha.100")(props),
      borderColor: mode("gray.100", "transparent")(props)
    },
    _focusVisible: {
      boxShadow: "outline"
    },
    _invalid: {
      borderColor: mode("red.500", "red.300")(props)
    }
  };
});
var baseStyleContainer$3 = defineStyle({
  _disabled: {
    cursor: "not-allowed"
  }
});
var baseStyleLabel$2 = defineStyle({
  userSelect: "none",
  _disabled: {
    opacity: 0.4
  }
});
var baseStyleIcon$5 = defineStyle({
  transitionProperty: "transform",
  transitionDuration: "normal"
});
var baseStyle$v = definePartsStyle$i(props => ({
  icon: baseStyleIcon$5,
  container: baseStyleContainer$3,
  control: runIfFn(baseStyleControl$1, props),
  label: baseStyleLabel$2
}));
var sizes$e = {
  sm: definePartsStyle$i({
    control: {
      [$size$3.variable]: "sizes.3"
    },
    label: {
      fontSize: "sm"
    },
    icon: {
      fontSize: "3xs"
    }
  }),
  md: definePartsStyle$i({
    control: {
      [$size$3.variable]: "sizes.4"
    },
    label: {
      fontSize: "md"
    },
    icon: {
      fontSize: "2xs"
    }
  }),
  lg: definePartsStyle$i({
    control: {
      [$size$3.variable]: "sizes.5"
    },
    label: {
      fontSize: "lg"
    },
    icon: {
      fontSize: "2xs"
    }
  })
};
var checkboxTheme = defineMultiStyleConfig$i({
  baseStyle: baseStyle$v,
  sizes: sizes$e,
  defaultProps: {
    size: "md",
    colorScheme: "blue"
  }
});
var {
  defineMultiStyleConfig: defineMultiStyleConfig$h,
  definePartsStyle: definePartsStyle$h
} = createMultiStyleConfigHelpers(radioAnatomy.keys);
var baseStyleControl = defineStyle(props => {
  var _a;
  const controlStyle = (_a = runIfFn(checkboxTheme.baseStyle, props)) == null ? void 0 : _a.control;
  return Object.assign({}, controlStyle, {
    borderRadius: "full",
    _checked: Object.assign({}, controlStyle == null ? void 0 : controlStyle["_checked"], {
      _before: {
        content: `""`,
        display: "inline-block",
        pos: "relative",
        w: "50%",
        h: "50%",
        borderRadius: "50%",
        bg: "currentColor"
      }
    })
  });
});
var baseStyle$u = definePartsStyle$h(props => {
  var _a, _b, _c, _d;
  return {
    label: (_b = (_a = checkboxTheme).baseStyle) == null ? void 0 : _b.call(_a, props).label,
    container: (_d = (_c = checkboxTheme).baseStyle) == null ? void 0 : _d.call(_c, props).container,
    control: baseStyleControl(props)
  };
});
var sizes$d = {
  md: definePartsStyle$h({
    control: {
      w: "4",
      h: "4"
    },
    label: {
      fontSize: "md"
    }
  }),
  lg: definePartsStyle$h({
    control: {
      w: "5",
      h: "5"
    },
    label: {
      fontSize: "lg"
    }
  }),
  sm: definePartsStyle$h({
    control: {
      width: "3",
      height: "3"
    },
    label: {
      fontSize: "sm"
    }
  })
};
var radioTheme = defineMultiStyleConfig$h({
  baseStyle: baseStyle$u,
  sizes: sizes$d,
  defaultProps: {
    size: "md",
    colorScheme: "blue"
  }
});
var {
  defineMultiStyleConfig: defineMultiStyleConfig$g,
  definePartsStyle: definePartsStyle$g
} = createMultiStyleConfigHelpers(selectAnatomy.keys);
var $bg$b = cssVar("select-bg");
var _a$1;
var baseStyleField$1 = defineStyle(Object.assign({}, (_a$1 = inputTheme.baseStyle) == null ? void 0 : _a$1.field, {
  appearance: "none",
  paddingBottom: "1px",
  lineHeight: "normal",
  bg: $bg$b.reference,
  [$bg$b.variable]: "colors.white",
  _dark: {
    [$bg$b.variable]: "colors.gray.700"
  },
  "> option, > optgroup": {
    bg: $bg$b.reference
  }
}));
var baseStyleIcon$4 = defineStyle({
  width: "6",
  height: "100%",
  insetEnd: "2",
  position: "relative",
  color: "currentColor",
  fontSize: "xl",
  _disabled: {
    opacity: 0.5
  }
});
var baseStyle$t = definePartsStyle$g({
  field: baseStyleField$1,
  icon: baseStyleIcon$4
});
var iconSpacing = defineStyle({
  paddingInlineEnd: "8"
});
var _a2$1, _b$1, _c, _d, _e, _f, _g, _h;
var sizes$c = {
  lg: Object.assign({}, (_a2$1 = inputTheme.sizes) == null ? void 0 : _a2$1.lg, {
    field: Object.assign({}, (_b$1 = inputTheme.sizes) == null ? void 0 : _b$1.lg.field, iconSpacing)
  }),
  md: Object.assign({}, (_c = inputTheme.sizes) == null ? void 0 : _c.md, {
    field: Object.assign({}, (_d = inputTheme.sizes) == null ? void 0 : _d.md.field, iconSpacing)
  }),
  sm: Object.assign({}, (_e = inputTheme.sizes) == null ? void 0 : _e.sm, {
    field: Object.assign({}, (_f = inputTheme.sizes) == null ? void 0 : _f.sm.field, iconSpacing)
  }),
  xs: Object.assign({}, (_g = inputTheme.sizes) == null ? void 0 : _g.xs, {
    field: Object.assign({}, (_h = inputTheme.sizes) == null ? void 0 : _h.xs.field, iconSpacing),
    icon: {
      insetEnd: "1"
    }
  })
};
var selectTheme = defineMultiStyleConfig$g({
  baseStyle: baseStyle$t,
  sizes: sizes$c,
  variants: inputTheme.variants,
  defaultProps: inputTheme.defaultProps
});

// src/components/skeleton.ts
var $startColor = cssVar("skeleton-start-color");
var $endColor = cssVar("skeleton-end-color");
var baseStyle$s = defineStyle({
  [$startColor.variable]: "colors.gray.100",
  [$endColor.variable]: "colors.gray.400",
  _dark: {
    [$startColor.variable]: "colors.gray.800",
    [$endColor.variable]: "colors.gray.600"
  },
  background: $startColor.reference,
  borderColor: $endColor.reference,
  opacity: 0.7,
  borderRadius: "sm"
});
var skeletonTheme = defineStyleConfig({
  baseStyle: baseStyle$s
});

// src/components/skip-link.ts
var $bg$a = cssVar("skip-link-bg");
var baseStyle$r = defineStyle({
  borderRadius: "md",
  fontWeight: "semibold",
  _focusVisible: {
    boxShadow: "outline",
    padding: "4",
    position: "fixed",
    top: "6",
    insetStart: "6",
    [$bg$a.variable]: "colors.white",
    _dark: {
      [$bg$a.variable]: "colors.gray.700"
    },
    bg: $bg$a.reference
  }
});
var skipLinkTheme = defineStyleConfig({
  baseStyle: baseStyle$r
});

// src/components/slider.ts
var {
  defineMultiStyleConfig: defineMultiStyleConfig$f,
  definePartsStyle: definePartsStyle$f
} = createMultiStyleConfigHelpers(sliderAnatomy.keys);
var $thumbSize = cssVar("slider-thumb-size");
var $trackSize = cssVar("slider-track-size");
var $bg$9 = cssVar("slider-bg");
var baseStyleContainer$2 = defineStyle(props => {
  const {
    orientation
  } = props;
  return Object.assign({
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
    _disabled: {
      opacity: 0.6,
      cursor: "default",
      pointerEvents: "none"
    }
  }, orient({
    orientation,
    vertical: {
      h: "100%"
    },
    horizontal: {
      w: "100%"
    }
  }));
});
var baseStyleTrack = defineStyle(props => {
  const orientationStyles = orient({
    orientation: props.orientation,
    horizontal: {
      h: $trackSize.reference
    },
    vertical: {
      w: $trackSize.reference
    }
  });
  return Object.assign({}, orientationStyles, {
    overflow: "hidden",
    borderRadius: "sm",
    [$bg$9.variable]: "colors.gray.200",
    _dark: {
      [$bg$9.variable]: "colors.whiteAlpha.200"
    },
    _disabled: {
      [$bg$9.variable]: "colors.gray.300",
      _dark: {
        [$bg$9.variable]: "colors.whiteAlpha.300"
      }
    },
    bg: $bg$9.reference
  });
});
var baseStyleThumb = defineStyle(props => {
  const {
    orientation
  } = props;
  const orientationStyle = orient({
    orientation,
    vertical: {
      left: "50%",
      transform: `translateX(-50%)`,
      _active: {
        transform: `translateX(-50%) scale(1.15)`
      }
    },
    horizontal: {
      top: "50%",
      transform: `translateY(-50%)`,
      _active: {
        transform: `translateY(-50%) scale(1.15)`
      }
    }
  });
  return Object.assign({}, orientationStyle, {
    w: $thumbSize.reference,
    h: $thumbSize.reference,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    outline: 0,
    zIndex: 1,
    borderRadius: "full",
    bg: "white",
    boxShadow: "base",
    border: "1px solid",
    borderColor: "transparent",
    transitionProperty: "transform",
    transitionDuration: "normal",
    _focusVisible: {
      boxShadow: "outline"
    },
    _disabled: {
      bg: "gray.300"
    }
  });
});
var baseStyleFilledTrack = defineStyle(props => {
  const {
    colorScheme: c
  } = props;
  return {
    width: "inherit",
    height: "inherit",
    [$bg$9.variable]: `colors.${c}.500`,
    _dark: {
      [$bg$9.variable]: `colors.${c}.200`
    },
    bg: $bg$9.reference
  };
});
var baseStyle$q = definePartsStyle$f(props => ({
  container: baseStyleContainer$2(props),
  track: baseStyleTrack(props),
  thumb: baseStyleThumb(props),
  filledTrack: baseStyleFilledTrack(props)
}));
var sizeLg = definePartsStyle$f({
  container: {
    [$thumbSize.variable]: `sizes.4`,
    [$trackSize.variable]: `sizes.1`
  }
});
var sizeMd = definePartsStyle$f({
  container: {
    [$thumbSize.variable]: `sizes.3.5`,
    [$trackSize.variable]: `sizes.1`
  }
});
var sizeSm = definePartsStyle$f({
  container: {
    [$thumbSize.variable]: `sizes.2.5`,
    [$trackSize.variable]: `sizes.0.5`
  }
});
var sizes$b = {
  lg: sizeLg,
  md: sizeMd,
  sm: sizeSm
};
var sliderTheme = defineMultiStyleConfig$f({
  baseStyle: baseStyle$q,
  sizes: sizes$b,
  defaultProps: {
    size: "md",
    colorScheme: "blue"
  }
});

// src/components/spinner.ts
var $size$2 = cssVar$1("spinner-size");
var baseStyle$p = defineStyle({
  width: [$size$2.reference],
  height: [$size$2.reference]
});
var sizes$a = {
  xs: defineStyle({
    [$size$2.variable]: "sizes.3"
  }),
  sm: defineStyle({
    [$size$2.variable]: "sizes.4"
  }),
  md: defineStyle({
    [$size$2.variable]: "sizes.6"
  }),
  lg: defineStyle({
    [$size$2.variable]: "sizes.8"
  }),
  xl: defineStyle({
    [$size$2.variable]: "sizes.12"
  })
};
var spinnerTheme = defineStyleConfig({
  baseStyle: baseStyle$p,
  sizes: sizes$a,
  defaultProps: {
    size: "md"
  }
});

// src/components/stat.ts
var {
  defineMultiStyleConfig: defineMultiStyleConfig$e,
  definePartsStyle: definePartsStyle$e
} = createMultiStyleConfigHelpers(statAnatomy.keys);
var baseStyleLabel$1 = defineStyle({
  fontWeight: "medium"
});
var baseStyleHelpText = defineStyle({
  opacity: 0.8,
  marginBottom: "2"
});
var baseStyleNumber = defineStyle({
  verticalAlign: "baseline",
  fontWeight: "semibold"
});
var baseStyleIcon$3 = defineStyle({
  marginEnd: 1,
  w: "3.5",
  h: "3.5",
  verticalAlign: "middle"
});
var baseStyle$o = definePartsStyle$e({
  container: {},
  label: baseStyleLabel$1,
  helpText: baseStyleHelpText,
  number: baseStyleNumber,
  icon: baseStyleIcon$3
});
var sizes$9 = {
  md: definePartsStyle$e({
    label: {
      fontSize: "sm"
    },
    helpText: {
      fontSize: "sm"
    },
    number: {
      fontSize: "2xl"
    }
  })
};
var statTheme = defineMultiStyleConfig$e({
  baseStyle: baseStyle$o,
  sizes: sizes$9,
  defaultProps: {
    size: "md"
  }
});

// src/components/kbd.ts
var $bg$8 = cssVar("kbd-bg");
var baseStyle$n = defineStyle({
  [$bg$8.variable]: "colors.gray.100",
  _dark: {
    [$bg$8.variable]: "colors.whiteAlpha.100"
  },
  bg: $bg$8.reference,
  borderRadius: "md",
  borderWidth: "1px",
  borderBottomWidth: "3px",
  fontSize: "0.8em",
  fontWeight: "bold",
  lineHeight: "normal",
  px: "0.4em",
  whiteSpace: "nowrap"
});
var kbdTheme = defineStyleConfig({
  baseStyle: baseStyle$n
});

// src/components/link.ts
var baseStyle$m = defineStyle({
  transitionProperty: "common",
  transitionDuration: "fast",
  transitionTimingFunction: "ease-out",
  cursor: "pointer",
  textDecoration: "none",
  outline: "none",
  color: "inherit",
  _hover: {
    textDecoration: "underline"
  },
  _focusVisible: {
    boxShadow: "outline"
  }
});
var linkTheme = defineStyleConfig({
  baseStyle: baseStyle$m
});

// src/components/list.ts
var {
  defineMultiStyleConfig: defineMultiStyleConfig$d,
  definePartsStyle: definePartsStyle$d
} = createMultiStyleConfigHelpers(listAnatomy.keys);
var baseStyleIcon$2 = defineStyle({
  marginEnd: "2",
  display: "inline",
  verticalAlign: "text-bottom"
});
var baseStyle$l = definePartsStyle$d({
  icon: baseStyleIcon$2
});
var listTheme = defineMultiStyleConfig$d({
  baseStyle: baseStyle$l
});

// src/components/menu.ts
var {
  defineMultiStyleConfig: defineMultiStyleConfig$c,
  definePartsStyle: definePartsStyle$c
} = createMultiStyleConfigHelpers(menuAnatomy.keys);
var $bg$7 = cssVar("menu-bg");
var $shadow$2 = cssVar("menu-shadow");
var baseStyleList = defineStyle({
  [$bg$7.variable]: "#fff",
  [$shadow$2.variable]: "shadows.sm",
  _dark: {
    [$bg$7.variable]: "colors.gray.700",
    [$shadow$2.variable]: "shadows.dark-lg"
  },
  color: "inherit",
  minW: "3xs",
  py: "2",
  zIndex: 1,
  borderRadius: "md",
  borderWidth: "1px",
  bg: $bg$7.reference,
  boxShadow: $shadow$2.reference
});
var baseStyleItem = defineStyle({
  py: "1.5",
  px: "3",
  transitionProperty: "background",
  transitionDuration: "ultra-fast",
  transitionTimingFunction: "ease-in",
  _focus: {
    [$bg$7.variable]: "colors.gray.100",
    _dark: {
      [$bg$7.variable]: "colors.whiteAlpha.100"
    }
  },
  _active: {
    [$bg$7.variable]: "colors.gray.200",
    _dark: {
      [$bg$7.variable]: "colors.whiteAlpha.200"
    }
  },
  _expanded: {
    [$bg$7.variable]: "colors.gray.100",
    _dark: {
      [$bg$7.variable]: "colors.whiteAlpha.100"
    }
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed"
  },
  bg: $bg$7.reference
});
var baseStyleGroupTitle = defineStyle({
  mx: 4,
  my: 2,
  fontWeight: "semibold",
  fontSize: "sm"
});
var baseStyleCommand = defineStyle({
  opacity: 0.6
});
var baseStyleDivider = defineStyle({
  border: 0,
  borderBottom: "1px solid",
  borderColor: "inherit",
  my: "2",
  opacity: 0.6
});
var baseStyleButton$1 = defineStyle({
  transitionProperty: "common",
  transitionDuration: "normal"
});
var baseStyle$k = definePartsStyle$c({
  button: baseStyleButton$1,
  list: baseStyleList,
  item: baseStyleItem,
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider
});
var menuTheme = defineMultiStyleConfig$c({
  baseStyle: baseStyle$k
});
var {
  defineMultiStyleConfig: defineMultiStyleConfig$b,
  definePartsStyle: definePartsStyle$b
} = createMultiStyleConfigHelpers(modalAnatomy.keys);
var $bg$6 = cssVar("modal-bg");
var $shadow$1 = cssVar("modal-shadow");
var baseStyleOverlay$1 = defineStyle({
  bg: "blackAlpha.600",
  zIndex: "modal"
});
var baseStyleDialogContainer$1 = defineStyle(props => {
  const {
    isCentered,
    scrollBehavior
  } = props;
  return {
    display: "flex",
    zIndex: "modal",
    justifyContent: "center",
    alignItems: isCentered ? "center" : "flex-start",
    overflow: scrollBehavior === "inside" ? "hidden" : "auto",
    overscrollBehaviorY: "none"
  };
});
var baseStyleDialog$1 = defineStyle(props => {
  const {
    isCentered,
    scrollBehavior
  } = props;
  return {
    borderRadius: "md",
    color: "inherit",
    my: isCentered ? "auto" : "16",
    mx: isCentered ? "auto" : void 0,
    zIndex: "modal",
    maxH: scrollBehavior === "inside" ? "calc(100% - 7.5rem)" : void 0,
    [$bg$6.variable]: "colors.white",
    [$shadow$1.variable]: "shadows.lg",
    _dark: {
      [$bg$6.variable]: "colors.gray.700",
      [$shadow$1.variable]: "shadows.dark-lg"
    },
    bg: $bg$6.reference,
    boxShadow: $shadow$1.reference
  };
});
var baseStyleHeader$2 = defineStyle({
  px: "6",
  py: "4",
  fontSize: "xl",
  fontWeight: "semibold"
});
var baseStyleCloseButton$2 = defineStyle({
  position: "absolute",
  top: "2",
  insetEnd: "3"
});
var baseStyleBody$2 = defineStyle(props => {
  const {
    scrollBehavior
  } = props;
  return {
    px: "6",
    py: "2",
    flex: "1",
    overflow: scrollBehavior === "inside" ? "auto" : void 0
  };
});
var baseStyleFooter$2 = defineStyle({
  px: "6",
  py: "4"
});
var baseStyle$j = definePartsStyle$b(props => ({
  overlay: baseStyleOverlay$1,
  dialogContainer: runIfFn(baseStyleDialogContainer$1, props),
  dialog: runIfFn(baseStyleDialog$1, props),
  header: baseStyleHeader$2,
  closeButton: baseStyleCloseButton$2,
  body: runIfFn(baseStyleBody$2, props),
  footer: baseStyleFooter$2
}));
function getSize$3(value) {
  if (value === "full") {
    return definePartsStyle$b({
      dialog: {
        maxW: "100vw",
        minH: "$100vh",
        my: "0",
        borderRadius: "0"
      }
    });
  }
  return definePartsStyle$b({
    dialog: {
      maxW: value
    }
  });
}
var sizes$8 = {
  xs: getSize$3("xs"),
  sm: getSize$3("sm"),
  md: getSize$3("md"),
  lg: getSize$3("lg"),
  xl: getSize$3("xl"),
  "2xl": getSize$3("2xl"),
  "3xl": getSize$3("3xl"),
  "4xl": getSize$3("4xl"),
  "5xl": getSize$3("5xl"),
  "6xl": getSize$3("6xl"),
  full: getSize$3("full")
};
var modalTheme = defineMultiStyleConfig$b({
  baseStyle: baseStyle$j,
  sizes: sizes$8,
  defaultProps: {
    size: "md"
  }
});
var {
  defineMultiStyleConfig: defineMultiStyleConfig$a,
  definePartsStyle: definePartsStyle$a
} = createMultiStyleConfigHelpers(numberInputAnatomy.keys);
var $stepperWidth = cssVar$1("number-input-stepper-width");
var $inputPadding = cssVar$1("number-input-input-padding");
var inputPaddingValue = calc($stepperWidth).add("0.5rem").toString();
var $bg$5 = cssVar$1("number-input-bg");
var $fg$3 = cssVar$1("number-input-color");
var $border$2 = cssVar$1("number-input-border-color");
var baseStyleRoot = defineStyle({
  [$stepperWidth.variable]: "sizes.6",
  [$inputPadding.variable]: inputPaddingValue
});
var baseStyleField = defineStyle(props => {
  var _a, _b;
  return (_b = (_a = runIfFn(inputTheme.baseStyle, props)) == null ? void 0 : _a.field) != null ? _b : {};
});
var baseStyleStepperGroup = defineStyle({
  width: $stepperWidth.reference
});
var baseStyleStepper = defineStyle({
  borderStart: "1px solid",
  borderStartColor: $border$2.reference,
  color: $fg$3.reference,
  bg: $bg$5.reference,
  [$fg$3.variable]: "colors.chakra-body-text",
  [$border$2.variable]: "colors.chakra-border-color",
  _dark: {
    [$fg$3.variable]: "colors.whiteAlpha.800",
    [$border$2.variable]: "colors.whiteAlpha.300"
  },
  _active: {
    [$bg$5.variable]: "colors.gray.200",
    _dark: {
      [$bg$5.variable]: "colors.whiteAlpha.300"
    }
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed"
  }
});
var baseStyle$i = definePartsStyle$a(props => {
  var _a;
  return {
    root: baseStyleRoot,
    field: (_a = runIfFn(baseStyleField, props)) != null ? _a : {},
    stepperGroup: baseStyleStepperGroup,
    stepper: baseStyleStepper
  };
});
function getSize$2(size) {
  var _a, _b, _c;
  const sizeStyle = (_a = inputTheme.sizes) == null ? void 0 : _a[size];
  const radius = {
    lg: "md",
    md: "md",
    sm: "sm",
    xs: "sm"
  };
  const _fontSize = (_c = (_b = sizeStyle.field) == null ? void 0 : _b.fontSize) != null ? _c : "md";
  const fontSize = typography_default.fontSizes[_fontSize];
  return definePartsStyle$a({
    field: Object.assign({}, sizeStyle.field, {
      paddingInlineEnd: $inputPadding.reference,
      verticalAlign: "top"
    }),
    stepper: {
      fontSize: calc(fontSize).multiply(0.75).toString(),
      _first: {
        borderTopEndRadius: radius[size]
      },
      _last: {
        borderBottomEndRadius: radius[size],
        mt: "-1px",
        borderTopWidth: 1
      }
    }
  });
}
var sizes$7 = {
  xs: getSize$2("xs"),
  sm: getSize$2("sm"),
  md: getSize$2("md"),
  lg: getSize$2("lg")
};
var numberInputTheme = defineMultiStyleConfig$a({
  baseStyle: baseStyle$i,
  sizes: sizes$7,
  variants: inputTheme.variants,
  defaultProps: inputTheme.defaultProps
});
var _a;
var baseStyle$h = defineStyle(Object.assign({}, (_a = inputTheme.baseStyle) == null ? void 0 : _a.field, {
  textAlign: "center"
}));
var sizes$6 = {
  lg: defineStyle({
    fontSize: "lg",
    w: 12,
    h: 12,
    borderRadius: "md"
  }),
  md: defineStyle({
    fontSize: "md",
    w: 10,
    h: 10,
    borderRadius: "md"
  }),
  sm: defineStyle({
    fontSize: "sm",
    w: 8,
    h: 8,
    borderRadius: "sm"
  }),
  xs: defineStyle({
    fontSize: "xs",
    w: 6,
    h: 6,
    borderRadius: "sm"
  })
};
var _a2, _b;
var variants$5 = {
  outline: defineStyle(props => {
    var _a3, _b2, _c;
    return (_c = (_b2 = runIfFn((_a3 = inputTheme.variants) == null ? void 0 : _a3.outline, props)) == null ? void 0 : _b2.field) != null ? _c : {};
  }),
  flushed: defineStyle(props => {
    var _a3, _b2, _c;
    return (_c = (_b2 = runIfFn((_a3 = inputTheme.variants) == null ? void 0 : _a3.flushed, props)) == null ? void 0 : _b2.field) != null ? _c : {};
  }),
  filled: defineStyle(props => {
    var _a3, _b2, _c;
    return (_c = (_b2 = runIfFn((_a3 = inputTheme.variants) == null ? void 0 : _a3.filled, props)) == null ? void 0 : _b2.field) != null ? _c : {};
  }),
  unstyled: (_b = (_a2 = inputTheme.variants) == null ? void 0 : _a2.unstyled.field) != null ? _b : {}
};
var pinInputTheme = defineStyleConfig({
  baseStyle: baseStyle$h,
  sizes: sizes$6,
  variants: variants$5,
  defaultProps: inputTheme.defaultProps
});

// src/components/popover.ts
var {
  defineMultiStyleConfig: defineMultiStyleConfig$9,
  definePartsStyle: definePartsStyle$9
} = createMultiStyleConfigHelpers(popoverAnatomy.keys);
var $popperBg = cssVar$1("popper-bg");
var $arrowBg = cssVar$1("popper-arrow-bg");
var $arrowShadowColor = cssVar$1("popper-arrow-shadow-color");
var baseStylePopper = defineStyle({
  zIndex: 10
});
var baseStyleContent = defineStyle({
  [$popperBg.variable]: `colors.white`,
  bg: $popperBg.reference,
  [$arrowBg.variable]: $popperBg.reference,
  [$arrowShadowColor.variable]: `colors.gray.200`,
  _dark: {
    [$popperBg.variable]: `colors.gray.700`,
    [$arrowShadowColor.variable]: `colors.whiteAlpha.300`
  },
  width: "xs",
  border: "1px solid",
  borderColor: "inherit",
  borderRadius: "md",
  boxShadow: "sm",
  zIndex: "inherit",
  _focusVisible: {
    outline: 0,
    boxShadow: "outline"
  }
});
var baseStyleHeader$1 = defineStyle({
  px: 3,
  py: 2,
  borderBottomWidth: "1px"
});
var baseStyleBody$1 = defineStyle({
  px: 3,
  py: 2
});
var baseStyleFooter$1 = defineStyle({
  px: 3,
  py: 2,
  borderTopWidth: "1px"
});
var baseStyleCloseButton$1 = defineStyle({
  position: "absolute",
  borderRadius: "md",
  top: 1,
  insetEnd: 2,
  padding: 2
});
var baseStyle$g = definePartsStyle$9({
  popper: baseStylePopper,
  content: baseStyleContent,
  header: baseStyleHeader$1,
  body: baseStyleBody$1,
  footer: baseStyleFooter$1,
  closeButton: baseStyleCloseButton$1
});
var popoverTheme = defineMultiStyleConfig$9({
  baseStyle: baseStyle$g
});
var {
  definePartsStyle: definePartsStyle$8,
  defineMultiStyleConfig: defineMultiStyleConfig$8
} = createMultiStyleConfigHelpers(drawerAnatomy.keys);
var $bg$4 = cssVar("drawer-bg");
var $bs = cssVar("drawer-box-shadow");
function getSize$1(value) {
  if (value === "full") {
    return definePartsStyle$8({
      dialog: {
        maxW: "100vw",
        h: "100vh"
      }
    });
  }
  return definePartsStyle$8({
    dialog: {
      maxW: value
    }
  });
}
var baseStyleOverlay = defineStyle({
  bg: "blackAlpha.600",
  zIndex: "overlay"
});
var baseStyleDialogContainer = defineStyle({
  display: "flex",
  zIndex: "modal",
  justifyContent: "center"
});
var baseStyleDialog = defineStyle(props => {
  const {
    isFullHeight
  } = props;
  return Object.assign({}, isFullHeight && {
    height: "100vh"
  }, {
    zIndex: "modal",
    maxH: "100vh",
    color: "inherit",
    [$bg$4.variable]: "colors.white",
    [$bs.variable]: "shadows.lg",
    _dark: {
      [$bg$4.variable]: "colors.gray.700",
      [$bs.variable]: "shadows.dark-lg"
    },
    bg: $bg$4.reference,
    boxShadow: $bs.reference
  });
});
var baseStyleHeader = defineStyle({
  px: "6",
  py: "4",
  fontSize: "xl",
  fontWeight: "semibold"
});
var baseStyleCloseButton = defineStyle({
  position: "absolute",
  top: "2",
  insetEnd: "3"
});
var baseStyleBody = defineStyle({
  px: "6",
  py: "2",
  flex: "1",
  overflow: "auto"
});
var baseStyleFooter = defineStyle({
  px: "6",
  py: "4"
});
var baseStyle$f = definePartsStyle$8(props => ({
  overlay: baseStyleOverlay,
  dialogContainer: baseStyleDialogContainer,
  dialog: runIfFn(baseStyleDialog, props),
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  body: baseStyleBody,
  footer: baseStyleFooter
}));
var sizes$5 = {
  xs: getSize$1("xs"),
  sm: getSize$1("md"),
  md: getSize$1("lg"),
  lg: getSize$1("2xl"),
  xl: getSize$1("4xl"),
  full: getSize$1("full")
};
var drawerTheme = defineMultiStyleConfig$8({
  baseStyle: baseStyle$f,
  sizes: sizes$5,
  defaultProps: {
    size: "xs"
  }
});

// src/components/editable.ts
var {
  definePartsStyle: definePartsStyle$7,
  defineMultiStyleConfig: defineMultiStyleConfig$7
} = createMultiStyleConfigHelpers(editableAnatomy.keys);
var baseStylePreview = defineStyle({
  borderRadius: "md",
  py: "1",
  transitionProperty: "common",
  transitionDuration: "normal"
});
var baseStyleInput = defineStyle({
  borderRadius: "md",
  py: "1",
  transitionProperty: "common",
  transitionDuration: "normal",
  width: "full",
  _focusVisible: {
    boxShadow: "outline"
  },
  _placeholder: {
    opacity: 0.6
  }
});
var baseStyleTextarea = defineStyle({
  borderRadius: "md",
  py: "1",
  transitionProperty: "common",
  transitionDuration: "normal",
  width: "full",
  _focusVisible: {
    boxShadow: "outline"
  },
  _placeholder: {
    opacity: 0.6
  }
});
var baseStyle$e = definePartsStyle$7({
  preview: baseStylePreview,
  input: baseStyleInput,
  textarea: baseStyleTextarea
});
var editableTheme = defineMultiStyleConfig$7({
  baseStyle: baseStyle$e
});

// src/components/form-control.ts
var {
  definePartsStyle: definePartsStyle$6,
  defineMultiStyleConfig: defineMultiStyleConfig$6
} = createMultiStyleConfigHelpers(formAnatomy.keys);
var $fg$2 = cssVar("form-control-color");
var baseStyleRequiredIndicator = defineStyle({
  marginStart: "1",
  [$fg$2.variable]: "colors.red.500",
  _dark: {
    [$fg$2.variable]: "colors.red.300"
  },
  color: $fg$2.reference
});
var baseStyleHelperText = defineStyle({
  mt: "2",
  [$fg$2.variable]: "colors.gray.600",
  _dark: {
    [$fg$2.variable]: "colors.whiteAlpha.600"
  },
  color: $fg$2.reference,
  lineHeight: "normal",
  fontSize: "sm"
});
var baseStyle$d = definePartsStyle$6({
  container: {
    width: "100%",
    position: "relative"
  },
  requiredIndicator: baseStyleRequiredIndicator,
  helperText: baseStyleHelperText
});
var formTheme = defineMultiStyleConfig$6({
  baseStyle: baseStyle$d
});

// src/components/form-error.ts
var {
  definePartsStyle: definePartsStyle$5,
  defineMultiStyleConfig: defineMultiStyleConfig$5
} = createMultiStyleConfigHelpers(formErrorAnatomy.keys);
var $fg$1 = cssVar("form-error-color");
var baseStyleText = defineStyle({
  [$fg$1.variable]: `colors.red.500`,
  _dark: {
    [$fg$1.variable]: `colors.red.300`
  },
  color: $fg$1.reference,
  mt: "2",
  fontSize: "sm",
  lineHeight: "normal"
});
var baseStyleIcon$1 = defineStyle({
  marginEnd: "0.5em",
  [$fg$1.variable]: `colors.red.500`,
  _dark: {
    [$fg$1.variable]: `colors.red.300`
  },
  color: $fg$1.reference
});
var baseStyle$c = definePartsStyle$5({
  text: baseStyleText,
  icon: baseStyleIcon$1
});
var formErrorTheme = defineMultiStyleConfig$5({
  baseStyle: baseStyle$c
});

// src/components/form-label.ts
var baseStyle$b = defineStyle({
  fontSize: "md",
  marginEnd: "3",
  mb: "2",
  fontWeight: "medium",
  transitionProperty: "common",
  transitionDuration: "normal",
  opacity: 1,
  _disabled: {
    opacity: 0.4
  }
});
var formLabelTheme = defineStyleConfig({
  baseStyle: baseStyle$b
});

// src/components/heading.ts
var baseStyle$a = defineStyle({
  fontFamily: "heading",
  fontWeight: "bold"
});
var sizes$4 = {
  "4xl": defineStyle({
    fontSize: ["6xl", null, "7xl"],
    lineHeight: 1
  }),
  "3xl": defineStyle({
    fontSize: ["5xl", null, "6xl"],
    lineHeight: 1
  }),
  "2xl": defineStyle({
    fontSize: ["4xl", null, "5xl"],
    lineHeight: [1.2, null, 1]
  }),
  xl: defineStyle({
    fontSize: ["3xl", null, "4xl"],
    lineHeight: [1.33, null, 1.2]
  }),
  lg: defineStyle({
    fontSize: ["2xl", null, "3xl"],
    lineHeight: [1.33, null, 1.2]
  }),
  md: defineStyle({
    fontSize: "xl",
    lineHeight: 1.2
  }),
  sm: defineStyle({
    fontSize: "md",
    lineHeight: 1.2
  }),
  xs: defineStyle({
    fontSize: "sm",
    lineHeight: 1.2
  })
};
var headingTheme = defineStyleConfig({
  baseStyle: baseStyle$a,
  sizes: sizes$4,
  defaultProps: {
    size: "xl"
  }
});

// src/components/breadcrumb.ts
var {
  defineMultiStyleConfig: defineMultiStyleConfig$4,
  definePartsStyle: definePartsStyle$4
} = createMultiStyleConfigHelpers(breadcrumbAnatomy.keys);
var $decor = cssVar("breadcrumb-link-decor");
var baseStyleLink = defineStyle({
  transitionProperty: "common",
  transitionDuration: "fast",
  transitionTimingFunction: "ease-out",
  outline: "none",
  color: "inherit",
  textDecoration: $decor.reference,
  [$decor.variable]: "none",
  "&:not([aria-current=page])": {
    cursor: "pointer",
    _hover: {
      [$decor.variable]: "underline"
    },
    _focusVisible: {
      boxShadow: "outline"
    }
  }
});
var baseStyle$9 = definePartsStyle$4({
  link: baseStyleLink
});
var breadcrumbTheme = defineMultiStyleConfig$4({
  baseStyle: baseStyle$9
});
var baseStyle$8 = defineStyle({
  lineHeight: "1.2",
  borderRadius: "md",
  fontWeight: "semibold",
  transitionProperty: "common",
  transitionDuration: "normal",
  _focusVisible: {
    boxShadow: "outline"
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed",
    boxShadow: "none"
  },
  _hover: {
    _disabled: {
      bg: "initial"
    }
  }
});
var variantGhost = defineStyle(props => {
  const {
    colorScheme: c,
    theme
  } = props;
  if (c === "gray") {
    return {
      color: mode(`gray.800`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode(`gray.100`, `whiteAlpha.200`)(props)
      },
      _active: {
        bg: mode(`gray.200`, `whiteAlpha.300`)(props)
      }
    };
  }
  const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme);
  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme);
  return {
    color: mode(`${c}.600`, `${c}.200`)(props),
    bg: "transparent",
    _hover: {
      bg: mode(`${c}.50`, darkHoverBg)(props)
    },
    _active: {
      bg: mode(`${c}.100`, darkActiveBg)(props)
    }
  };
});
var variantOutline = defineStyle(props => {
  const {
    colorScheme: c
  } = props;
  const borderColor = mode(`gray.200`, `whiteAlpha.300`)(props);
  return Object.assign({
    border: "1px solid",
    borderColor: c === "gray" ? borderColor : "currentColor",
    ".chakra-button__group[data-attached][data-orientation=horizontal] > &:not(:last-of-type)": {
      marginEnd: "-1px"
    },
    ".chakra-button__group[data-attached][data-orientation=vertical] > &:not(:last-of-type)": {
      marginBottom: "-1px"
    }
  }, runIfFn(variantGhost, props));
});
var accessibleColorMap = {
  yellow: {
    bg: "yellow.400",
    color: "black",
    hoverBg: "yellow.500",
    activeBg: "yellow.600"
  },
  cyan: {
    bg: "cyan.400",
    color: "black",
    hoverBg: "cyan.500",
    activeBg: "cyan.600"
  }
};
var variantSolid$2 = defineStyle(props => {
  var _a;
  const {
    colorScheme: c
  } = props;
  if (c === "gray") {
    const bg2 = mode(`gray.100`, `whiteAlpha.200`)(props);
    return {
      bg: bg2,
      color: mode(`gray.800`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode(`gray.200`, `whiteAlpha.300`)(props),
        _disabled: {
          bg: bg2
        }
      },
      _active: {
        bg: mode(`gray.300`, `whiteAlpha.400`)(props)
      }
    };
  }
  const {
    bg = `${c}.500`,
    color = "white",
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`
  } = (_a = accessibleColorMap[c]) != null ? _a : {};
  const background = mode(bg, `${c}.200`)(props);
  return {
    bg: background,
    color: mode(color, `gray.800`)(props),
    _hover: {
      bg: mode(hoverBg, `${c}.300`)(props),
      _disabled: {
        bg: background
      }
    },
    _active: {
      bg: mode(activeBg, `${c}.400`)(props)
    }
  };
});
var variantLink = defineStyle(props => {
  const {
    colorScheme: c
  } = props;
  return {
    padding: 0,
    height: "auto",
    lineHeight: "normal",
    verticalAlign: "baseline",
    color: mode(`${c}.500`, `${c}.200`)(props),
    _hover: {
      textDecoration: "underline",
      _disabled: {
        textDecoration: "none"
      }
    },
    _active: {
      color: mode(`${c}.700`, `${c}.500`)(props)
    }
  };
});
var variantUnstyled = defineStyle({
  bg: "none",
  color: "inherit",
  display: "inline",
  lineHeight: "inherit",
  m: "0",
  p: "0"
});
var variants$4 = {
  ghost: variantGhost,
  outline: variantOutline,
  solid: variantSolid$2,
  link: variantLink,
  unstyled: variantUnstyled
};
var sizes$3 = {
  lg: defineStyle({
    h: "12",
    minW: "12",
    fontSize: "lg",
    px: "6"
  }),
  md: defineStyle({
    h: "10",
    minW: "10",
    fontSize: "md",
    px: "4"
  }),
  sm: defineStyle({
    h: "8",
    minW: "8",
    fontSize: "sm",
    px: "3"
  }),
  xs: defineStyle({
    h: "6",
    minW: "6",
    fontSize: "xs",
    px: "2"
  })
};
var buttonTheme = defineStyleConfig({
  baseStyle: baseStyle$8,
  variants: variants$4,
  sizes: sizes$3,
  defaultProps: {
    variant: "solid",
    size: "md",
    colorScheme: "gray"
  }
});

// src/components/card.ts
var {
  definePartsStyle: definePartsStyle$3,
  defineMultiStyleConfig: defineMultiStyleConfig$3
} = createMultiStyleConfigHelpers(cardAnatomy.keys);
var $bg$3 = cssVar("card-bg");
var $padding = cssVar("card-padding");
var $shadow = cssVar("card-shadow");
var $radius = cssVar("card-radius");
var $border$1 = cssVar("card-border-width", "0");
var $borderColor = cssVar("card-border-color");
var baseStyle$7 = definePartsStyle$3({
  container: {
    [$bg$3.variable]: "colors.chakra-body-bg",
    backgroundColor: $bg$3.reference,
    boxShadow: $shadow.reference,
    borderRadius: $radius.reference,
    color: "chakra-body-text",
    borderWidth: $border$1.reference,
    borderColor: $borderColor.reference
  },
  body: {
    padding: $padding.reference,
    flex: "1 1 0%"
  },
  header: {
    padding: $padding.reference
  },
  footer: {
    padding: $padding.reference
  }
});
var sizes$2 = {
  sm: definePartsStyle$3({
    container: {
      [$radius.variable]: "radii.base",
      [$padding.variable]: "space.3"
    }
  }),
  md: definePartsStyle$3({
    container: {
      [$radius.variable]: "radii.md",
      [$padding.variable]: "space.5"
    }
  }),
  lg: definePartsStyle$3({
    container: {
      [$radius.variable]: "radii.xl",
      [$padding.variable]: "space.7"
    }
  })
};
var variants$3 = {
  elevated: definePartsStyle$3({
    container: {
      [$shadow.variable]: "shadows.base",
      _dark: {
        [$bg$3.variable]: "colors.gray.700"
      }
    }
  }),
  outline: definePartsStyle$3({
    container: {
      [$border$1.variable]: "1px",
      [$borderColor.variable]: "colors.chakra-border-color"
    }
  }),
  filled: definePartsStyle$3({
    container: {
      [$bg$3.variable]: "colors.chakra-subtle-bg"
    }
  }),
  unstyled: {
    body: {
      [$padding.variable]: 0
    },
    header: {
      [$padding.variable]: 0
    },
    footer: {
      [$padding.variable]: 0
    }
  }
};
var cardTheme = defineMultiStyleConfig$3({
  baseStyle: baseStyle$7,
  variants: variants$3,
  sizes: sizes$2,
  defaultProps: {
    variant: "elevated",
    size: "md"
  }
});

// src/components/close-button.ts
var $size$1 = cssVar$1("close-button-size");
var $bg$2 = cssVar$1("close-button-bg");
var baseStyle$6 = defineStyle({
  w: [$size$1.reference],
  h: [$size$1.reference],
  borderRadius: "md",
  transitionProperty: "common",
  transitionDuration: "normal",
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed",
    boxShadow: "none"
  },
  _hover: {
    [$bg$2.variable]: "colors.blackAlpha.100",
    _dark: {
      [$bg$2.variable]: "colors.whiteAlpha.100"
    }
  },
  _active: {
    [$bg$2.variable]: "colors.blackAlpha.200",
    _dark: {
      [$bg$2.variable]: "colors.whiteAlpha.200"
    }
  },
  _focusVisible: {
    boxShadow: "outline"
  },
  bg: $bg$2.reference
});
var sizes$1 = {
  lg: defineStyle({
    [$size$1.variable]: "sizes.10",
    fontSize: "md"
  }),
  md: defineStyle({
    [$size$1.variable]: "sizes.8",
    fontSize: "xs"
  }),
  sm: defineStyle({
    [$size$1.variable]: "sizes.6",
    fontSize: "2xs"
  })
};
var closeButtonTheme = defineStyleConfig({
  baseStyle: baseStyle$6,
  sizes: sizes$1,
  defaultProps: {
    size: "md"
  }
});
var {
  variants: variants$2,
  defaultProps
} = badgeTheme;
var baseStyle$5 = defineStyle({
  fontFamily: "mono",
  fontSize: "sm",
  px: "0.2em",
  borderRadius: "sm",
  bg: vars.bg.reference,
  color: vars.color.reference,
  boxShadow: vars.shadow.reference
});
var codeTheme = defineStyleConfig({
  baseStyle: baseStyle$5,
  variants: variants$2,
  defaultProps
});

// src/components/container.ts
var baseStyle$4 = defineStyle({
  w: "100%",
  mx: "auto",
  maxW: "prose",
  px: "4"
});
var containerTheme = defineStyleConfig({
  baseStyle: baseStyle$4
});

// src/components/divider.ts
var baseStyle$3 = defineStyle({
  opacity: 0.6,
  borderColor: "inherit"
});
var variantSolid$1 = defineStyle({
  borderStyle: "solid"
});
var variantDashed = defineStyle({
  borderStyle: "dashed"
});
var variants$1 = {
  solid: variantSolid$1,
  dashed: variantDashed
};
var dividerTheme = defineStyleConfig({
  baseStyle: baseStyle$3,
  variants: variants$1,
  defaultProps: {
    variant: "solid"
  }
});

// src/components/accordion.ts
var {
  definePartsStyle: definePartsStyle$2,
  defineMultiStyleConfig: defineMultiStyleConfig$2
} = createMultiStyleConfigHelpers(accordionAnatomy.keys);
var baseStyleContainer$1 = defineStyle({
  borderTopWidth: "1px",
  borderColor: "inherit",
  _last: {
    borderBottomWidth: "1px"
  }
});
var baseStyleButton = defineStyle({
  transitionProperty: "common",
  transitionDuration: "normal",
  fontSize: "md",
  _focusVisible: {
    boxShadow: "outline"
  },
  _hover: {
    bg: "blackAlpha.50"
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed"
  },
  px: "4",
  py: "2"
});
var baseStylePanel = defineStyle({
  pt: "2",
  px: "4",
  pb: "5"
});
var baseStyleIcon = defineStyle({
  fontSize: "1.25em"
});
var baseStyle$2 = definePartsStyle$2({
  container: baseStyleContainer$1,
  button: baseStyleButton,
  panel: baseStylePanel,
  icon: baseStyleIcon
});
var accordionTheme = defineMultiStyleConfig$2({
  baseStyle: baseStyle$2
});

// src/components/alert.ts
var {
  definePartsStyle: definePartsStyle$1,
  defineMultiStyleConfig: defineMultiStyleConfig$1
} = createMultiStyleConfigHelpers(alertAnatomy.keys);
var $fg = cssVar("alert-fg");
var $bg$1 = cssVar("alert-bg");
var baseStyle$1 = definePartsStyle$1({
  container: {
    bg: $bg$1.reference,
    px: "4",
    py: "3"
  },
  title: {
    fontWeight: "bold",
    lineHeight: "6",
    marginEnd: "2"
  },
  description: {
    lineHeight: "6"
  },
  icon: {
    color: $fg.reference,
    flexShrink: 0,
    marginEnd: "3",
    w: "5",
    h: "6"
  },
  spinner: {
    color: $fg.reference,
    flexShrink: 0,
    marginEnd: "3",
    w: "5",
    h: "5"
  }
});
function getBg(props) {
  const {
    theme,
    colorScheme: c
  } = props;
  const darkBg = transparentize(`${c}.200`, 0.16)(theme);
  return {
    light: `colors.${c}.100`,
    dark: darkBg
  };
}
var variantSubtle = definePartsStyle$1(props => {
  const {
    colorScheme: c
  } = props;
  const bg = getBg(props);
  return {
    container: {
      [$fg.variable]: `colors.${c}.500`,
      [$bg$1.variable]: bg.light,
      _dark: {
        [$fg.variable]: `colors.${c}.200`,
        [$bg$1.variable]: bg.dark
      }
    }
  };
});
var variantLeftAccent = definePartsStyle$1(props => {
  const {
    colorScheme: c
  } = props;
  const bg = getBg(props);
  return {
    container: {
      [$fg.variable]: `colors.${c}.500`,
      [$bg$1.variable]: bg.light,
      _dark: {
        [$fg.variable]: `colors.${c}.200`,
        [$bg$1.variable]: bg.dark
      },
      paddingStart: "3",
      borderStartWidth: "4px",
      borderStartColor: $fg.reference
    }
  };
});
var variantTopAccent = definePartsStyle$1(props => {
  const {
    colorScheme: c
  } = props;
  const bg = getBg(props);
  return {
    container: {
      [$fg.variable]: `colors.${c}.500`,
      [$bg$1.variable]: bg.light,
      _dark: {
        [$fg.variable]: `colors.${c}.200`,
        [$bg$1.variable]: bg.dark
      },
      pt: "2",
      borderTopWidth: "4px",
      borderTopColor: $fg.reference
    }
  };
});
var variantSolid = definePartsStyle$1(props => {
  const {
    colorScheme: c
  } = props;
  return {
    container: {
      [$fg.variable]: `colors.white`,
      [$bg$1.variable]: `colors.${c}.500`,
      _dark: {
        [$fg.variable]: `colors.gray.900`,
        [$bg$1.variable]: `colors.${c}.200`
      },
      color: $fg.reference
    }
  };
});
var variants = {
  subtle: variantSubtle,
  "left-accent": variantLeftAccent,
  "top-accent": variantTopAccent,
  solid: variantSolid
};
var alertTheme = defineMultiStyleConfig$1({
  baseStyle: baseStyle$1,
  variants,
  defaultProps: {
    variant: "subtle",
    colorScheme: "blue"
  }
});
var {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(avatarAnatomy.keys);
var $border = cssVar("avatar-border-color");
var $bg = cssVar("avatar-bg");
var $fs = cssVar("avatar-font-size");
var $size = cssVar("avatar-size");
var baseStyleBadge = defineStyle({
  borderRadius: "full",
  border: "0.2em solid",
  borderColor: $border.reference,
  [$border.variable]: "white",
  _dark: {
    [$border.variable]: "colors.gray.800"
  }
});
var baseStyleExcessLabel = defineStyle({
  bg: $bg.reference,
  fontSize: $fs.reference,
  width: $size.reference,
  height: $size.reference,
  lineHeight: "1",
  [$bg.variable]: "colors.gray.200",
  _dark: {
    [$bg.variable]: "colors.whiteAlpha.400"
  }
});
var baseStyleContainer = defineStyle(props => {
  const {
    name,
    theme
  } = props;
  const bg = name ? randomColor({
    string: name
  }) : "colors.gray.400";
  const isBgDark = isDark(bg)(theme);
  let color = "white";
  if (!isBgDark) color = "gray.800";
  return {
    bg: $bg.reference,
    fontSize: $fs.reference,
    color,
    borderColor: $border.reference,
    verticalAlign: "top",
    width: $size.reference,
    height: $size.reference,
    "&:not([data-loaded])": {
      [$bg.variable]: bg
    },
    [$border.variable]: "colors.white",
    _dark: {
      [$border.variable]: "colors.gray.800"
    }
  };
});
var baseStyleLabel = defineStyle({
  fontSize: $fs.reference,
  lineHeight: "1"
});
var baseStyle = definePartsStyle(props => ({
  badge: runIfFn(baseStyleBadge, props),
  excessLabel: runIfFn(baseStyleExcessLabel, props),
  container: runIfFn(baseStyleContainer, props),
  label: baseStyleLabel
}));
function getSize(size) {
  const themeSize = size !== "100%" ? sizes_default[size] : void 0;
  return definePartsStyle({
    container: {
      [$size.variable]: themeSize != null ? themeSize : size,
      [$fs.variable]: `calc(${themeSize != null ? themeSize : size} / 2.5)`
    },
    excessLabel: {
      [$size.variable]: themeSize != null ? themeSize : size,
      [$fs.variable]: `calc(${themeSize != null ? themeSize : size} / 2.5)`
    }
  });
}
var sizes = {
  "2xs": getSize(4),
  xs: getSize(6),
  sm: getSize(8),
  md: getSize(12),
  lg: getSize(16),
  xl: getSize(24),
  "2xl": getSize(32),
  full: getSize("100%")
};
var avatarTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: "md"
  }
});

// src/components/index.ts
var components = {
  Accordion: accordionTheme,
  Alert: alertTheme,
  Avatar: avatarTheme,
  Badge: badgeTheme,
  Breadcrumb: breadcrumbTheme,
  Button: buttonTheme,
  Checkbox: checkboxTheme,
  CloseButton: closeButtonTheme,
  Code: codeTheme,
  Container: containerTheme,
  Divider: dividerTheme,
  Drawer: drawerTheme,
  Editable: editableTheme,
  Form: formTheme,
  FormError: formErrorTheme,
  FormLabel: formLabelTheme,
  Heading: headingTheme,
  Input: inputTheme,
  Kbd: kbdTheme,
  Link: linkTheme,
  List: listTheme,
  Menu: menuTheme,
  Modal: modalTheme,
  NumberInput: numberInputTheme,
  PinInput: pinInputTheme,
  Popover: popoverTheme,
  Progress: progressTheme,
  Radio: radioTheme,
  Select: selectTheme,
  Skeleton: skeletonTheme,
  SkipLink: skipLinkTheme,
  Slider: sliderTheme,
  Spinner: spinnerTheme,
  Stat: statTheme,
  Switch: switchTheme,
  Table: tableTheme,
  Tabs: tabsTheme,
  Tag: tagTheme,
  Textarea: textareaTheme,
  Tooltip: tooltipTheme,
  Card: cardTheme,
  Stepper: stepperTheme
};

// src/semantic-tokens.ts
var semanticTokens = {
  colors: {
    "chakra-body-text": {
      _light: "gray.800",
      _dark: "whiteAlpha.900"
    },
    "chakra-body-bg": {
      _light: "white",
      _dark: "gray.800"
    },
    "chakra-border-color": {
      _light: "gray.200",
      _dark: "whiteAlpha.300"
    },
    "chakra-inverse-text": {
      _light: "white",
      _dark: "gray.800"
    },
    "chakra-subtle-bg": {
      _light: "gray.100",
      _dark: "gray.700"
    },
    "chakra-subtle-text": {
      _light: "gray.600",
      _dark: "gray.400"
    },
    "chakra-placeholder-color": {
      _light: "gray.500",
      _dark: "whiteAlpha.400"
    }
  }
};

// src/styles.ts
var styles = {
  global: {
    body: {
      fontFamily: "body",
      color: "chakra-body-text",
      bg: "chakra-body-bg",
      transitionProperty: "background-color",
      transitionDuration: "normal",
      lineHeight: "base"
    },
    "*::placeholder": {
      color: "chakra-placeholder-color"
    },
    "*, *::before, &::after": {
      borderColor: "chakra-border-color"
    }
  }
};

// src/index.ts
var direction = "ltr";
var config = {
  useSystemColorMode: false,
  initialColorMode: "light",
  cssVarPrefix: "chakra"
};
var theme = Object.assign({
  semanticTokens,
  direction
}, foundations, {
  components,
  styles,
  config
});
var src_default = theme;
var baseTheme = Object.assign({
  semanticTokens,
  direction,
  components: {}
}, foundations, {
  styles,
  config
});
export { baseTheme, src_default as default, isChakraTheme, requiredChakraThemeKeys, theme };