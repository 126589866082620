export function generateValidationFunction(config) {
  return function (value) {
    // Null check (properties from backend are sometimes sent back as null)
    if (value === null) {
      return [];
    }
    const errors = [];
    if (config.minLength && value.length < config.minLength.value) {
      errors.push(config.minLength.error);
    }
    if (config.maxLength && value.length > config.maxLength.value) {
      errors.push(config.maxLength.error);
    }
    if (config.validCharacters && value.length > 0) {
      const regex = config.validCharacters.value;
      const isValid = new RegExp(regex).test(value);
      if (!isValid) {
        errors.push(config.validCharacters.error);
      }
    }
    return errors;
  };
}