import { useEffect } from 'react';
import { useReducer } from 'react';
var FormFieldReducerAction;
(function (FormFieldReducerAction) {
  FormFieldReducerAction[FormFieldReducerAction["UPDATE"] = 0] = "UPDATE";
  FormFieldReducerAction[FormFieldReducerAction["BLUR"] = 1] = "BLUR";
  FormFieldReducerAction[FormFieldReducerAction["RESET"] = 2] = "RESET";
})(FormFieldReducerAction || (FormFieldReducerAction = {}));
const defaultState = {
  value: '',
  touched: false,
  errors: [],
  hasBlurred: false
};
function formFieldReducer(state, action) {
  switch (action.type) {
    case FormFieldReducerAction.UPDATE:
      {
        return Object.assign({}, state, {
          value: action.value,
          errors: action.errors,
          touched: action.touched
        });
      }
    case FormFieldReducerAction.RESET:
      {
        return Object.assign({}, defaultState, {
          value: action.value
        });
      }
    case FormFieldReducerAction.BLUR:
      {
        return Object.assign({}, state, {
          hasBlurred: true
        });
      }
    default:
      {
        return state;
      }
  }
}
export function useFormFieldState(validator, initialValue, type = 'input') {
  const initialState = Object.assign({}, defaultState);
  const [{
    value,
    errors,
    touched,
    hasBlurred
  }, dispatch] = useReducer(formFieldReducer, initialState);

  /** The _touched arg allows us to invoke the onChange without affecting the forms pristine state, eg. for initialValue setting */
  function onChange(updatedValue, _touched = true) {
    dispatch({
      type: FormFieldReducerAction.UPDATE,
      value: updatedValue,
      errors: validator(updatedValue),
      touched: _touched
    });
  }
  function onReset() {
    dispatch({
      type: FormFieldReducerAction.RESET,
      value: initialValue ? initialValue : ''
    });
  }
  function onBlur() {
    dispatch({
      type: FormFieldReducerAction.BLUR
    });
  }

  // NOTE: We need to switch this to useEffect because initialValue may change as a result of other hooks updating that value
  // after this hook has been initialized. We still need to run the onChange event in that scenario
  useEffect(() => {
    if (typeof initialValue !== 'undefined') {
      // FIXME: https://git.hubteam.com/hubspot/connect-dot-com-app-ui/issues/727
      // @ts-expect-error Needs to be able to handle multiple types
      onChange(initialValue, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);
  return {
    type,
    value,
    errors,
    touched,
    hasBlurred,
    onChange,
    onReset,
    onBlur
  };
}