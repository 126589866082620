import { createIndexedDBSuperstore } from 'superstore';
let storeInstance;
function getStore() {
  if (typeof storeInstance === 'undefined') {
    storeInstance = createIndexedDBSuperstore({
      namespace: 'connectdotcom',
      global: true
    }).open();
  }
  return storeInstance;
}
class Storage {
  async set(name, value) {
    if (value) {
      const store = await getStore();
      await store.set(name, value);
      return;
    }
  }
  async get(name) {
    const store = await getStore();
    return store.get(name);
  }
  async remove(name) {
    const store = await getStore();
    await store.delete(name);
  }
  async clear() {
    const store = await getStore();
    await store.clear();
  }
}
export { Storage, getStore };