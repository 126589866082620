import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { generateBasicBreakpointObject } from '../../../utils/layout';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  tab: {
    fontWeight: 'medium',
    // change the font weight
    _selected: {
      color: 'CARNABY.regular'
    },
    _first: {
      paddingLeft: generateBasicBreakpointObject(0, 4)
    },
    _last: {
      paddingRight: generateBasicBreakpointObject(0, 4)
    }
  },
  tabpanel: {
    paddingTop: 5,
    paddingX: 0
  }
});

// export the component theme
export const tabsTheme = defineMultiStyleConfig({
  baseStyle
});