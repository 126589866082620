import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(parts.keys);
const baseStyle = props => definePartsStyle(Object.assign({}, props.theme.components.Modal.baseStyle, {
  overlay: Object.assign({}, props.theme.components.Modal.baseStyle.overlay, {
    bg: 'blackAlpha.750'
  }),
  header: Object.assign({}, props.theme.components.Modal.baseStyle.header, {
    paddingX: 8,
    borderBottom: '1px solid',
    borderBottomColor: 'GREYSCALE.SATELLITE',
    paddingBottom: 3
  }),
  body: Object.assign({}, props.theme.components.Modal.baseStyle.body, {
    py: 3,
    px: 8
  }),
  footer: Object.assign({}, props.theme.components.Modal.baseStyle.footer, {
    paddingTop: 3,
    px: 8,
    paddingBottom: 8,
    justifyContent: 'initial'
  })
}));
const lightbox = props => definePartsStyle({
  closeButton: {
    '--close-button-size': props.theme.sizes[8],
    color: 'GREYSCALE.MOON',
    right: 5,
    top: 5,
    zIndex: 'modal',
    svg: {
      height: 'full',
      width: 'full'
    }
  }
});
export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    lightbox
  }
});