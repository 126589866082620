'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["htmlWidth", "htmlHeight", "alt"],
  _excluded2 = ["fallbackSrc", "fallback", "src", "srcSet", "align", "fit", "loading", "ignoreFallback", "crossOrigin", "fallbackStrategy", "referrerPolicy"];
import { forwardRef, chakra } from "../system";
import { jsx } from 'react/jsx-runtime';
import { useSafeLayoutEffect } from "../react-use-safe-layout-effect";
import { useState, useEffect, useRef, useCallback } from 'react';

// src/native-image.tsx
var NativeImage = forwardRef(function NativeImage2(props, ref) {
  const {
      htmlWidth,
      htmlHeight,
      alt
    } = props,
    rest = _objectWithoutPropertiesLoose(props, _excluded);
  return /* @__PURE__ */jsx("img", Object.assign({
    width: htmlWidth,
    height: htmlHeight,
    ref,
    alt
  }, rest));
});
NativeImage.displayName = "NativeImage";

// src/use-image.ts
function useImage(props) {
  const {
    loading,
    src,
    srcSet,
    onLoad,
    onError,
    crossOrigin,
    sizes,
    ignoreFallback
  } = props;
  const [status, setStatus] = useState("pending");
  useEffect(() => {
    setStatus(src ? "loading" : "pending");
  }, [src]);
  const imageRef = useRef();
  const load = useCallback(() => {
    if (!src) return;
    flush();
    const img = new Image();
    img.src = src;
    if (crossOrigin) img.crossOrigin = crossOrigin;
    if (srcSet) img.srcset = srcSet;
    if (sizes) img.sizes = sizes;
    if (loading) img.loading = loading;
    img.onload = event => {
      flush();
      setStatus("loaded");
      onLoad == null ? void 0 : onLoad(event);
    };
    img.onerror = error => {
      flush();
      setStatus("failed");
      onError == null ? void 0 : onError(error);
    };
    imageRef.current = img;
  }, [src, crossOrigin, srcSet, sizes, onLoad, onError, loading]);
  const flush = () => {
    if (imageRef.current) {
      imageRef.current.onload = null;
      imageRef.current.onerror = null;
      imageRef.current = null;
    }
  };
  useSafeLayoutEffect(() => {
    if (ignoreFallback) return void 0;
    if (status === "loading") {
      load();
    }
    return () => {
      flush();
    };
  }, [status, load, ignoreFallback]);
  return ignoreFallback ? "loaded" : status;
}
var shouldShowFallbackImage = (status, fallbackStrategy) => status !== "loaded" && fallbackStrategy === "beforeLoadOrError" || status === "failed" && fallbackStrategy === "onError";

// ../../utilities/object-utils/src/omit.ts
function omit(object, keysToOmit = []) {
  const clone = Object.assign({}, object);
  for (const key of keysToOmit) {
    if (key in clone) {
      delete clone[key];
    }
  }
  return clone;
}
var Image$1 = forwardRef(function Image2(props, ref) {
  const {
      fallbackSrc,
      fallback,
      src,
      srcSet,
      align,
      fit,
      loading,
      ignoreFallback,
      crossOrigin,
      fallbackStrategy = "beforeLoadOrError",
      referrerPolicy
    } = props,
    rest = _objectWithoutPropertiesLoose(props, _excluded2);
  const providedFallback = fallbackSrc !== void 0 || fallback !== void 0;
  const shouldIgnoreFallbackImage = loading != null || ignoreFallback || !providedFallback;
  const status = useImage(Object.assign({}, props, {
    crossOrigin,
    ignoreFallback: shouldIgnoreFallbackImage
  }));
  const showFallbackImage = shouldShowFallbackImage(status, fallbackStrategy);
  const shared = Object.assign({
    ref,
    objectFit: fit,
    objectPosition: align
  }, shouldIgnoreFallbackImage ? rest : omit(rest, ["onError", "onLoad"]));
  if (showFallbackImage) {
    if (fallback) return fallback;
    return /* @__PURE__ */jsx(chakra.img, Object.assign({
      as: NativeImage,
      className: "chakra-image__placeholder",
      src: fallbackSrc
    }, shared));
  }
  return /* @__PURE__ */jsx(chakra.img, Object.assign({
    as: NativeImage,
    src,
    srcSet,
    crossOrigin,
    loading,
    referrerPolicy,
    className: "chakra-image"
  }, shared));
});
Image$1.displayName = "Image";
var Img = forwardRef((props, ref) => /* @__PURE__ */jsx(chakra.img, Object.assign({
  ref,
  as: NativeImage,
  className: "chakra-image"
}, props)));
export { Image$1 as Image, Img, useImage };