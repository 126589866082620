import { getEnvironment } from './portal-config';
import { getCookie } from './cookies';
export let Forms;
(function (Forms) {
  Forms["ANDROID_WAITLIST"] = "ANDROID_WAITLIST";
})(Forms || (Forms = {}));
const formConfig = {
  qa: {
    ANDROID_WAITLIST: {
      formGuid: 'c0b2a0ce-25d2-4cb5-80cc-4e5ccf849cee',
      consentText: 'I agree to allow Connect.com to store and process my personal data.'
    }
  },
  prod: {
    ANDROID_WAITLIST: {
      formGuid: '8b247aea-073a-490d-a931-2d3085a0d612',
      consentText: 'I agree to allow Connect.com to store and process my personal data.'
    }
  }
};
export const getConfigForForm = formName => {
  return formConfig[getEnvironment()][formName];
};
const getConsentText = formName => {
  const environment = getEnvironment();
  return formConfig[environment][formName].consentText;
};
export const getFormData = (email, formType) => {
  const hutk = getCookie('hubspotutk');
  const data = {
    submittedAt: `${new Date().getTime()}`,
    fields: [{
      objectTypeId: '0-1',
      name: 'email',
      value: email
    }],
    context: {
      hutk,
      pageUri: 'www.connect.com',
      pageName: 'Connect.com'
    },
    legalConsentOptions: {
      // Include this object when GDPR options are enabled
      consent: {
        consentToProcess: true,
        text: getConsentText(formType),
        communications: []
      }
    }
  };
  return data;
};