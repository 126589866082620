import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { variantOutlined } from '../../focusShim';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(selectAnatomy.keys);

/**
 * This is the default variant of the Select component as
 * specified here: https://v2.chakra-ui.com/docs/components/select/theming#theming-properties
 */
const outline = definePartsStyle(() => {
  return {
    field: Object.assign({
      fontSize: 'sm',
      backgroundColor: 'GREYSCALE.SUPERNOVA',
      borderColor: 'GREYSCALE.SATELLITE'
    }, variantOutlined()),
    icon: {
      color: 'CARNABY.regular'
    }
  };
});
export const selectTheme = defineMultiStyleConfig({
  variants: {
    outline
  }
});