'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["id", "isRequired", "isInvalid", "isDisabled", "isReadOnly"],
  _excluded2 = ["getRootProps", "htmlProps"],
  _excluded3 = ["className", "children", "requiredIndicator", "optionalIndicator"],
  _excluded4 = ["isDisabled", "isInvalid", "isReadOnly", "isRequired"],
  _excluded5 = ["id", "disabled", "readOnly", "required", "isRequired", "isInvalid", "isReadOnly", "isDisabled", "onFocus", "onBlur"];
import { createContext } from "../react-context";
import { mergeRefs } from "../react-use-merge-refs";
import { forwardRef, useMultiStyleConfig, omitThemingProps, chakra, useStyleConfig } from "../system";
import { cx, dataAttr, ariaAttr, callAllHandlers } from "../shared-utils";
import { useId, useState, useCallback } from 'react';
import { jsx, jsxs } from 'react/jsx-runtime';
import { Icon } from "../icon";

// src/form-control.tsx
var [FormControlStylesProvider, useFormControlStyles] = createContext({
  name: `FormControlStylesContext`,
  errorMessage: `useFormControlStyles returned is 'undefined'. Seems you forgot to wrap the components in "<FormControl />" `
});
var [FormControlProvider, useFormControlContext] = createContext({
  strict: false,
  name: "FormControlContext"
});
function useFormControlProvider(props) {
  const {
      id: idProp,
      isRequired,
      isInvalid,
      isDisabled,
      isReadOnly
    } = props,
    htmlProps = _objectWithoutPropertiesLoose(props, _excluded);
  const uuid = useId();
  const id = idProp || `field-${uuid}`;
  const labelId = `${id}-label`;
  const feedbackId = `${id}-feedback`;
  const helpTextId = `${id}-helptext`;
  const [hasFeedbackText, setHasFeedbackText] = useState(false);
  const [hasHelpText, setHasHelpText] = useState(false);
  const [isFocused, setFocus] = useState(false);
  const getHelpTextProps = useCallback((props2 = {}, forwardedRef = null) => Object.assign({
    id: helpTextId
  }, props2, {
    ref: mergeRefs(forwardedRef, node => {
      if (!node) return;
      setHasHelpText(true);
    })
  }), [helpTextId]);
  const getLabelProps = useCallback((props2 = {}, forwardedRef = null) => Object.assign({}, props2, {
    ref: forwardedRef,
    "data-focus": dataAttr(isFocused),
    "data-disabled": dataAttr(isDisabled),
    "data-invalid": dataAttr(isInvalid),
    "data-readonly": dataAttr(isReadOnly),
    id: props2.id !== void 0 ? props2.id : labelId,
    htmlFor: props2.htmlFor !== void 0 ? props2.htmlFor : id
  }), [id, isDisabled, isFocused, isInvalid, isReadOnly, labelId]);
  const getErrorMessageProps = useCallback((props2 = {}, forwardedRef = null) => Object.assign({
    id: feedbackId
  }, props2, {
    ref: mergeRefs(forwardedRef, node => {
      if (!node) return;
      setHasFeedbackText(true);
    }),
    "aria-live": "polite"
  }), [feedbackId]);
  const getRootProps = useCallback((props2 = {}, forwardedRef = null) => Object.assign({}, props2, htmlProps, {
    ref: forwardedRef,
    role: "group"
  }), [htmlProps]);
  const getRequiredIndicatorProps = useCallback((props2 = {}, forwardedRef = null) => Object.assign({}, props2, {
    ref: forwardedRef,
    role: "presentation",
    "aria-hidden": true,
    children: props2.children || "*"
  }), []);
  return {
    isRequired: !!isRequired,
    isInvalid: !!isInvalid,
    isReadOnly: !!isReadOnly,
    isDisabled: !!isDisabled,
    isFocused: !!isFocused,
    onFocus: () => setFocus(true),
    onBlur: () => setFocus(false),
    hasFeedbackText,
    setHasFeedbackText,
    hasHelpText,
    setHasHelpText,
    id,
    labelId,
    feedbackId,
    helpTextId,
    htmlProps,
    getHelpTextProps,
    getErrorMessageProps,
    getRootProps,
    getLabelProps,
    getRequiredIndicatorProps
  };
}
var FormControl = forwardRef(function FormControl2(props, ref) {
  const styles = useMultiStyleConfig("Form", props);
  const ownProps = omitThemingProps(props);
  const _useFormControlProvid = useFormControlProvider(ownProps),
    {
      getRootProps
    } = _useFormControlProvid,
    context = _objectWithoutPropertiesLoose(_useFormControlProvid, _excluded2);
  const className = cx("chakra-form-control", props.className);
  return /* @__PURE__ */jsx(FormControlProvider, {
    value: context,
    children: /* @__PURE__ */jsx(FormControlStylesProvider, {
      value: styles,
      children: /* @__PURE__ */jsx(chakra.div, Object.assign({}, getRootProps({}, ref), {
        className,
        __css: styles["container"]
      }))
    })
  });
});
FormControl.displayName = "FormControl";
var FormHelperText = forwardRef(function FormHelperText2(props, ref) {
  const field = useFormControlContext();
  const styles = useFormControlStyles();
  const className = cx("chakra-form__helper-text", props.className);
  return /* @__PURE__ */jsx(chakra.div, Object.assign({}, field == null ? void 0 : field.getHelpTextProps(props, ref), {
    __css: styles.helperText,
    className
  }));
});
FormHelperText.displayName = "FormHelperText";
var [FormErrorStylesProvider, useFormErrorStyles] = createContext({
  name: `FormErrorStylesContext`,
  errorMessage: `useFormErrorStyles returned is 'undefined'. Seems you forgot to wrap the components in "<FormError />" `
});
var FormErrorMessage = forwardRef((props, ref) => {
  const styles = useMultiStyleConfig("FormError", props);
  const ownProps = omitThemingProps(props);
  const field = useFormControlContext();
  if (!(field == null ? void 0 : field.isInvalid)) return null;
  return /* @__PURE__ */jsx(FormErrorStylesProvider, {
    value: styles,
    children: /* @__PURE__ */jsx(chakra.div, Object.assign({}, field == null ? void 0 : field.getErrorMessageProps(ownProps, ref), {
      className: cx("chakra-form__error-message", props.className),
      __css: Object.assign({
        display: "flex",
        alignItems: "center"
      }, styles.text)
    }))
  });
});
FormErrorMessage.displayName = "FormErrorMessage";
var FormErrorIcon = forwardRef((props, ref) => {
  const styles = useFormErrorStyles();
  const field = useFormControlContext();
  if (!(field == null ? void 0 : field.isInvalid)) return null;
  const _className = cx("chakra-form__error-icon", props.className);
  return /* @__PURE__ */jsx(Icon, Object.assign({
    ref,
    "aria-hidden": true
  }, props, {
    __css: styles.icon,
    className: _className,
    children: /* @__PURE__ */jsx("path", {
      fill: "currentColor",
      d: "M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
    })
  }));
});
FormErrorIcon.displayName = "FormErrorIcon";
var FormLabel = forwardRef(function FormLabel2(passedProps, ref) {
  var _a;
  const styles = useStyleConfig("FormLabel", passedProps);
  const props = omitThemingProps(passedProps);
  const {
      children,
      requiredIndicator = /* @__PURE__ */jsx(RequiredIndicator, {}),
      optionalIndicator = null
    } = props,
    rest = _objectWithoutPropertiesLoose(props, _excluded3);
  const field = useFormControlContext();
  const ownProps = (_a = field == null ? void 0 : field.getLabelProps(rest, ref)) != null ? _a : Object.assign({
    ref
  }, rest);
  return /* @__PURE__ */jsxs(chakra.label, Object.assign({}, ownProps, {
    className: cx("chakra-form__label", props.className),
    __css: Object.assign({
      display: "block",
      textAlign: "start"
    }, styles),
    children: [children, (field == null ? void 0 : field.isRequired) ? requiredIndicator : optionalIndicator]
  }));
});
FormLabel.displayName = "FormLabel";
var RequiredIndicator = forwardRef(function RequiredIndicator2(props, ref) {
  const field = useFormControlContext();
  const styles = useFormControlStyles();
  if (!(field == null ? void 0 : field.isRequired)) return null;
  const className = cx("chakra-form__required-indicator", props.className);
  return /* @__PURE__ */jsx(chakra.span, Object.assign({}, field == null ? void 0 : field.getRequiredIndicatorProps(props, ref), {
    __css: styles.requiredIndicator,
    className
  }));
});
RequiredIndicator.displayName = "RequiredIndicator";
function useFormControl(props) {
  const _useFormControlProps = useFormControlProps(props),
    {
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired
    } = _useFormControlProps,
    rest = _objectWithoutPropertiesLoose(_useFormControlProps, _excluded4);
  return Object.assign({}, rest, {
    disabled: isDisabled,
    readOnly: isReadOnly,
    required: isRequired,
    "aria-invalid": ariaAttr(isInvalid),
    "aria-required": ariaAttr(isRequired),
    "aria-readonly": ariaAttr(isReadOnly)
  });
}
function useFormControlProps(props) {
  var _a, _b, _c;
  const field = useFormControlContext();
  const {
      id,
      disabled,
      readOnly,
      required,
      isRequired,
      isInvalid,
      isReadOnly,
      isDisabled,
      onFocus,
      onBlur
    } = props,
    rest = _objectWithoutPropertiesLoose(props, _excluded5);
  const labelIds = props["aria-describedby"] ? [props["aria-describedby"]] : [];
  if ((field == null ? void 0 : field.hasFeedbackText) && (field == null ? void 0 : field.isInvalid)) {
    labelIds.push(field.feedbackId);
  }
  if (field == null ? void 0 : field.hasHelpText) {
    labelIds.push(field.helpTextId);
  }
  return Object.assign({}, rest, {
    "aria-describedby": labelIds.join(" ") || void 0,
    id: id != null ? id : field == null ? void 0 : field.id,
    isDisabled: (_a = disabled != null ? disabled : isDisabled) != null ? _a : field == null ? void 0 : field.isDisabled,
    isReadOnly: (_b = readOnly != null ? readOnly : isReadOnly) != null ? _b : field == null ? void 0 : field.isReadOnly,
    isRequired: (_c = required != null ? required : isRequired) != null ? _c : field == null ? void 0 : field.isRequired,
    isInvalid: isInvalid != null ? isInvalid : field == null ? void 0 : field.isInvalid,
    onFocus: callAllHandlers(field == null ? void 0 : field.onFocus, onFocus),
    onBlur: callAllHandlers(field == null ? void 0 : field.onBlur, onBlur)
  });
}
export { FormControl, FormErrorIcon, FormErrorMessage, FormHelperText, FormLabel, RequiredIndicator, useFormControl, useFormControlContext, useFormControlProps, useFormControlStyles, useFormErrorStyles };