import { tableAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(parts.keys);
const simple = () => definePartsStyle({
  thead: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'GREYSCALE.SATELLITE'
  },
  th: {
    backgroundColor: 'GREYSCALE.SUPERNOVA',
    fontWeight: 500,
    paddingInlineStart: 5,
    paddingInlineEnd: 5,
    color: 'GREYSCALE.ECLIPSE'
  },
  tbody: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'GREYSCALE.SATELLITE'
  },
  td: {
    pl: 5,
    lineHeight: 'normal'
  }
});

/**
 *
 * We overwrite the "simple" variant because that's the default variant. baseStyle only works properly if there
 * are other variants.
 *
 * https://v2.chakra-ui.com/docs/components/table/theming#theming-properties
 */
const variants = {
  simple
};
export const tableTheme = defineMultiStyleConfig({
  variants
});