module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": [
        "homepage",
        "members"
      ]
    },
    "namespace": "connect-dot-com-public-ui",
    "version": "1"
  },
  "interaction": {
    "name": "user interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "identifier": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "connect-dot-com-public-ui",
    "version": "1"
  }
};