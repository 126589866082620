'use es6';

import { useEnvironment } from "../react-env";
import { useState, useEffect } from 'react';
import { useTheme } from "../system";
import { jsx } from 'react/jsx-runtime';
import { breakpoints, arrayToObjectNotation } from "../breakpoint-utils";
import { isObject } from "../shared-utils";

// src/use-media-query.ts
function useMediaQuery(query, options = {}) {
  const {
    ssr = true,
    fallback
  } = options;
  const {
    getWindow
  } = useEnvironment();
  const queries = Array.isArray(query) ? query : [query];
  let fallbackValues = Array.isArray(fallback) ? fallback : [fallback];
  fallbackValues = fallbackValues.filter(v => v != null);
  const [value, setValue] = useState(() => {
    return queries.map((query2, index) => ({
      media: query2,
      matches: ssr ? !!fallbackValues[index] : getWindow().matchMedia(query2).matches
    }));
  });
  useEffect(() => {
    const win = getWindow();
    setValue(queries.map(query2 => ({
      media: query2,
      matches: win.matchMedia(query2).matches
    })));
    const mql = queries.map(query2 => win.matchMedia(query2));
    const handler = evt => {
      setValue(prev => {
        return prev.slice().map(item => {
          if (item.media === evt.media) return Object.assign({}, item, {
            matches: evt.matches
          });
          return item;
        });
      });
    };
    mql.forEach(mql2 => {
      if (typeof mql2.addListener === "function") {
        mql2.addListener(handler);
      } else {
        mql2.addEventListener("change", handler);
      }
    });
    return () => {
      mql.forEach(mql2 => {
        if (typeof mql2.removeListener === "function") {
          mql2.removeListener(handler);
        } else {
          mql2.removeEventListener("change", handler);
        }
      });
    };
  }, [getWindow]);
  return value.map(item => item.matches);
}

// src/visibility.tsx
function Visibility(props) {
  const {
    breakpoint,
    hide,
    children,
    ssr
  } = props;
  const [show] = useMediaQuery(breakpoint, {
    ssr
  });
  const isVisible = hide ? !show : show;
  const rendered = isVisible ? children : null;
  return rendered;
}

// src/media-query.tsx
var getBreakpoint = (theme, value) => {
  var _a, _b;
  return (_b = (_a = theme == null ? void 0 : theme.breakpoints) == null ? void 0 : _a[value]) != null ? _b : value;
};
function useQuery(props) {
  const {
    breakpoint = "",
    below,
    above
  } = props;
  const theme = useTheme();
  const bpBelow = getBreakpoint(theme, below);
  const bpAbove = getBreakpoint(theme, above);
  let query = breakpoint;
  if (bpBelow) {
    query = `(max-width: ${bpBelow})`;
  } else if (bpAbove) {
    query = `(min-width: ${bpAbove})`;
  }
  return query;
}
function Hide(props) {
  const {
    children,
    ssr
  } = props;
  const query = useQuery(props);
  return /* @__PURE__ */jsx(Visibility, {
    breakpoint: query,
    hide: true,
    ssr,
    children
  });
}
Hide.displayName = "Hide";

// src/media-query.hook.ts
function usePrefersReducedMotion(options) {
  const [prefersReducedMotion] = useMediaQuery("(prefers-reduced-motion: reduce)", options);
  return prefersReducedMotion;
}
function useColorModePreference(options) {
  const [isLight, isDark] = useMediaQuery(["(prefers-color-scheme: light)", "(prefers-color-scheme: dark)"], options);
  if (isLight) return "light";
  if (isDark) return "dark";
  return void 0;
}
function Show(props) {
  const {
    children,
    ssr
  } = props;
  const query = useQuery(props);
  return /* @__PURE__ */jsx(Visibility, {
    breakpoint: query,
    ssr,
    children
  });
}
Show.displayName = "Show";

// src/media-query.utils.ts
function getClosestValue(values, breakpoint, breakpoints$1 = breakpoints) {
  let index = Object.keys(values).indexOf(breakpoint);
  if (index !== -1) {
    return values[breakpoint];
  }
  let stopIndex = breakpoints$1.indexOf(breakpoint);
  while (stopIndex >= 0) {
    const key = breakpoints$1[stopIndex];
    if (values.hasOwnProperty(key)) {
      index = stopIndex;
      break;
    }
    stopIndex -= 1;
  }
  if (index !== -1) {
    const key = breakpoints$1[index];
    return values[key];
  }
  return void 0;
}
function useBreakpoint(arg) {
  var _a, _b;
  const opts = isObject(arg) ? arg : {
    fallback: arg != null ? arg : "base"
  };
  const theme = useTheme();
  const breakpoints = theme.__breakpoints.details.map(({
    minMaxQuery,
    breakpoint
  }) => ({
    breakpoint,
    query: minMaxQuery.replace("@media screen and ", "")
  }));
  const fallback = breakpoints.map(bp => bp.breakpoint === opts.fallback);
  const values = useMediaQuery(breakpoints.map(bp => bp.query), {
    fallback,
    ssr: opts.ssr
  });
  const index = values.findIndex(value => value == true);
  return (_b = (_a = breakpoints[index]) == null ? void 0 : _a.breakpoint) != null ? _b : opts.fallback;
}
function useBreakpointValue(values, arg) {
  var _a;
  const opts = isObject(arg) ? arg : {
    fallback: arg != null ? arg : "base"
  };
  const breakpoint = useBreakpoint(opts);
  const theme = useTheme();
  if (!breakpoint) return;
  const breakpoints = Array.from(((_a = theme.__breakpoints) == null ? void 0 : _a.keys) || []);
  const obj = Array.isArray(values) ? Object.fromEntries(Object.entries(arrayToObjectNotation(values, breakpoints)).map(([key, value]) => [key, value])) : values;
  return getClosestValue(obj, breakpoint, breakpoints);
}
export { Hide, Show, useBreakpoint, useBreakpointValue, useColorModePreference, useMediaQuery, usePrefersReducedMotion, useQuery };