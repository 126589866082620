
(function() {
  var result = { translations: { "he-il": { "i18n": { "duration": { "seconds": "{{ seconds }} \u05E9\u05E0\u05D9\u05D5\u05EA", "minutes": "{{ minutes }} \u05D3\u05E7\u05D5\u05EA", "hours": "{{ hours }} \u05E9\u05E2\u05D5\u05EA", "hoursAndMinutes": "{{ hours }} \u05E9\u05E2\u05D5\u05EA {{ minutes }} \u05D3\u05E7\u05D5\u05EA", "days": "{{ days }} \u05D9\u05DE\u05D9\u05DD", "shortForm": { "seconds": "{{ seconds }} \u05E9\u05E0'", "minutes": "{{ minutes }} \u05D3\u05E7'", "hours": "{{ hours }} \u05E9\u05E2'", "days": "{{ days }} \u05D9\u05DE'", "hoursAndMinutes": "{{ hours }} \u05E9\u05E2' {{ minutes }} \u05D3\u05E7'" } }, "dateTime": { "quarterAndYear": "\u05E8\u05D1\u05E2\u05D5\u05DF{{quarterNumber}} {{yearNumber}}" }, "numberRepresentation": { "fraction": "{{ numerator }}/{{ denominator }}", "numberWithCurrencyCode": "{{amountNumberValue}} {{currencyCode}}", "phoneNumberWithExtension": "{{phoneNumber}}, \u05E9\u05DC\u05D5\u05D7\u05D4 {{extension}}" } } } } };
  result.translationsLoaded = {
  "I18n": {
    "he-il": "static-7.1201"
  }
};
  result.translationsAvailable = {
  "I18n": {
    "af": "static-7.1201",
    "ar-eg": "static-7.1201",
    "bg": "static-7.1201",
    "bn": "static-7.1201",
    "ca-es": "static-7.1201",
    "cs": "static-7.1201",
    "da": "static-7.1201",
    "de": "static-7.1201",
    "el": "static-7.1201",
    "en-gb": "static-7.1201",
    "en": "static-7.1201",
    "es-mx": "static-7.1201",
    "es": "static-7.1201",
    "et": "static-7.1201",
    "fi": "static-7.1201",
    "fr-ca": "static-7.1201",
    "fr": "static-7.1201",
    "he-il": "static-7.1201",
    "hi": "static-7.1201",
    "hr": "static-7.1201",
    "hu": "static-7.1201",
    "id": "static-7.1201",
    "it": "static-7.1201",
    "ja": "static-7.1201",
    "ko": "static-7.1201",
    "lt": "static-7.1201",
    "ms": "static-7.1201",
    "nl": "static-7.1201",
    "no": "static-7.1201",
    "pl": "static-7.1201",
    "pt-br": "static-7.1201",
    "pt-pt": "static-7.1201",
    "ro": "static-7.1201",
    "ru": "static-7.1201",
    "sk": "static-7.1201",
    "sl": "static-7.1201",
    "sv": "static-7.1201",
    "th": "static-7.1201",
    "tl": "static-7.1201",
    "tr": "static-7.1201",
    "uk": "static-7.1201",
    "vi": "static-7.1201",
    "x-pseudo": "static-7.1201",
    "zh-cn": "static-7.1201",
    "zh-hk": "static-7.1201",
    "zh-tw": "static-7.1201"
  }
};
  hns('I18n', result);
  if (typeof I18n === 'object' && I18n.trigger) { I18n.trigger('loaded:I18n:he-il', {version: 'static-7.1201'}); }
})();


