'use es6';

// src/assign-after.ts
function assignAfter(target, ...sources) {
  if (target == null) {
    throw new TypeError("Cannot convert undefined or null to object");
  }
  const result = Object.assign({}, target);
  for (const nextSource of sources) {
    if (nextSource == null) continue;
    for (const nextKey in nextSource) {
      if (!Object.prototype.hasOwnProperty.call(nextSource, nextKey)) continue;
      if (nextKey in result) delete result[nextKey];
      result[nextKey] = nextSource[nextKey];
    }
  }
  return result;
}

// src/compact.ts
function compact(object) {
  const clone = Object.assign({}, object);
  for (let key in clone) {
    if (clone[key] === void 0) delete clone[key];
  }
  return clone;
}

// src/omit.ts
function omit(object, keysToOmit = []) {
  const clone = Object.assign({}, object);
  for (const key of keysToOmit) {
    if (key in clone) {
      delete clone[key];
    }
  }
  return clone;
}

// src/pick.ts
function pick(object, keysToPick) {
  const result = {};
  for (const key of keysToPick) {
    if (key in object) {
      result[key] = object[key];
    }
  }
  return result;
}

// src/split.ts
function split(object, keys) {
  const picked = {};
  const omitted = {};
  for (const [key, value] of Object.entries(object)) {
    if (keys.includes(key)) picked[key] = value;else omitted[key] = value;
  }
  return [picked, omitted];
}

// src/walk-object.ts
function isObject(value) {
  return typeof value === "object" && value != null && !Array.isArray(value);
}
function walkObject(target, predicate, options = {}) {
  const {
    stop,
    getKey
  } = options;
  function inner(value, path = []) {
    var _a;
    if (isObject(value) || Array.isArray(value)) {
      const result = {};
      for (const [prop, child] of Object.entries(value)) {
        const key = (_a = getKey == null ? void 0 : getKey(prop)) != null ? _a : prop;
        const childPath = [...path, key];
        if (stop == null ? void 0 : stop(value, childPath)) {
          return predicate(value, path);
        }
        result[key] = inner(child, childPath);
      }
      return result;
    }
    return predicate(value, path);
  }
  return inner(target);
}
export { assignAfter, compact, omit, pick, split, walkObject };