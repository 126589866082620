// THEME COLORS

export const CARNABY = {
  dark: '#850D63',
  regular: '#BD138D',
  medium: '#EBB8DD',
  light: '#FBF1F8',
  extraLight: '#FDF8FB'
};
export const MACARON = {
  dark: '#4348B0',
  regular: '#5C62D6',
  medium: '#8589E0',
  light: '#CED0F3',
  extraLight: '#F7F7FD'
};
export const ATLANTIC = {
  dark: '#00497D',
  regular: '#0068B1',
  medium: '#408EC5',
  light: '#B2D1E7',
  extraLight: '#F2F7FB'
};
export const ZAMBA = {
  extraDark: '#085E5E',
  dark: '#0B8484',
  regular: '#0FBFBF',
  medium: '#57D2D2',
  light: '#B7ECEC',
  extraLight: '#F3FCFC'
};
export const EMERALD = {
  dark: '#387D4E',
  regular: '#4FB06D',
  medium: '#7BC492',
  light: '#CAE7D3',
  extraLight: '#F6FBF7'
};
export const PATAGONIA = {
  dark: '#D49134',
  regular: '#FFBC4B',
  medium: '#FFCD78',
  light: '#FFEBC9',
  extraLight: '#FFFBF6'
};
export const SOLARIS = {
  extraDark: '#991E00',
  dark: '#D44325',
  regular: '#FF5C35',
  medium: '#FF7A59',
  light: '#FFCEC2',
  extraLight: '#FFF7F5'
};
export const KYOTO = {
  dark: '#BF2434',
  regular: '#ED2D40',
  medium: '#F56271',
  light: '#FBC0C6',
  extraLight: '#FEF4F5'
};
export const CHERRY_BLOSSOM = {
  dark: '#CF1B57',
  regular: '#F62D71',
  medium: '#F86295',
  light: '#FCC0D4',
  extraLight: '#FEF4F8'
};
export const _themeColors = {
  CARNABY,
  MACARON,
  ATLANTIC,
  ZAMBA,
  EMERALD,
  PATAGONIA,
  SOLARIS,
  KYOTO,
  CHERRY_BLOSSOM
};

// "grey"scale colors

export const _greyscaleColors = {
  DARK_MATTER: '#192733',
  ECLIPSE: '#213343',
  GALAXY: '#2E475D',
  METEOR: '#3E5974',
  CELESTIAL: '#516F90',
  COMET: '#7691AD',
  NEBULA: '#99AFC4',
  MILKY_WAY: '#B6C7D6',
  SATELLITE: '#DBE4ED',
  CONSTELLATION: '#EAF0F6',
  SUPERNOVA: '#F6F9FC',
  MOON: '#FFFFFF'
};
const colors = {
  CARNABY,
  MACARON,
  ATLANTIC,
  ZAMBA,
  PATAGONIA,
  SOLARIS,
  CHERRY_BLOSSOM,
  KYOTO,
  EMERALD,
  GREYSCALE: _greyscaleColors,
  text: {
    body: _greyscaleColors.ECLIPSE
  },
  blackAlpha: {
    750: 'rgba(0, 0, 0, 0.75)'
  },
  // We're overwriting some preset styles here to match our styleguide
  red: {
    '50': '#FDE8EA',
    '100': '#F9BDC3',
    '200': '#F6939D',
    '300': '#F26976',
    '400': '#EE3E50',
    '500': '#EB1429',
    '600': '#BC1021',
    '700': '#8D0C19',
    '800': '#5E0811',
    '900': '#2F0408'
  },
  blue: {
    '50': '#E5F4FF',
    '100': '#B8E2FF',
    '200': '#8ACFFF',
    '300': '#5CBCFF',
    '400': '#2EA9FF',
    '500': '#0096FF',
    '600': '#0078CC',
    '700': '#005A99',
    '800': '#003C66',
    '900': '#001E33'
  },
  green: {
    '50': '#EDF7F0',
    '100': '#CEE9D6',
    '200': '#AEDBBC',
    '300': '#8ECCA2',
    '400': '#6FBE87',
    '500': '#4FB06D',
    '600': '#3F8D57',
    '700': '#2F6A41',
    '800': '#20462C',
    '900': '#102316'
  },
  orange: {
    '50': '#FFEAE5',
    '100': '#FFC5B8',
    '200': '#FFA08A',
    '300': '#FF7B5C',
    '400': '#FF562E',
    '500': '#FF3100',
    '600': '#CC2700',
    '700': '#991E00',
    '800': '#661400',
    '900': '#330A00'
  }
};
export default colors;