export let ContainerGradient;
(function (ContainerGradient) {
  ContainerGradient["fullPageContainer"] = "to bottom right, #F8E1EC 0%, #FDF3F3 100%";
})(ContainerGradient || (ContainerGradient = {}));
export const generateTextGradientFill = gradient => ({
  bgGradient: `linear(${gradient})`,
  bgClip: 'text'
});
export default {
  'magenta-orange': 'linear-gradient(271.75deg, #CE4EAA 0%, #F56271 100%)',
  'magenta-purple': 'linear-gradient(91.14deg, #CE4EAA -4.62%, #8589E0 113.38%)',
  'light-purple-light-orange': 'linear-gradient(281.42deg, #F2C2C7 0%, #E3BBDB 100%)'
};