import { baseSpacingRemValue } from '../constants';
import space from '../space';
const sizesToCompute = {
  '3xs': 14,
  '2xs': 16,
  xs: 20,
  sm: 24,
  md: 28,
  lg: 32,
  xl: 36,
  '2xl': 42,
  '3xl': 48,
  '4xl': 56,
  '5xl': 64,
  '6xl': 72,
  '7xl': 80,
  '8xl': 90
};

// This is a bit of a hack. Chakra (and most other UI frameworks) calculate spacing off of base font size, traditionally 16px.
// Our app has a base font size of 14px, but we still want spacings calculated off of 16px. As such, we grab our calculated base rem value (which
// corresponds to 0.25 rem in the case of base font size 16px), multiply it by 4 to get 1 'new' rem, and then multiply it by the list of multipliers found
// here: https://v2.chakra-ui.com/docs/styled-system/theme

const computedSizes = Object.keys(sizesToCompute).reduce((accumulator, thisKey) => {
  return Object.assign({}, accumulator, {
    [thisKey]: `${sizesToCompute[thisKey] * baseSpacingRemValue * 4}rem`
  });
}, {});
const sizes = Object.assign({}, space, computedSizes, {
  max: 'max-content',
  min: 'min-content',
  container: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px'
  }
});
export default sizes;