import { createTextFieldBorderColors } from './constants';
export const focusRingColors = () => ({
  borderColor: 'var(--chakra-ui-focus-ring-color)',
  boxShadow: '0 0 0 1px var(--chakra-ui-focus-ring-color)'
});
export const variantOutlined = () => ({
  _focus: Object.assign({}, createTextFieldBorderColors('CARNABY.medium'), focusRingColors()),
  _focusWithin: Object.assign({}, createTextFieldBorderColors('CARNABY.medium'), focusRingColors())
});
export const variantFilled = () => ({
  _focus: Object.assign({}, createTextFieldBorderColors('CARNABY.medium'), focusRingColors())
});