import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(switchAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  track: {
    bg: 'GREYSCALE.MILKY_WAY',
    _checked: {
      bg: 'CARNABY.regular'
    }
  }
});

// export the component theme
export const switchTheme = defineMultiStyleConfig({
  baseStyle
});