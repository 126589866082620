import { defineStyle } from '@chakra-ui/react';
export default {
  baseStyle: defineStyle({
    color: 'CARNABY.regular',
    fontWeight: 'medium',
    _hover: {
      textDecoration: 'underline'
    },
    _focusVisible: {
      textDecoration: 'underline'
    },
    _active: {
      color: 'CARNABY.medium'
    }
  }),
  variants: defineStyle({
    homepage: {
      color: 'text.body'
    },
    danger: {
      color: 'KYOTO.regular'
    },
    unstyled: {
      color: 'text.body',
      fontWeight: 'medium',
      _active: {
        color: 'text.body'
      }
    },
    tabsLink: {
      color: 'text.body',
      // Overrides the underline on focus
      textDecoration: 'none !important',
      _selected: {
        color: 'CARNBARY.regular',
        textDecoration: 'none'
      },
      _hover: {
        textDecoration: 'none'
      },
      _focus: {
        boxShadow: 'none'
      }
    }
  })
};