import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(parts.keys);
const baseStyle = definePartsStyle({});
const variants = {
  gradientBackground: definePartsStyle({
    body: {
      color: '#FFF',
      py: 1
    },
    header: {
      color: '#FFF',
      border: 0,
      py: 1
    },
    arrow: {
      background: 'KYOTO.medium!important'
    },
    content: {
      background: 'linear-gradient(271.75deg, var(--chakra-colors-CARNABY-medium) 0%, var(--chakra-colors-KYOTO-medium) 100%)',
      boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.15)',
      borderRadius: '10px',
      padding: 2,
      py: 3,
      border: 0
    },
    closeButton: {
      color: '#FFF'
    }
  })
};
export const popoverTheme = defineMultiStyleConfig({
  baseStyle,
  variants
});