'use es6';

import { CSSReset, CSSPolyfill } from "../css-reset";
import { PortalManager } from "../portal";
import { ThemeProvider, ColorModeProvider, GlobalStyle } from "../system";
import { EnvironmentProvider } from "../react-env";
import { jsx, jsxs } from 'react/jsx-runtime';

// src/chakra-provider.tsx
var ChakraProvider = props => {
  const {
    children,
    colorModeManager,
    portalZIndex,
    resetScope,
    resetCSS = true,
    theme = {},
    environment,
    cssVarsRoot,
    disableEnvironment,
    disableGlobalStyle
  } = props;
  const _children = /* @__PURE__ */jsx(EnvironmentProvider, {
    environment,
    disabled: disableEnvironment,
    children
  });
  return /* @__PURE__ */jsx(ThemeProvider, {
    theme,
    cssVarsRoot,
    children: /* @__PURE__ */jsxs(ColorModeProvider, {
      colorModeManager,
      options: theme.config,
      children: [resetCSS ? /* @__PURE__ */jsx(CSSReset, {
        scope: resetScope
      }) : /* @__PURE__ */jsx(CSSPolyfill, {}), !disableGlobalStyle && /* @__PURE__ */jsx(GlobalStyle, {}), portalZIndex ? /* @__PURE__ */jsx(PortalManager, {
        zIndex: portalZIndex,
        children: _children
      }) : _children]
    })
  });
};
export { ChakraProvider };