// @ts-expect-error FIXME
import backgroundLogo from 'bender-url!../../../../images/connect-logo-bg-for-homepage-card.png';
import { ContainerGradient } from '../../../theme/gradients';
export let HomepageCardBackgroundGradient;
(function (HomepageCardBackgroundGradient) {
  HomepageCardBackgroundGradient["carnabyMacaron"] = "to right, var(--chakra-colors-CARNABY-medium) 0%, var(--chakra-colors-MACARON-medium) 100%";
  HomepageCardBackgroundGradient["cherryBlossomCarnaby"] = "to right, var(--chakra-colors-CHERRY_BLOSSOM-medium) 0%, var(--chakra-colors-CARNABY-medium) 100%";
  HomepageCardBackgroundGradient["kyotoCarnaby"] = "to right, var(--chakra-colors-KYOTO-medium) 0%, var(--chakra-colors-CARNABY-medium) 100%";
  HomepageCardBackgroundGradient["pinkToOrangeishVertical"] = "to top, #F2C2C7 0%, #E3BBDB 100%";
  HomepageCardBackgroundGradient["magentaToOrange"] = "to right, #CE4EAA 0%, #F56271 100%";
  HomepageCardBackgroundGradient["orangeToMagenta"] = "to right, #F56271 0%, #CE4EAA 100%";
})(HomepageCardBackgroundGradient || (HomepageCardBackgroundGradient = {}));
export function generateGradientBackgroundProps(gradient, includeLogo) {
  let textColor = 'white';
  if ([HomepageCardBackgroundGradient.pinkToOrangeishVertical, ContainerGradient.fullPageContainer].includes(gradient)) {
    textColor = undefined;
  }
  return {
    style: {
      backgroundImage: `${includeLogo ? `url('${backgroundLogo}'),` : ''} linear-gradient(${gradient})`
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: textColor
  };
}
export function generateBlockColorBackgroundProps(imageSrc) {
  let maybeBackgroundProps = {};
  if (imageSrc) {
    maybeBackgroundProps = {
      backgroundImage: imageSrc,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    };
  }
  return Object.assign({}, maybeBackgroundProps, {
    backgroundColor: 'SOLARIS.extraLight',
    color: 'GREYSCALE.DARK_MATTER'
  });
}