'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["className"],
  _excluded2 = ["className", "justify"],
  _excluded3 = ["className"],
  _excluded4 = ["className", "children", "direction", "justify", "align"];
import { createStylesContext, forwardRef, chakra, omitThemingProps, useMultiStyleConfig } from "../system";
import { cx } from "../shared-utils";
import { jsx } from 'react/jsx-runtime';

// src/card-context.tsx
var [CardStylesProvider, useCardStyles] = createStylesContext("Card");
var CardBody = forwardRef(function CardBody2(props, ref) {
  const {
      className
    } = props,
    rest = _objectWithoutPropertiesLoose(props, _excluded);
  const styles = useCardStyles();
  return /* @__PURE__ */jsx(chakra.div, Object.assign({
    ref,
    className: cx("chakra-card__body", className),
    __css: styles.body
  }, rest));
});
var CardFooter = forwardRef(function CardFooter2(props, ref) {
  const {
      className,
      justify
    } = props,
    rest = _objectWithoutPropertiesLoose(props, _excluded2);
  const styles = useCardStyles();
  return /* @__PURE__ */jsx(chakra.div, Object.assign({
    ref,
    className: cx("chakra-card__footer", className),
    __css: Object.assign({
      display: "flex",
      justifyContent: justify
    }, styles.footer)
  }, rest));
});
var CardHeader = forwardRef(function CardHeader2(props, ref) {
  const {
      className
    } = props,
    rest = _objectWithoutPropertiesLoose(props, _excluded3);
  const styles = useCardStyles();
  return /* @__PURE__ */jsx(chakra.div, Object.assign({
    ref,
    className: cx("chakra-card__header", className),
    __css: styles.header
  }, rest));
});
var Card = forwardRef(function Card2(props, ref) {
  const _omitThemingProps = omitThemingProps(props),
    {
      className,
      children,
      direction = "column",
      justify,
      align
    } = _omitThemingProps,
    rest = _objectWithoutPropertiesLoose(_omitThemingProps, _excluded4);
  const styles = useMultiStyleConfig("Card", props);
  return /* @__PURE__ */jsx(chakra.div, Object.assign({
    ref,
    className: cx("chakra-card", className),
    __css: Object.assign({
      display: "flex",
      flexDirection: direction,
      justifyContent: justify,
      alignItems: align,
      position: "relative",
      minWidth: 0,
      wordWrap: "break-word"
    }, styles.container)
  }, rest, {
    children: /* @__PURE__ */jsx(CardStylesProvider, {
      value: styles,
      children
    })
  }));
});
export { Card, CardBody, CardFooter, CardHeader, useCardStyles };