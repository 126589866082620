module.exports = {
  "/": {
    "success": {
      "homepage loaded": [
        "HOME_LOAD"
      ]
    }
  },
  "/inbound": {
    "name": "inboundRedirect"
  },
  "/member/:memberId": {},
  "/code-of-conduct": {
    "success": {
      "code of conduct loaded": [
        "CODE_OF_CONDUCT_LOAD"
      ]
    }
  }
};