import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(accordionAnatomy.keys);
const sidenavButtonStyles = defineStyle(() => {
  return {
    padding: 2,
    _hover: {
      backgroundColor: 'GREYSCALE.SUPERNOVA'
    }
  };
});
const sidenavPanelStyles = defineStyle(() => {
  return {
    marginLeft: '44px',
    paddingTop: 2,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0
  };
});
const sidenav = definePartsStyle({
  container: {
    border: 'none',
    mb: 4
  },
  button: sidenavButtonStyles(),
  panel: sidenavPanelStyles()
});
export const accordionTheme = defineMultiStyleConfig({
  variants: {
    sidenav
  }
});