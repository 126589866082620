'use es6';

import { isChakraTheme, theme, baseTheme } from "../theme";
import mergeWith from "../../lodash.mergewith";
import { isObject } from "../shared-utils";

// src/extend-theme.ts
function isFunction(value) {
  return typeof value === "function";
}
function pipe$1(...fns) {
  return v => fns.reduce((a, b) => b(a), v);
}
var createExtendTheme = theme2 => {
  return function extendTheme2(...extensions) {
    let overrides = [...extensions];
    let activeTheme = extensions[extensions.length - 1];
    if (isChakraTheme(activeTheme) && overrides.length > 1) {
      overrides = overrides.slice(0, overrides.length - 1);
    } else {
      activeTheme = theme2;
    }
    return pipe$1(...overrides.map(extension => prevTheme => isFunction(extension) ? extension(prevTheme) : mergeThemeOverride(prevTheme, extension)))(activeTheme);
  };
};
var extendTheme = createExtendTheme(theme);
var extendBaseTheme = createExtendTheme(baseTheme);
function mergeThemeOverride(...overrides) {
  return mergeWith({}, ...overrides, mergeThemeCustomizer);
}
function mergeThemeCustomizer(source, override, key, object) {
  if ((isFunction(source) || isFunction(override)) && Object.prototype.hasOwnProperty.call(object, key)) {
    return (...args) => {
      const sourceValue = isFunction(source) ? source(...args) : source;
      const overrideValue = isFunction(override) ? override(...args) : override;
      return mergeWith({}, sourceValue, overrideValue, mergeThemeCustomizer);
    };
  }
  return void 0;
}
function withDefaultColorScheme({
  colorScheme,
  components
}) {
  return theme => {
    let names = Object.keys(theme.components || {});
    if (Array.isArray(components)) {
      names = components;
    } else if (isObject(components)) {
      names = Object.keys(components);
    }
    return mergeThemeOverride(theme, {
      components: Object.fromEntries(names.map(componentName => {
        const withColorScheme = {
          defaultProps: {
            colorScheme
          }
        };
        return [componentName, withColorScheme];
      }))
    });
  };
}
function withDefaultSize({
  size,
  components
}) {
  return theme => {
    let names = Object.keys(theme.components || {});
    if (Array.isArray(components)) {
      names = components;
    } else if (isObject(components)) {
      names = Object.keys(components);
    }
    return mergeThemeOverride(theme, {
      components: Object.fromEntries(names.map(componentName => {
        const withSize = {
          defaultProps: {
            size
          }
        };
        return [componentName, withSize];
      }))
    });
  };
}
function withDefaultVariant({
  variant,
  components
}) {
  return theme => {
    let names = Object.keys(theme.components || {});
    if (Array.isArray(components)) {
      names = components;
    } else if (isObject(components)) {
      names = Object.keys(components);
    }
    return mergeThemeOverride(theme, {
      components: Object.fromEntries(names.map(componentName => {
        const withVariant = {
          defaultProps: {
            variant
          }
        };
        return [componentName, withVariant];
      }))
    });
  };
}

// src/theme-extensions/with-default-props.ts
function pipe(...fns) {
  return v => fns.reduce((a, b) => b(a), v);
}
function withDefaultProps({
  defaultProps: {
    colorScheme,
    variant,
    size
  },
  components
}) {
  const identity = t => t;
  const fns = [colorScheme ? withDefaultColorScheme({
    colorScheme,
    components
  }) : identity, size ? withDefaultSize({
    size,
    components
  }) : identity, variant ? withDefaultVariant({
    variant,
    components
  }) : identity];
  return theme => mergeThemeOverride(pipe(...fns)(theme));
}
export { extendBaseTheme, extendTheme, mergeThemeOverride, withDefaultColorScheme, withDefaultProps, withDefaultSize, withDefaultVariant };