// This is a bit of a hack. Chakra (and most other UI frameworks) calculate spacing off of base font size, traditionally 16px.
// Our app has a base font size of 14px, but we still want spacings calculated off of 16px.
// We are using the list of spacing values as defined [here](https://v2.chakra-ui.com/docs/styled-system/theme#spacing) and then manually adding on 1px.

import { baseSpacingRemValue } from './constants';
const spaceMultipliers = [0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 72, 80, 96, 1.5, 2.5, 3.5];
const themeSpace = spaceMultipliers.reduce((accumulator, currentValue) => Object.assign({}, accumulator, {
  [currentValue]: `${currentValue * baseSpacingRemValue}rem`
}), {});
export default Object.assign({}, themeSpace, {
  px: '1px'
});