import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { createTextFieldBorderColors } from '../../constants';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(inputAnatomy.keys);
const outline = definePartsStyle(() => {
  return {
    addon: {
      px: 2
    },
    field: Object.assign({}, createTextFieldBorderColors(), {
      backgroundColor: 'GREYSCALE.SUPERNOVA',
      fontSize: 'sm',
      _empty: {
        _focus: Object.assign({}, createTextFieldBorderColors('CARNABY.medium')),
        _focusWithin: Object.assign({}, createTextFieldBorderColors('CARNABY.medium'))
      },
      _focusVisible: {
        shadow: 'none'
      }
    })
  };
});
const unstyled = definePartsStyle(() => {
  return {
    field: {
      backgroundColor: 'white',
      fontSize: 'sm'
    }
  };
});
const usernameCheck = definePartsStyle(() => ({
  addon: {
    fontSize: 'md',
    px: 4,
    h: 14,
    borderRadius: 'full'
  },
  field: {
    fontSize: 'md',
    px: 4,
    h: 14,
    borderRadius: 'full',
    borderTopWidth: '1px',
    borderLeftWidth: '1px',
    borderBottomWidth: '1px',
    borderRight: 0,
    borderStyle: 'solid',
    borderTopColor: 'CARNABY.medium',
    borderBottomColor: 'CARNABY.medium',
    borderLeftColor: 'CARNABY.medium',
    backgroundColor: '#FFF',
    _invalid: {
      borderColor: 'CARNABY.medium',
      color: 'KYOTO.regular'
    },
    _focus: {
      boxShadow: 'none'
    }
  }
}));
export default defineMultiStyleConfig({
  variants: {
    outline,
    unstyled,
    usernameCheck
  }
});