export const fontSizes = {
  '4xl': '4.286rem',
  '3xl': '3.429rem',
  '2xl': '2.286rem',
  xl: '1.714rem',
  lg: '1.429rem',
  md: '1.143rem',
  sm: '1rem',
  xs: '0.857rem',
  xxs: '0.714rem'
};

// While we don't overwrite any of the default Chakra styles here, we should
// capture them here to ensure consistency with design.
export const fontWeights = {
  bold: 700,
  semibold: 600,
  medium: 500,
  normal: 400
};
export const fonts = {
  heading: `'Lexend Deca', 'sans-serif'`,
  body: `'Lexend Deca', 'sans-serif'`
};