import { drawerAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(parts.keys);
const baseStyle = () => definePartsStyle({
  header: {
    background: 'var(--chakra-gradients-magenta-orange)',
    color: 'GREYSCALE.MOON'
  },
  body: {
    my: 4
  },
  closeButton: {
    color: 'GREYSCALE.MOON'
  },
  footer: {
    backgroundColor: 'GREYSCALE.SUPERNOVA',
    borderTop: '1px solid',
    borderTopColor: 'GREYSCALE.SATELLITE',
    justifyContent: 'flex-start'
  }
});
export const drawerTheme = defineMultiStyleConfig({
  baseStyle
});