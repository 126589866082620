// This is a bunch of stuff copy-pasted from Chakra modified slightly to suit our needs

// @ts-expect-error This is a JS file
import delve from '../delve';
import parseToRgba from './color2K/parseToRgba';
import toHex from './color2K/toHex';
export function randomColorFromList(str, list) {
  let index = 0;
  if (str.length === 0) return list[0];
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    index = str.charCodeAt(i) + ((index << 5) - index);
    // eslint-disable-next-line no-bitwise
    index = index & index;
  }
  index = (index % list.length + list.length) % list.length;
  return list[index];
}
export const getColor = (theme, color, fallback) => {
  const hex = delve(theme, `colors.${color}`, color);
  try {
    toHex(hex);
    return hex;
  } catch (_unused) {
    // returning black to stay consistent with TinyColor behaviour so as to prevent breaking change
    return fallback || '#000000';
  }
};
const getBrightness = color => {
  const [r, g, b] = parseToRgba(color);
  // http://www.w3.org/TR/AERT#color-contrast
  return (r * 299 + g * 587 + b * 114) / 1000;
};

/**
 * Determines if the tone of given color is "light" or "dark"
 * @param color - the color in hex, rgb, or hsl
 *
 */
const tone = color => theme => {
  const hex = getColor(theme, color);
  const brightness = getBrightness(hex);
  const isDark = brightness < 128;
  return isDark ? 'dark' : 'light';
};
export const isDark = color => theme => tone(color)(theme) === 'dark';