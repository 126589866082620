'use es6';

import { useRef, useEffect, useCallback } from 'react';

// src/index.ts
function useCallbackRef(callback, deps = []) {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  });
  return useCallback((...args) => {
    var _a;
    return (_a = callbackRef.current) == null ? void 0 : _a.call(callbackRef, ...args);
  }, deps);
}
export { useCallbackRef };